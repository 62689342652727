import { Grid, InputLabel } from '@material-ui/core';
import { ApplicationDetail, CoverageType } from '@nirvana/api/quoting';
import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import DeductiblesSkeleton from 'src/features/application/components/create/indicationOptions/deductibles/skeleton';
import LimitsSelector from 'src/features/application/components/create/indicationOptions/limits/selector';
import { getIndicationOptions } from '../../../queries';
import { useStyles } from '../coveragesFormView';

export interface ILimits {
  applicationData: ApplicationDetail;
}

const Limits = ({ applicationData }: ILimits) => {
  const classes = useStyles();

  const { control, watch, setValue } = useFormContext();

  const coveragesRequired = watch('coveragesRequired');
  const { data: indicationOptionsData, isLoading } = useQuery(
    ['indicationOptions', applicationData.summary.applicationID],
    () =>
      getIndicationOptions({
        applicationID: applicationData.summary.applicationID,
      }),
  );

  const { limitsOptions: limits } = indicationOptionsData || {};

  const hasMTC = useMemo(() => {
    return coveragesRequired?.some(
      (coverage: any) =>
        coverage.coverageType === CoverageType.CoverageMotorTruckCargo,
    );
  }, [coveragesRequired]);

  const limitsByCoverage = useMemo(() => {
    const result = {};

    if (hasMTC) {
      limits?.forEach((limit: any) => {
        const currentVal =
          limit.current === -1 ? limit.options[0] : limit.current;
        result[CoverageType.CoverageMotorTruckCargo] = {
          current: currentVal,
          options: limit.options,
        };
        setValue(`limits.${CoverageType.CoverageMotorTruckCargo}`, currentVal);
      });
    } else {
      return {};
    }

    return result;
  }, [hasMTC, limits, setValue]);

  if (!hasMTC) {
    return null;
  }

  if (isLoading) {
    return <DeductiblesSkeleton spacingGridLeft={4} spacingGridRight={8} />;
  }

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={4}>
        <InputLabel
          htmlFor="deductiblesOnCoverage-input"
          className={classes.inputLabel}
        >
          Limits
        </InputLabel>
      </Grid>
      <Grid item xs={8} container spacing={2} alignItems="center">
        {Object.keys(limitsByCoverage).map((coverage) => {
          const deductibleRecord = limitsByCoverage[coverage];

          return (
            <Grid item xs={6} key={coverage}>
              <Controller
                name={`limits.${coverage}`}
                control={control}
                defaultValue={deductibleRecord.current}
                render={({ onChange, value }) => (
                  <LimitsSelector
                    coverage={coverage as CoverageType}
                    disabled={false}
                    options={deductibleRecord.options || []}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Limits;
