import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { has, isString } from 'lodash';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Grid, Typography } from '@material-ui/core';
import {
  ApplicationDetail,
  CoverageType,
  EquipmentList,
  EquipmentListRecord,
} from '@nirvana/api/quoting';
import { AttachmentView } from '@nirvana/ui-kit';

import { SummaryView } from 'src/components/table';
import { LocationList } from 'src/components/terminalLocation';
import { fetchApplicationById } from 'src/features/application/actions';
import { application as ApplicationHelper } from 'src/helpers';
import { useDispatch } from 'src/redux';

import Section, { SectionDataRenderParams } from '../../create/review/section';
import { useStyles } from '../../create/review/styles';
import { SECTION_NAMES } from '../create';
import { ViewProps } from '../create/renewStep2';
import { updateOperations } from '../queries';
import getOperationsValidationSchema from '../validationSchema/operations';
import EditModeFooter from './footer/editModeFooter';
import MarkAsCompletedFooter from './footer/markAsCompletedFooter';
import OperationsFormView from './forms/operationsFormView';

const OperationsView = ({
  activeApplication,
  isEditable = false,
  onMarkAsCompleted,
  isMarkAsCompleted,
  title = 'Operations',
  hideFooter = false,
  markAsCompletedMap,
  isSectionLoading,
}: ViewProps) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = React.useState<
    z.ZodFormattedError<{
      projectedMileage: number;
      equipmentList: {
        info: any[];
        flatFileMetadata?: any;
      };
      projectedNumberOfPowerUnits: number;
      terminalLocations?: unknown;
    }>
  >();
  const childFormRef = React.useRef<any>();

  const methods = useForm();
  const applicationID = activeApplication.summary.applicationID;

  const { mutate: update } = useMutation(updateOperations, {
    onSuccess: () => {
      dispatch(fetchApplicationById(applicationID));
      setEditMode(false);
    },
  });

  const setEditModeVal = (value: boolean) => {
    setEditMode(value);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleUpdate = () => {
    methods.handleSubmit((data) => {
      const emptyEquipmentList: EquipmentList = {
        info: [],
        flatfileMetadata: undefined,
      };

      const operationsForm = activeApplication.indicationForm?.operationsForm;

      const numberOfPowerUnits =
        has(data, 'numberOfPowerUnits') && isString(data.numberOfPowerUnits)
          ? +data.numberOfPowerUnits
          : operationsForm?.numberOfPowerUnits ?? 0;

      const projectedMileage =
        has(data, 'projectedMileage') && isString(data.projectedMileage)
          ? +data.projectedMileage
          : operationsForm?.projectedMileage ?? 0;

      const operationsFormData = {
        equipmentList: operationsForm?.equipmentList ?? emptyEquipmentList,
        terminalLocations: operationsForm?.terminalLocations,
        ...data,
        numberOfPowerUnits,
        projectedMileage,
      };

      update({
        applicationID,
        operations: operationsFormData,
      });
      setEditMode(false);
    })();
  };

  const handleMarkAsCompleted = (checked: boolean) => {
    if (validationErrors) {
      return;
    }
    onMarkAsCompleted?.(SECTION_NAMES.OPERATIONS, checked);
  };

  const hasGL =
    activeApplication.indicationForm?.operationsForm?.coveragesRequired?.some(
      (record) => record.coverageType === CoverageType.CoverageGeneralLiability,
    );

  React.useEffect(() => {
    const validationSchema = getOperationsValidationSchema(hasGL);

    const validationData = {
      projectedMileage:
        activeApplication.indicationForm?.operationsForm?.projectedMileage,
      projectedNumberOfPowerUnits:
        activeApplication.indicationForm?.operationsForm?.numberOfPowerUnits,
      equipmentList:
        activeApplication.indicationForm?.operationsForm?.equipmentList,
      terminalLocations:
        activeApplication.indicationForm?.operationsForm?.terminalLocations,
    };

    const validationResult = validationSchema.safeParse(validationData);

    if (!validationResult.success) {
      setValidationErrors(validationResult.error.format());
    } else {
      setValidationErrors(undefined);
    }
  }, [activeApplication, hasGL, markAsCompletedMap]);

  const getEquipmentSummaryRows = (
    equipmentList: Array<EquipmentListRecord>,
  ) => {
    const equipmentSummaryValues =
      ApplicationHelper.getEquipmentSummary(equipmentList);

    const equipmentSummaryRows = [
      {
        label: 'Total # of trucks and trailers',
        value: equipmentSummaryValues.totalUnits,
      },
      {
        label: 'Total value',
        value: (
          <NumberFormat
            value={equipmentSummaryValues.totalValue}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        ),
      },
    ];

    return equipmentSummaryRows;
  };

  if (isMarkAsCompleted) {
    return (
      <Grid classes={{ root: classes.markAsCompleted }}>
        <Typography classes={{ root: classes.completedHeader }}>
          {title}
        </Typography>
        <MarkAsCompletedFooter
          checked={isMarkAsCompleted}
          onChange={() => handleMarkAsCompleted(false)}
          isSectionLoading={isSectionLoading}
        />
      </Grid>
    );
  }

  return (
    <Section
      applicationData={activeApplication}
      title={title}
      isEditable={!editMode && isEditable}
      onEdit={() => setEditModeVal(true)}
      footer={
        hideFooter ? null : editMode ? (
          <EditModeFooter onUpdate={handleUpdate} onCancel={handleCancel} />
        ) : (
          <MarkAsCompletedFooter
            onChange={(checked) => handleMarkAsCompleted(checked)}
            isSectionLoading={isSectionLoading}
          />
        )
      }
      data={
        editMode
          ? [
              {
                key: 'operationsForm',
                getValue: (data: ApplicationDetail) => {
                  return data;
                },
                renderValue: () => {
                  return (
                    <FormProvider {...methods}>
                      <OperationsFormView
                        childFormRef={childFormRef}
                        application={activeApplication}
                      />
                    </FormProvider>
                  );
                },
              },
            ]
          : [
              {
                key: 'numberOfPowerUnits',
                label: 'Projected number of power units',
                getValue: (data: ApplicationDetail) => {
                  return data.indicationForm?.operationsForm
                    ?.numberOfPowerUnits;
                },
                renderValue: ({
                  value,
                  hasChanged,
                }: SectionDataRenderParams) => {
                  return hasChanged ? <mark>{value}</mark> : value;
                },
              },
              {
                key: 'projectedMileage',
                label: 'Projected mileage',
                getValue: (data: ApplicationDetail) => {
                  return data.indicationForm?.operationsForm?.projectedMileage;
                },
                renderValue: ({
                  value,
                  hasChanged,
                }: SectionDataRenderParams) => {
                  return hasChanged ? (
                    <mark>
                      <NumberFormat
                        value={value}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </mark>
                  ) : (
                    <NumberFormat
                      value={value}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                  );
                },
              },

              {
                key: 'equipmentList',
                label: 'Updated equipment list',
                getValue: (data: ApplicationDetail) => {
                  return data.indicationForm?.operationsForm?.equipmentList;
                },
                renderValue: ({
                  value,
                  previousValue,
                  hasChanged,
                }: SectionDataRenderParams) => {
                  const previousEquipmentSummary = getEquipmentSummaryRows(
                    previousValue?.info,
                  );
                  return (
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <AttachmentView
                          files={[
                            {
                              filename:
                                value?.flatfileMetadata?.fileMetadata.name,
                            },
                          ]}
                        />
                      </Grid>
                      {value?.info ? (
                        <Grid item>
                          <div className={classes.summaryContainer}>
                            <SummaryView
                              title="Document Summary"
                              rows={getEquipmentSummaryRows(value?.info).map(
                                (record, index) => {
                                  return {
                                    ...record,
                                    value:
                                      hasChanged &&
                                      previousEquipmentSummary[index].value !==
                                        record.value ? (
                                        <mark>{record.value}</mark>
                                      ) : (
                                        record.value
                                      ),
                                  };
                                },
                              )}
                            />
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  );
                },
              },
              ...(hasGL
                ? [
                    {
                      key: 'terminalLocations',
                      label: 'Physical business addresses',
                      getValue: (data: ApplicationDetail) => {
                        return data.indicationForm?.operationsForm
                          ?.terminalLocations;
                      },
                      renderValue: ({ value }: SectionDataRenderParams) => {
                        return (
                          <LocationList
                            list={value}
                            onChange={() => null}
                            disableEdit
                            errors={validationErrors?.terminalLocations}
                          />
                        );
                      },
                    },
                  ]
                : []),
            ]
      }
    />
  );
};

export default OperationsView;
