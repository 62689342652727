import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';
import {
  ApplicationState,
  CoverageRecord,
  CoverageType,
} from '@nirvana/api/quoting';
import { Show } from '@nirvana/ui-kit';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  INDICATION_PAGE_VIEW,
  INDICATION_SELECT,
  INDICATION_PAGE_DEDUCTIBLE_CHANGE,
  INDICATION_PAGE_LIMIT_CHANGE,
  INDICATION_PAGE_INDICATION_RANGE_SHOW,
} from 'src/features/telematics/events';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import { useAnalytics } from 'src/helpers/analytics';
import { fetchIndicationOptions } from '../../../actions/indicationOptions';
import { applicationSelector, fetchState } from '../../../slices';
import {
  DeductibleUpdateRecord,
  VariablesOptionsTypes,
  indicationOptionsSelector,
  setLoadingIndication,
  updateIndication,
  updateVariablesOptions,
} from '../../../slices/indicationOptions';
import Deductibles from './deductibles';
import IndicationDelay from './indicationDelay';
import Loader, { IndicationLoaderStatus } from './indicationLoader';
import IndicationOptionsSkeleton from './indicationOptionsSkeleton';
import Limits from './limits';

import { getLoadingSteps } from './constants/steps';
import IndicationOptionWithPrice from './indicationOptionWithPrice';
import IndicationOptionWithoutPrice from './indicationOptionWithoutPrice';
import { useStyles } from './styles';

export interface IIndicationOptionsProps {
  coverages: CoverageType[];
  applicationId: string;
  control: any;
  errors: any;
  setValue: any;
  onPanic: () => void;
}

/**
 * Page that lists 3 indication options for selection.
 * This page also allows modifying the deductible values to generate relevant indication options.
 * Corresponds to Indication (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A11450&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const IndicationOptions = ({
  coverages = [],
  applicationId,
  control,
  errors,
  setValue,
  onPanic,
}: IIndicationOptionsProps) => {
  const classes = useStyles();
  const { capture } = useAnalytics();
  const dispatch = useDispatch();
  const getFeatureValue = useFeatureFlag();
  const [loaderAnimationStatus, setLoaderAnimationStatus] =
    React.useState<IndicationLoaderStatus>('idle');
  const [isFetchingApplicationState, setFetchingApplicationState] =
    React.useState<boolean>(false);
  const [isUpdatingDeductibles, setUpdatingDeductibles] =
    React.useState<boolean>(false);
  const [isIndicationDelayed, setIndicationDelayed] =
    React.useState<boolean>(false);
  const application = useSelector(applicationSelector);
  const {
    applicationState,
    applicationStateFetchStatus,
    activeApplication: details,
  } = application;
  const indicationOptionsData = useSelector(indicationOptionsSelector);
  const { isLoading, list: indicationOptions } = indicationOptionsData;
  const hasMTC = React.useMemo(() => {
    return coverages.includes(CoverageType.CoverageMotorTruckCargo);
  }, [coverages]);

  const isPricelessIndication = getFeatureValue(
    Feature.PRICELESS_INDICATION,
    false,
  );

  const isIndicationRangesEnabled = getFeatureValue(
    Feature.INDICATION_RANGES,
    false,
  );

  React.useEffect(() => {
    if (applicationId && (!indicationOptions || !indicationOptions.length)) {
      dispatch(fetchState({ applicationID: applicationId }));
    }
  }, [dispatch, applicationId, indicationOptions]);

  React.useEffect(() => {
    if (!isLoading) {
      setUpdatingDeductibles(false);
      setFetchingApplicationState(false);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (
      isUpdatingDeductibles ||
      isFetchingApplicationState ||
      isLoading ||
      loaderAnimationStatus === 'loading'
    ) {
      dispatch(setLoadingIndication(true));
    } else {
      dispatch(setLoadingIndication(false));
    }
  }, [
    dispatch,
    isUpdatingDeductibles,
    isFetchingApplicationState,
    isLoading,
    loaderAnimationStatus,
  ]);

  React.useEffect(() => {
    if (applicationStateFetchStatus === 'succeeded' && applicationState) {
      // TODO: change when app state machine is implemented. We accept all states
      // after indication generated
      if (
        [
          ApplicationState.ApplicationStateIndicationGenerated,
          ApplicationState.ApplicationStateIndicationSelected,
          ApplicationState.ApplicationStateUnderReviewForQuote,
        ].includes(applicationState)
      ) {
        dispatch(fetchIndicationOptions(applicationId));
      } else if (
        applicationState ===
        ApplicationState.ApplicationStateUnderReviewForIndication
      ) {
        setFetchingApplicationState(true);
        setTimeout(() => {
          dispatch(fetchState({ applicationID: applicationId }));
        }, 1000);
      } else if (applicationState === ApplicationState.ApplicationStatePanic) {
        // Show error screen
        onPanic();
      } else if (
        applicationState === ApplicationState.ApplicationStateIndicationDelay
      ) {
        setFetchingApplicationState(false);
        setIndicationDelayed(true);
      } else {
        // Do not do anything
      }
    } else if (applicationStateFetchStatus === 'failed') {
      // TODO: Handle this case
    }
  }, [
    applicationId,
    applicationStateFetchStatus,
    applicationState,
    dispatch,
    onPanic,
  ]);

  const onDeductiblesChange = (updated: DeductibleUpdateRecord) => {
    // Update operations form
    if (details?.indicationForm?.operationsForm) {
      const indicationForm = {
        ...details.indicationForm,
        operationsForm: {
          ...details.indicationForm.operationsForm,
          coveragesRequired: (
            details.indicationForm.operationsForm?.coveragesRequired || []
          ).map((record: CoverageRecord) => {
            if (
              updated.deductibles[record.coverageType] ||
              updated.deductibles[record.coverageType] === 0
            ) {
              // Update selected deductibles/limit
              dispatch(
                updateVariablesOptions({
                  coverage: record.coverageType,
                  amount: updated.deductibles[record.coverageType],
                  type: 'deductible',
                }),
              );

              return {
                ...record,
                deductible: updated.deductibles[record.coverageType],
              };
            }

            return record;
          }),
          coveragesWithCombinedDeductibles:
            updated.coveragesWithCombinedDeductibles,
        },
      };

      // // Update indication options
      dispatch(
        updateIndication({
          applicationID: applicationId,
          payload: indicationForm,
        }),
      );
    }
  };

  React.useEffect(() => {
    capture(INDICATION_PAGE_VIEW, {
      applicationId,
    });
  }, []);

  React.useEffect(() => {
    if (isIndicationRangesEnabled) {
      capture(INDICATION_PAGE_INDICATION_RANGE_SHOW, {
        applicationId: applicationId,
      });
    }
  }, [applicationId, isIndicationRangesEnabled, indicationOptions]);

  const handleDeductibleChange = (updated: DeductibleUpdateRecord) => {
    // Capture event
    capture(INDICATION_PAGE_DEDUCTIBLE_CHANGE, {
      applicationId,
      deductible: updated,
    });

    setValue('id', undefined);
    setUpdatingDeductibles(true);
    onDeductiblesChange(updated);
  };

  const onLimitsChange = (
    coverage: CoverageType,
    value: number,
    type: VariablesOptionsTypes,
  ) => {
    capture(INDICATION_PAGE_LIMIT_CHANGE, {
      applicationId,
      limit: value,
      coverage,
    });

    // Update operations form
    if (details?.indicationForm?.operationsForm) {
      const indicationForm = {
        ...details.indicationForm,
        operationsForm: {
          ...details.indicationForm.operationsForm,
          coveragesRequired: (
            details.indicationForm.operationsForm?.coveragesRequired || []
          ).map((record: CoverageRecord) => {
            if (record.coverageType === coverage) {
              return {
                ...record,
                [type]: value,
              };
            }

            return record;
          }),
        },
      };

      // Update selected deductibles/limit
      dispatch(
        updateVariablesOptions({
          coverage,
          amount: value,
          type,
        }),
      );

      // Update indication options
      dispatch(
        updateIndication({
          applicationID: applicationId,
          payload: indicationForm,
        }),
      );
    }
  };

  const handleLimitsChange = (event: any) => {
    const { name, value } = event.target;

    setValue('id', undefined);
    onLimitsChange(name, value, 'limit');
    setUpdatingDeductibles(true);
  };

  const renderContent = (props: any) => {
    if (
      !isUpdatingDeductibles &&
      (isFetchingApplicationState || loaderAnimationStatus === 'loading')
    ) {
      return (
        <Grid item position="relative">
          <Box flex={1} className={classes.overlay} />
          <Box className={classes.loaderContainer}>
            <Loader
              onStatusChange={setLoaderAnimationStatus}
              steps={getLoadingSteps({ isPricelessIndication })}
            />
          </Box>
          <IndicationOptionsSkeleton />
        </Grid>
      );
    } else if (
      isUpdatingDeductibles ||
      isFetchingApplicationState ||
      isLoading
    ) {
      return (
        <>
          <>
            <Deductibles
              loading={isUpdatingDeductibles}
              hasMTC={hasMTC}
              coverages={coverages}
              onChange={handleDeductibleChange}
            />
          </>
          <>
            <Limits
              loading={isUpdatingDeductibles}
              hasMTC={hasMTC}
              onChange={handleLimitsChange}
            />
          </>
          <>
            <IndicationOptionsSkeleton />
          </>
        </>
      );
    } else if (isIndicationDelayed) {
      return (
        <Box pt={18} pl={10}>
          <Grid item position="relative">
            <IndicationDelay />
          </Grid>
        </Box>
      );
    } else if (indicationOptions && indicationOptions.length) {
      const selectedOption: string = props.value;

      return (
        <>
          <>
            <Deductibles
              loading={isUpdatingDeductibles}
              hasMTC={hasMTC}
              coverages={coverages}
              onChange={handleDeductibleChange}
            />
          </>
          <>
            <Limits
              loading={isUpdatingDeductibles}
              hasMTC={hasMTC}
              onChange={handleLimitsChange}
            />
          </>
          <Grid item>
            <Divider />
          </Grid>

          <Show when={!isPricelessIndication}>
            <Grid item>
              <InputLabel
                htmlFor="deductiblesOnCoverage-input"
                className={classes.inputLabel}
              >
                Plans
              </InputLabel>
              <FormHelperText>
                Please select the coverage plan for the insured
              </FormHelperText>
            </Grid>
          </Show>
          <Grid item container direction="row" spacing={1}>
            <Grid item>
              <FormHelperText error>
                {errors?.id?.message || ' '}
              </FormHelperText>
            </Grid>
            <Grid item container spacing={1} wrap="nowrap">
              <>
                {indicationOptions &&
                  !!indicationOptions.length &&
                  indicationOptions.map((item) => {
                    return isPricelessIndication ? (
                      <IndicationOptionWithoutPrice
                        key={item.id}
                        data={item}
                        value={selectedOption}
                        onChange={(val) => {
                          capture(INDICATION_SELECT, {
                            applicationId,
                            indicationOptionId: val,
                            type: 'priceless',
                          });
                          props.onChange(val);
                        }}
                      />
                    ) : (
                      <IndicationOptionWithPrice
                        key={item.id}
                        data={item}
                        value={selectedOption}
                        onChange={(val) => {
                          capture(INDICATION_SELECT, {
                            applicationId,
                            indicationOptionId: val,
                          });
                          props.onChange(val);
                        }}
                      />
                    );
                  })}
              </>
            </Grid>
          </Grid>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h4" fontWeight="fontWeightBold">
            {isPricelessIndication ? 'Packages' : 'Indication'}
          </Typography>
          <FormHelperText style={{ maxWidth: 822 }} sx={{ marginTop: 1 }}>
            {isPricelessIndication
              ? 'These are the packages that we offer and does not guarantee a quote for this risk. All final determinations will be made following underwriter evaluation of the full submission.'
              : 'This is a preliminary pricing indication and does not guarantee a quote for this risk. All final determinations will be made following underwriter evaluation of the full submission.'}
          </FormHelperText>
        </Grid>

        <Controller
          control={control}
          name="id"
          rules={{
            required: 'Please select a package',
          }}
          render={renderContent}
        />
      </Grid>
    </>
  );
};

export const IndicationOptionsContainer = (props: any) => {
  const methods = useFormContext();

  return <IndicationOptions {...methods} {...props} />;
};

export default IndicationOptionsContainer;
