import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  CoverageRecord,
  CoverageType,
  IndicationOption,
} from '@nirvana/api/quoting';
import clsx from 'clsx';
import { ReactNode } from 'react';
import NumberFormat from 'react-number-format';

import Button from 'src/components/button';
import { application as ApplicationHelper } from 'src/helpers';

import { Show } from '@nirvana/ui-kit';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';

import { addPercents } from 'src/helpers/utils';
import { RangeIndication } from './rangeIndication';
import { useStyles } from './styles';

export const INDICATION_RANGES_PERCENT = {
  LOW: -15,
  HIGH: 20,
};

export interface PrimaryCoverageInfo extends CoverageRecord {
  name: string;
  primary: ReactNode;
  secondary: ReactNode;
}

const getPrimaryCoverageInfo = (
  list: CoverageRecord[],
  type: string,
  isIndicationRangesEnabled: boolean,
): PrimaryCoverageInfo => {
  for (let i = 0, l = list.length; i < l; i += 1) {
    if (list[i].coverageType === type) {
      return {
        ...list[i],
        name: `${ApplicationHelper.getIndicationOptionsFeatureLabel(
          list[i].coverageType,
        )}`,
        primary:
          type === CoverageType.CoverageGeneralLiability ? (
            <>
              <Show when={isIndicationRangesEnabled}>
                <div className="flex flex-row justify-center space-x-1">
                  <span className="flex">
                    <RangeIndication
                      indicationNumber={list[i]?.premium}
                      rangePercentLow={INDICATION_RANGES_PERCENT.LOW}
                      rangePercentHigh={INDICATION_RANGES_PERCENT.HIGH}
                    />
                  </span>
                  <span>Premium</span>
                </div>
              </Show>
              <Show when={!isIndicationRangesEnabled}>
                <NumberFormat
                  value={list[i]?.premium}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  suffix=" Premium"
                />
              </Show>
            </>
          ) : list[i]?.premiumPerUnit ? (
            <>
              <Show when={isIndicationRangesEnabled}>
                <div className="flex flex-row justify-center space-x-1">
                  <span className="flex">
                    <RangeIndication
                      indicationNumber={list[i]?.premiumPerUnit}
                      rangePercentLow={INDICATION_RANGES_PERCENT.LOW}
                      rangePercentHigh={INDICATION_RANGES_PERCENT.HIGH}
                    />
                  </span>
                  <span>per Unit</span>
                </div>
              </Show>
              <Show when={!isIndicationRangesEnabled}>
                <NumberFormat
                  value={list[i]?.premiumPerUnit}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  suffix={' per unit'}
                />
              </Show>
            </>
          ) : (
            <>
              <Show when={isIndicationRangesEnabled}>
                <div>
                  {addPercents(
                    list[i]?.TIVPercentage,
                    INDICATION_RANGES_PERCENT.LOW,
                    2,
                  )}
                  {'% '}-{' '}
                  {addPercents(
                    list[i]?.TIVPercentage,
                    INDICATION_RANGES_PERCENT.HIGH,
                    2,
                  )}
                  % of TIV
                </div>
              </Show>

              <Show when={!isIndicationRangesEnabled}>
                {list[i].TIVPercentage}% of TIV
              </Show>
            </>
          ),
        secondary:
          type === CoverageType.CoverageGeneralLiability ? (
            'Not subject to audit'
          ) : (
            <>
              <Show when={isIndicationRangesEnabled}>
                <div className="flex flex-row justify-center">
                  <span>Premium - </span>
                  <RangeIndication
                    indicationNumber={list[i]?.premium}
                    rangePercentLow={INDICATION_RANGES_PERCENT.LOW}
                    rangePercentHigh={INDICATION_RANGES_PERCENT.HIGH}
                  />
                </div>
              </Show>
              <Show when={!isIndicationRangesEnabled}>
                <NumberFormat
                  value={list[i]?.premium}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'Premium - $'}
                />
              </Show>
            </>
          ),
      };
    }
  }

  return {} as PrimaryCoverageInfo;
};

export interface AdditionalCoverageInfo {
  name: string;
  secondary: ReactNode;
}

const getAdditionalCoverages = (
  list: CoverageRecord[],
): AdditionalCoverageInfo[] => {
  let includedStatutoryCoverages = false;
  const coverages = [];
  const otherInclusions = [];
  const blanketAdditionalCoverages = (list || [])
    .filter((record) => {
      return (
        record.coverageType === CoverageType.CoverageBlanketAdditional ||
        record.coverageType === CoverageType.CoverageGlBlanketAdditional ||
        record.coverageType === CoverageType.CoverageMtcBlanketAdditional
      );
    })
    .map((record) => record.coverageType);
  const blanketWaiverCoverages = (list || [])
    .filter((record) => {
      return (
        record.coverageType ===
          CoverageType.CoverageBlanketWaiverOfSubrogation ||
        record.coverageType ===
          CoverageType.CoverageGlBlanketWaiverOfSubrogation ||
        record.coverageType ===
          CoverageType.CoverageMtcBlanketWaiverOfSubrogation
      );
    })
    .map((record) => record.coverageType);

  for (let i = 0, l = list.length; i < l; i += 1) {
    if (
      list[i].coverageType === CoverageType.CoverageAutoLiability ||
      list[i].coverageType === CoverageType.CoverageAutoPhysicalDamage ||
      list[i].coverageType === CoverageType.CoverageMotorTruckCargo ||
      list[i].coverageType === CoverageType.CoverageGeneralLiability ||
      list[i].coverageType === CoverageType.CoverageBlanketAdditional ||
      list[i].coverageType === CoverageType.CoverageBlanketWaiverOfSubrogation
    ) {
      continue;
    }
    if (
      ApplicationHelper.isCoverageStatutory(list[i].coverageType) &&
      !includedStatutoryCoverages
    ) {
      coverages.push({
        name: ApplicationHelper.getStatutoryCoveragesLabel(),
        secondary:
          '(UM/UIM/PIP/PPI/Med Pay) Offered at statutory minimum limits only where available',
      });
      includedStatutoryCoverages = true;
    } else if (
      list[i].coverageType === CoverageType.CoverageTrailerInterchange
    ) {
      otherInclusions.push(
        <div>
          {ApplicationHelper.getIndicationOptionsFeatureLabel(
            list[i].coverageType,
          )}{' '}
          - Limit{' '}
          <NumberFormat
            value={list[i].limit}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </div>,
      );
    } else if (!ApplicationHelper.isCoverageStatutory(list[i].coverageType)) {
      otherInclusions.push(
        <div>
          {ApplicationHelper.getIndicationOptionsFeatureLabel(
            list[i].coverageType,
          )}
        </div>,
      );
    }
  }

  if (blanketAdditionalCoverages.length) {
    otherInclusions.push(
      <div>
        {ApplicationHelper.getBlanketCoverageLabel(
          blanketAdditionalCoverages,
          'additional',
        )}
      </div>,
    );
  }

  if (blanketWaiverCoverages.length) {
    otherInclusions.push(
      <div>
        {ApplicationHelper.getBlanketCoverageLabel(
          blanketWaiverCoverages,
          'waiver',
        )}
      </div>,
    );
  }

  if (otherInclusions.length) {
    coverages.push({
      name: 'Additional Coverages',
      secondary: otherInclusions,
    });
  }

  return coverages;
};

type IndicationOptionWithPriceProps = {
  data: IndicationOption;
  value: string;
  onChange: (value: string) => void;
};

const IndicationOptionWithPrice = ({
  data,
  value,
  onChange,
}: IndicationOptionWithPriceProps) => {
  const classes = useStyles();
  const getFeatureValue = useFeatureFlag();

  const isIndicationRangesEnabled = getFeatureValue(
    Feature.INDICATION_RANGES,
    false,
  );

  const primaryCoverages = [
    getPrimaryCoverageInfo(
      data.coverages,
      CoverageType.CoverageAutoLiability,
      isIndicationRangesEnabled,
    ),
    getPrimaryCoverageInfo(
      data.coverages,
      CoverageType.CoverageAutoPhysicalDamage,
      isIndicationRangesEnabled,
    ),
    getPrimaryCoverageInfo(
      data.coverages,
      CoverageType.CoverageMotorTruckCargo,
      isIndicationRangesEnabled,
    ),
    getPrimaryCoverageInfo(
      data.coverages,
      CoverageType.CoverageGeneralLiability,
      isIndicationRangesEnabled,
    ),
  ];
  const additionalCoverages = getAdditionalCoverages(data.coverages);

  return (
    <Grid item flexGrow={1} style={{ display: 'flex', flex: 1 }}>
      <Card
        variant="outlined"
        className={clsx(classes.card, 'cursor-pointer', {
          [classes.selected]: value === data.id,
          [classes.recommended]: data?.isRecommended,
        })}
        onClick={() => {
          onChange(data.id);
        }}
      >
        {data?.isRecommended ? (
          <Box className={classes.cardChip}>
            <Typography variant="caption" fontWeight="600">
              Recommended
            </Typography>
          </Box>
        ) : null}

        <CardHeader
          title={
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h6"
                color="textPrimary"
                fontWeight="fontWeightBold"
              >
                {ApplicationHelper.getIndicationOptionTagLabel(data.optionTag)}
              </Typography>
              <div className={classes.cardHeaderBar} />
            </Box>
          }
          subheader={
            <>
              <Show when={isIndicationRangesEnabled}>
                <div className="flex flex-row items-center justify-center space-x-1 text-2xl">
                  <RangeIndication
                    indicationNumber={data.totalPremium}
                    rangePercentLow={INDICATION_RANGES_PERCENT.LOW}
                    rangePercentHigh={INDICATION_RANGES_PERCENT.HIGH}
                  />
                </div>
              </Show>

              <Show when={!isIndicationRangesEnabled}>
                <Typography
                  variant="h6"
                  component="span"
                  fontWeight="fontWeightRegular"
                  color="textPrimary"
                  className={classes.currencySymbol}
                >
                  $
                </Typography>
                <NumberFormat
                  value={data.totalPremium}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </Show>

              <Grid item>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  sx={
                    value === data.id
                      ? {
                          backgroundColor: () => '#D7DCFE !important',
                          marginTop: (theme) => theme.spacing(2),
                          '&:hover': {
                            backgroundColor: () => '#D7DCFE',
                          },
                        }
                      : {
                          marginTop: (theme) => theme.spacing(2),
                        }
                  }
                  fullWidth
                  size="large"
                >
                  {value === data.id ? 'Selected' : 'Select'}
                </Button>
              </Grid>
            </>
          }
          titleTypographyProps={{
            className:
              value === data.id ? classes.selectedTitle : classes.title,
            variant: 'h6',
            fontWeight: 'regular',
          }}
          subheaderTypographyProps={{
            variant: 'h3',
            color: 'secondary',
          }}
          classes={{
            root: classes.cardHeader,
            content: classes.cardHeaderContent,
          }}
        />
        <CardContent>
          <Grid container direction="column" spacing={4} alignItems="center">
            {primaryCoverages.map((coverage) => {
              if (!coverage || !coverage.name) {
                return null;
              }

              return (
                <Grid
                  key={coverage.name}
                  item
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <Typography
                    color="primary"
                    component="p"
                    fontWeight="fontWeightRegular"
                    mb={1}
                    textAlign="center"
                    variant="caption"
                  >
                    {coverage.name}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    component="p"
                    fontWeight="fontWeightBold"
                    mb={0.4}
                    textAlign="center"
                    variant="h6"
                  >
                    {coverage.primary}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    fontWeight="fontWeightRegular"
                    textAlign="center"
                  >
                    {coverage.secondary}
                  </Typography>
                </Grid>
              );
            })}

            {additionalCoverages.map((coverage) => {
              if (!coverage || !coverage.name) {
                return null;
              }

              return (
                <Grid
                  key={coverage.name}
                  item
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    component="p"
                    textAlign="center"
                    mb={1}
                  >
                    {coverage.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    textAlign="center"
                  >
                    {coverage.secondary}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default IndicationOptionWithPrice;
