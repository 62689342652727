import * as React from 'react';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/lab';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  Box,
} from '@material-ui/core';

export type DatePickerProps = Omit<MuiDatePickerProps, 'renderInput'> & {
  /** Current value of date */
  value: Date | null;
  /** Callback to handle change in selected date */
  onChange: (date: any) => void;
  /** Mui props for OutlinedInput component */
  InputProps?: OutlinedInputProps;
  /** Label for the DatePicker */
  label?: string;
};

/**
 * Date Picker component with text input.
 * Note: All props except label and className are passed down to MuiDatePicker.
 * @component
 *
 * @example
 * const selectedDate = new Date();
 * const handleDateChange = (date) => {
 *  console.log('Date changed: ', date);
 * };
 * return <DatePicker value={selectedDate} onChange={handleDateChange} label="Select start time" />
 */
export default function DatePicker({
  value,
  onChange,
  InputProps = {},
  label,
  ...rest
}: DatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <FormControl variant="outlined" fullWidth>
          {!!label && <InputLabel shrink>{label}</InputLabel>}
          <MuiDatePicker
            {...rest}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <OutlinedInput
                {...params.InputProps}
                inputProps={params.inputProps}
                ref={params.inputRef}
                label={label}
                notched
                {...InputProps}
              />
            )}
          />
        </FormControl>
      </Box>
    </LocalizationProvider>
  );
}
