import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { ApplicationDetail, CoverageType } from '@nirvana/api/quoting';
import { Checkbox } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { utils as UtilsHelper } from 'src/helpers';
import BaseFields from '../../../create/lossHistory/baseFields';

import {
  TableContainer,
  TableHeading,
  useStyles,
} from '../../../create/lossHistory/styles';
import SummaryFieldsBlock from '../../../create/lossHistory/summaryBlock';
import { DateRange, getDateRanges } from '../../../create/lossHistory/utils';
import { getApplicationById } from '../../queries';

interface LossRunFormMTCProps {
  applicationData: ApplicationDetail;
}

const LossRunFormMTC = ({ applicationData }: LossRunFormMTCProps) => {
  const classes = useStyles();

  const { setValue, watch } = useFormContext();

  const [isALAPDBound, setALAPDBinding] = React.useState<boolean>();

  const effectiveDateISO = applicationData?.summary.effectiveDate;

  const effectiveDate = React.useMemo(
    () => (effectiveDateISO ? parseISO(effectiveDateISO) : new Date()),
    [effectiveDateISO],
  );

  const dateRanges: DateRange[] = React.useMemo(() => {
    const dateRanges = getDateRanges(effectiveDate);
    return dateRanges;
  }, [effectiveDate]);

  const { data: originalApplicationInfo } = useQuery(
    [
      'originalApplication',
      applicationData.summary.renewalMetadata?.originalApplicationId,
    ],
    () => {
      if (applicationData.summary.renewalMetadata?.SectionCompletionMap) {
        return getApplicationById({
          applicationID:
            applicationData.summary.renewalMetadata?.originalApplicationId,
        });
      }
    },
    {
      enabled: !!applicationData.summary.renewalMetadata?.originalApplicationId,
    },
  );

  const originalCoveragesRequired =
    originalApplicationInfo?.indicationForm?.operationsForm?.coveragesRequired?.map(
      (coverage) => coverage.coverageType,
    ) || [];

  const applicableCoverages = React.useMemo(() => {
    const coverages =
      applicationData?.indicationForm?.operationsForm?.coveragesRequired?.map(
        (coverage) => coverage.coverageType,
      ) || [];

    const filteredCoverages = coverages.filter(
      (coverage) => coverage !== CoverageType.CoverageGeneralLiability,
    );
    const coverageByIndex = {
      [CoverageType.CoverageAutoLiability]: filteredCoverages.indexOf(
        CoverageType.CoverageAutoLiability,
      ),
    };

    if (filteredCoverages.includes(CoverageType.CoverageAutoPhysicalDamage)) {
      coverageByIndex[CoverageType.CoverageAutoPhysicalDamage] =
        filteredCoverages.indexOf(CoverageType.CoverageAutoPhysicalDamage);
    }

    if (filteredCoverages.includes(CoverageType.CoverageMotorTruckCargo)) {
      coverageByIndex[CoverageType.CoverageMotorTruckCargo] =
        filteredCoverages.indexOf(CoverageType.CoverageMotorTruckCargo);
    }

    return coverageByIndex;
  }, [applicationData?.indicationForm?.operationsForm?.coveragesRequired]);

  React.useEffect(() => {
    const lossSummary = applicationData?.indicationForm?.lossRunSummaryForm;
    if (isALAPDBound === undefined) {
      if (!lossSummary || !lossSummary.length) {
        // No data = new application
        setALAPDBinding(true);
      } else {
        // Check if AL power units = APD power units
        const summaryAL = lossSummary.find(
          (record) =>
            record.coverageType === CoverageType.CoverageAutoLiability,
        );
        const summaryAPD = lossSummary.find(
          (record) =>
            record.coverageType === CoverageType.CoverageAutoPhysicalDamage,
        );
        const powerUnitsAL = summaryAL?.lossRunSummary.map(
          (record) => record.numberOfPowerUnits,
        );
        const powerUnitsAPD = summaryAPD?.lossRunSummary.map(
          (record) => record.numberOfPowerUnits,
        );

        if (JSON.stringify(powerUnitsAL) === JSON.stringify(powerUnitsAPD)) {
          setALAPDBinding(true);
        } else {
          setALAPDBinding(false);
        }
      }
    }
  }, [applicationData, isALAPDBound]);

  React.useEffect(() => {
    const lossSummaryForm =
      applicationData?.indicationForm?.lossRunSummaryForm || [];

    // loop through the existing loss run summary and set the values
    lossSummaryForm.forEach((lossSummary, index) => {
      setValue(
        `lossRunSummaryForm[${index}].coverageType`,
        lossSummary.coverageType,
      );
      lossSummary.lossRunSummary.forEach(
        (lossRunSummary, lossRunSummaryIndex) => {
          setValue(
            `lossRunSummaryForm[${index}].lossRunSummary[${lossRunSummaryIndex}]`,
            { ...lossRunSummary },
          );
        },
      );
    });
  }, [applicationData?.indicationForm?.lossRunSummaryForm]);

  const summaryAL =
    watch(
      `lossRunSummaryForm[${
        applicableCoverages[CoverageType.CoverageAutoLiability]
      }].lossRunSummary`,
    ) || [];

  React.useEffect(() => {
    // Always update MTC, if available
    if (applicableCoverages[CoverageType.CoverageMotorTruckCargo]) {
      for (let i = 0, l = summaryAL.length; i < l; i += 1) {
        setValue(
          `lossRunSummaryForm[${
            applicableCoverages[CoverageType.CoverageMotorTruckCargo]
          }].lossRunSummary[${i}].numberOfPowerUnits`,
          summaryAL[i].numberOfPowerUnits,
        );
      }
    }

    // Auto update APD if bound
    if (isALAPDBound) {
      for (let i = 0, l = summaryAL.length; i < l; i += 1) {
        setValue(
          `lossRunSummaryForm[${
            applicableCoverages[CoverageType.CoverageAutoPhysicalDamage]
          }].lossRunSummary[${i}].numberOfPowerUnits`,
          summaryAL[i].numberOfPowerUnits,
        );
      }
    } else {
      // Do nothing
    }
  }, [isALAPDBound, applicableCoverages, summaryAL]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <FormHelperText>
          Losses should include indemnity paid + case reserve dollars only (no
          expense).
          <br />
          Claim counts are to be entered 1 per claim event (not per claimant)
          and should NOT include zero dollar indemnity events.
        </FormHelperText>
      </Grid>
      {/* AL & MTC */}
      <Grid item>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item xs={4}>
            <TableContainer>
              <BaseFields
                dateRanges={dateRanges}
                coverageIndex={
                  applicableCoverages[CoverageType.CoverageAutoLiability]
                }
                readOnly={false}
                disabledPredicate={(index) => {
                  if (
                    index === 0 &&
                    originalCoveragesRequired?.includes(
                      CoverageType.CoverageAutoLiability,
                    ) &&
                    originalCoveragesRequired?.includes(
                      CoverageType.CoverageMotorTruckCargo,
                    )
                  ) {
                    return true;
                  }
                  return false;
                }}
              />
            </TableContainer>
          </Grid>
          <Grid item xs={4}>
            <SummaryFieldsBlock
              coverage={CoverageType.CoverageAutoLiability}
              coverageIndex={
                applicableCoverages[CoverageType.CoverageAutoLiability]
              }
              dateRanges={dateRanges}
              disabledPredicate={(index) => index === 0}
            />
          </Grid>
          {UtilsHelper.isNumeric(
            applicableCoverages[CoverageType.CoverageMotorTruckCargo],
          ) && (
            <Grid item xs={4}>
              <Box sx={{ display: 'none' }}>
                <BaseFields
                  dateRanges={dateRanges}
                  coverageIndex={
                    applicableCoverages[CoverageType.CoverageMotorTruckCargo]
                  }
                  readOnly={isALAPDBound}
                  disabledPredicate={(index) =>
                    index === 0 &&
                    originalCoveragesRequired?.includes(
                      CoverageType.CoverageMotorTruckCargo,
                    )
                  }
                />
              </Box>
              <SummaryFieldsBlock
                coverage={CoverageType.CoverageMotorTruckCargo}
                coverageIndex={
                  applicableCoverages[CoverageType.CoverageMotorTruckCargo]
                }
                dateRanges={dateRanges}
                disabledPredicate={(index) =>
                  index === 0 &&
                  originalCoveragesRequired?.includes(
                    CoverageType.CoverageMotorTruckCargo,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* APD */}
      {UtilsHelper.isNumeric(
        applicableCoverages[CoverageType.CoverageAutoPhysicalDamage],
      ) && (
        <>
          {/* Separator */}
          <Grid item ml={2}>
            <Divider />
          </Grid>
          {/* APD */}
          <Grid item>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item xs={4}>
                <TableHeading>
                  <FormControlLabel
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                    sx={{ paddingLeft: 4 }}
                    onChange={(_, checked: boolean) => {
                      setALAPDBinding(checked);
                    }}
                    control={<Checkbox checked={!!isALAPDBound} />}
                    label="# of power units is the same"
                  />
                </TableHeading>
                <TableContainer>
                  <BaseFields
                    dateRanges={dateRanges}
                    coverageIndex={
                      applicableCoverages[
                        CoverageType.CoverageAutoPhysicalDamage
                      ]
                    }
                    readOnly={isALAPDBound}
                    disabledPredicate={(index) =>
                      index === 0 &&
                      originalCoveragesRequired?.includes(
                        CoverageType.CoverageAutoPhysicalDamage,
                      )
                    }
                  />
                </TableContainer>
              </Grid>
              <Grid item xs={4}>
                <SummaryFieldsBlock
                  coverage={CoverageType.CoverageAutoPhysicalDamage}
                  coverageIndex={
                    applicableCoverages[CoverageType.CoverageAutoPhysicalDamage]
                  }
                  dateRanges={dateRanges}
                  disabledPredicate={(index) =>
                    index === 0 &&
                    originalCoveragesRequired?.includes(
                      CoverageType.CoverageAutoPhysicalDamage,
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LossRunFormMTC;
