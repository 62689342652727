/* global google */
import { Map, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useState } from 'react';

import { Paper, Typography } from '@material-ui/core';
import { AutoComplete } from 'src/components/autoComplete';
import {
  getCity,
  getStateLongname,
  getStateShortname,
  getStreet,
  getZipCode,
} from './helpers';

type FormattedPlace = {
  street: string;
  city: string;
  stateCode: string;
  state: string;
  zip: string;
};

export interface AutocompleteAddressProps {
  onPlaceSelect: (
    formattedPlace: FormattedPlace,
    place: google.maps.places.PlaceResult | null,
  ) => void;
  value?: string;
}

export const AutocompleteAddress = ({
  onPlaceSelect,
  value = '',
}: AutocompleteAddressProps) => {
  const map = useMap();
  const places = useMapsLibrary('places');

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [places, map]);

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      try {
        const request = { input: inputValue, sessionToken };
        const response = await autocompleteService.getPlacePredictions(request);

        if (!response) {
          throw new Error('No predictions found');
        }

        setPredictionResults(response.predictions);
      } catch (ex) {
        console.error('Error fetching predictions: ', ex);
      }
    },
    [autocompleteService, sessionToken],
  );

  const onInputChange = useCallback(
    (value: string = '') => {
      setInputValue(value);
      fetchPredictions(value);

      if (!value) {
        onPlaceSelect(
          {
            street: '',
            city: '',
            stateCode: '',
            state: '',
            zip: '',
          },
          null,
        );
      }
    },
    [fetchPredictions, onPlaceSelect],
  );

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ['address_components', 'name', 'formatted_address'],
        sessionToken,
      };

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null,
      ) => {
        onPlaceSelect(
          {
            street: getStreet(placeDetails),
            city: getCity(placeDetails?.address_components ?? []),
            stateCode: getStateShortname(
              placeDetails?.address_components ?? [],
            ),
            state: getStateLongname(placeDetails?.address_components ?? []),
            zip: getZipCode(placeDetails?.address_components ?? []),
          },
          placeDetails,
        );
        setPredictionResults([]);
        setInputValue(getStreet(placeDetails));
        setSessionToken(new places.AutocompleteSessionToken());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [onPlaceSelect, places, placesService, sessionToken],
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className="autocomplete-container">
      <AutoComplete
        value={inputValue}
        autoCompleteProps={{
          noOptionsText: 'No locations',
          getOptionLabel: (option) =>
            typeof option === 'string' ? option : option.description,
          onInputChange: (_, newInputValue) => {
            onInputChange(newInputValue);
          },
          onChange: (_, option) => {
            if (option?.place_id) {
              handleSuggestionClick(option.place_id);
            }
          },
          PaperComponent: ({ children }) => (
            <Paper sx={{ padding: 0, py: 1 }}>
              <Typography variant="overline" color="text.hint" px={2}>
                SUGGESTIONS
              </Typography>
              {children}
            </Paper>
          ),
          renderOption: (props, option) => (
            <Typography variant="caption" {...props}>
              {/* Bold matching part of the description based on inputValue */}
              <p
                dangerouslySetInnerHTML={{
                  __html: option.description.replace(
                    new RegExp(inputValue, 'gi'),
                    (match: string) => `<strong>${match}</strong>`,
                  ),
                }}
              />
            </Typography>
          ),
        }}
        placeholder="Search for a place"
        focusPlaceholder="Start typing to search..."
        list={predictionResults}
      />

      {/* Render a hidden map to ensure that the maps API is loaded */}
      <div style={{ width: 0, height: 0 }}>
        <Map
          defaultZoom={3}
          defaultCenter={{ lat: 22.54992, lng: 0 }}
          gestureHandling="greedy"
          disableDefaultUI
        />
      </div>
    </div>
  );
};
