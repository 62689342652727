import { Grid, Typography } from '@material-ui/core';
import { ApplicationDetail, DriverListRecord } from '@nirvana/api/quoting';
import { AttachmentView, Show } from '@nirvana/ui-kit';
import { useMutation, useQuery } from '@tanstack/react-query';
import difference from 'lodash/difference';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SummaryView } from 'src/components/table';
import { fetchApplicationById } from 'src/features/application/actions';
import { application as ApplicationHelper } from 'src/helpers';
import { useDispatch } from 'src/redux';

import Section, { SectionDataRenderParams } from '../../create/review/section';
import { useStyles } from '../../create/review/styles';
import { SECTION_NAMES } from '../create';
import { ViewProps } from '../create/renewStep2';
import { getApplicationById, updateAdditionalForm } from '../queries';
import { getCoverageType } from '../utils';
import EditModeFooter from './footer/editModeFooter';
import MarkAsCompletedFooter from './footer/markAsCompletedFooter';
import AdditionalFormView from './forms/additionalFormView';

export const getDriversSummaryRows = (driversList: Array<DriverListRecord>) => {
  const driversSummaryValues = ApplicationHelper.getDriversSummary(driversList);

  const driversSummaryRows = [
    {
      label: 'Total # of drivers',
      value: driversSummaryValues.total,
    },
    {
      label: 'Drivers aged 18-30 years',
      value: driversSummaryValues['18-30'],
    },
    {
      label: 'Drivers aged 31-50 years',
      value: driversSummaryValues['31-50'],
    },
    {
      label: 'Drivers aged 51+ years',
      value: driversSummaryValues['51+'],
    },
  ];

  return driversSummaryRows;
};

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
});

const AdditionInformartionView = ({
  activeApplication: applicationData,
  isEditable,
  onMarkAsCompleted,
  isMarkAsCompleted,
  hideFooter = false,
  title = 'Additional Information',
  isSectionLoading,
}: ViewProps) => {
  const classes = useStyles();
  const methods = useForm();
  const [editMode, setEditMode] = React.useState(false);
  const [areCoveragesChanged, setAreCoveragesChanged] = React.useState(false);
  const [differenceIsCoverages, setDifferenceIsCoverages] = React.useState<
    string[]
  >([]);

  const [isCoverageChangeSeen, setIsCoverageChangeSeen] = React.useState(false);

  const dispatch = useDispatch();

  const { mutate: update } = useMutation(updateAdditionalForm, {
    onSuccess: () => {
      setEditMode(false);
      dispatch(fetchApplicationById(applicationData.summary.applicationID));
    },
  });

  const { data: originalApplicationInfo } = useQuery(
    [
      'originalApplication',
      applicationData.summary.renewalMetadata?.originalApplicationId,
    ],
    () => {
      if (applicationData.summary.renewalMetadata?.SectionCompletionMap) {
        return getApplicationById({
          applicationID:
            applicationData.summary.renewalMetadata?.originalApplicationId,
        });
      }
    },
    {
      enabled: !!applicationData.summary.renewalMetadata?.originalApplicationId,
    },
  );

  React.useEffect(() => {
    const newCoverages =
      applicationData.indicationForm?.operationsForm?.coveragesRequired?.map(
        (item) => item.coverageType,
      );

    const oldCoverages =
      originalApplicationInfo?.indicationForm?.operationsForm?.coveragesRequired?.map(
        (item) => item.coverageType,
      );

    const diffCoverages = difference(newCoverages, oldCoverages || []).map(
      (item) => getCoverageType(item),
    );

    setDifferenceIsCoverages(diffCoverages);
    setAreCoveragesChanged(diffCoverages.length > 0);
  }, [
    applicationData.indicationForm?.operationsForm?.coveragesRequired,
    originalApplicationInfo,
  ]);

  const setEditModeVal = (value: boolean) => {
    setEditMode(value);
  };

  const handleMarkAsCompleted = (checked: boolean) => {
    onMarkAsCompleted?.(SECTION_NAMES.ADDITIONAL_INFO, checked);
  };

  if (isMarkAsCompleted) {
    return (
      <Grid classes={{ root: classes.markAsCompleted }}>
        <Typography classes={{ root: classes.completedHeader }}>
          {title}
        </Typography>
        <MarkAsCompletedFooter
          checked={isMarkAsCompleted}
          onChange={() => handleMarkAsCompleted(false)}
          isSectionLoading={isSectionLoading}
        />
      </Grid>
    );
  }

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleUpdate = () => {
    methods.handleSubmit((data) => {
      const apiData = {
        driverList: applicationData.additionalInfoForm?.driverList,
        numOwnerOperatorUnits:
          applicationData.additionalInfoForm?.numOwnerOperatorUnits,
        lossRunFiles: applicationData.additionalInfoForm?.lossRunFiles,
        largeLossComment: applicationData.additionalInfoForm?.largeLossComment,
        overallComment: applicationData.additionalInfoForm?.overallComment,
        ...data,
      };

      update({
        applicationID: applicationData.summary.applicationID,
        additionalForm: apiData as any,
      });
    })();
  };

  return (
    <>
      <Section
        applicationData={applicationData}
        key="section-additional-info"
        title={title}
        onEdit={() => {
          setIsCoverageChangeSeen(true);
          setEditModeVal(true);
        }}
        isEditable={!editMode && isEditable}
        footer={
          hideFooter ? null : editMode ? (
            <EditModeFooter onCancel={handleCancel} onUpdate={handleUpdate} />
          ) : (
            <MarkAsCompletedFooter
              onChange={(checked) => handleMarkAsCompleted(checked)}
              isSectionLoading={isSectionLoading}
            />
          )
        }
        data={
          editMode
            ? [
                {
                  key: 'additionalForm',
                  getValue: (data: ApplicationDetail) => {
                    return data;
                  },
                  renderValue: () => {
                    return (
                      <FormProvider {...methods}>
                        <AdditionalFormView application={applicationData} />
                      </FormProvider>
                    );
                  },
                },
              ]
            : [
                {
                  key: 'driverList',
                  label: 'Updated drivers list',
                  getValue: (data: ApplicationDetail) => {
                    return data.additionalInfoForm?.driverList;
                  },
                  renderValue: ({
                    value,
                    previousValue,
                    hasChanged,
                  }: SectionDataRenderParams) => {
                    const previousDriversSummary = getDriversSummaryRows(
                      previousValue?.drivers,
                    );

                    return (
                      <Grid container direction="column" spacing={1}>
                        <Grid item>
                          <AttachmentView
                            files={[
                              {
                                filename:
                                  value?.flatfileMetadata?.fileMetadata.name,
                              },
                            ]}
                          />
                        </Grid>
                        {value?.drivers ? (
                          <Grid item>
                            <div className={classes.summaryContainer}>
                              <SummaryView
                                title="Document Summary"
                                rows={getDriversSummaryRows(value?.drivers).map(
                                  (record, index) => {
                                    return {
                                      ...record,
                                      value:
                                        hasChanged &&
                                        previousDriversSummary[index].value !==
                                          record.value ? (
                                          <mark>{record.value}</mark>
                                        ) : (
                                          record.value
                                        ),
                                    };
                                  },
                                )}
                              />
                            </div>
                          </Grid>
                        ) : null}
                      </Grid>
                    );
                  },
                },
                {
                  key: 'ownerOperators',
                  label: 'How many units in the fleet are owner-operators?',
                  getValue: (data: ApplicationDetail) => {
                    return data.additionalInfoForm?.numOwnerOperatorUnits;
                  },
                  renderValue: ({
                    value,
                    hasChanged,
                  }: SectionDataRenderParams) => {
                    return hasChanged ? <mark>{value}</mark> : value;
                  },
                },
                {
                  key: 'lossRuns',
                  label: 'Updated loss runs (4 years prior to Nirvana)',

                  getValue: (data: ApplicationDetail) => {
                    return data.additionalInfoForm?.lossRunFiles;
                  },
                  renderValue: ({ value }: SectionDataRenderParams) => {
                    return (
                      <>
                        <AttachmentView
                          files={
                            value?.map((item: any) => {
                              return {
                                filename: item.name || item.handle,
                              };
                            }) || []
                          }
                          showWarning={
                            areCoveragesChanged && !isCoverageChangeSeen
                          }
                        />
                        <Show
                          when={areCoveragesChanged && !isCoverageChangeSeen}
                        >
                          <div className="mt-1 text-xs text-warning-dark">
                            Please make sure to upload the loss runs for{' '}
                            {formatter.format(differenceIsCoverages)}
                          </div>
                        </Show>
                      </>
                    );
                  },
                },
                {
                  key: 'iftas',
                  label: 'Available IFTAs for the policy term',

                  getValue: (data: ApplicationDetail) => {
                    return data.summary.renewalMetadata?.iftaFiles;
                  },
                  renderValue: ({ value }: SectionDataRenderParams) => {
                    return (
                      <AttachmentView
                        files={
                          value?.map((item: any) => {
                            return {
                              filename: item.name || item.handle,
                            };
                          }) || []
                        }
                      />
                    );
                  },
                },
                {
                  key: 'lossRunNarrative',
                  label: 'Large losses',
                  getValue: (data: ApplicationDetail) => {
                    return data.additionalInfoForm?.largeLossComment;
                  },
                  renderValue: ({
                    value,
                    hasChanged,
                  }: SectionDataRenderParams) => {
                    return hasChanged ? (
                      <mark>{value || '-'}</mark>
                    ) : (
                      value || '-'
                    );
                  },
                },
                {
                  key: 'comments',
                  label: 'Additional information',
                  getValue: (data: ApplicationDetail) => {
                    return data.additionalInfoForm?.overallComment;
                  },
                  renderValue: ({
                    value,
                    hasChanged,
                  }: SectionDataRenderParams) => {
                    return hasChanged ? (
                      <mark>{value || '-'}</mark>
                    ) : (
                      value || '-'
                    );
                  },
                },
              ]
        }
      />
    </>
  );
};

export default AdditionInformartionView;
