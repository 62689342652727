import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { ApplicationDetail, CoverageType } from '@nirvana/api/quoting';
import { InputNumeric, ITheme } from '@nirvana/ui-kit';
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import IconPlus from 'src/assets/icons/plus-primary.svg';
import EquipmentUploadWithSummaryContainer from 'src/components/fileUpload/equipmentUploadWithSummary';
import { LocationForm, LocationList } from 'src/components/terminalLocation';
import { getHelperText } from '../../../create/additionalInformation';

interface OperationsFormViewProps {
  application: ApplicationDetail;
  childFormRef: React.MutableRefObject<any>;
}

export const useStyles = makeStyles((theme: ITheme) => ({
  formControl: {
    width: 220,
  },
  formControlLabel: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  radioLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'normal',
  },
  formLabel: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  },
}));

const OperationsFormView = ({
  application,
  childFormRef,
}: OperationsFormViewProps) => {
  const classes = useStyles();
  const [openEquipmentListDialog, setOpenEquipmentListDialog] =
    React.useState<boolean>(false);

  const [isUploadingEquipmentList, setIsUploadingEquipmentList] =
    React.useState<boolean>(false);

  const [isAddTerminalLocationFormVisible, setAddTerminalLocationVisibility] =
    React.useState<boolean>(false);

  const { control, errors } = useFormContext();

  const isGlCoverage =
    application?.indicationForm?.operationsForm?.coveragesRequired?.some(
      (record) => record.coverageType === CoverageType.CoverageGeneralLiability,
    );

  return (
    <Grid container direction="column" spacing={3}>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={4}>
          <InputLabel htmlFor="numberOfPowerUnits-input">
            Projected number of power units
          </InputLabel>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            <Controller
              name="numberOfPowerUnits"
              defaultValue={
                application?.indicationForm?.operationsForm?.numberOfPowerUnits
              }
              control={control}
              rules={{
                required: 'Please enter a value',
                pattern: {
                  value: /^[1-9]\d*$/,
                  message: 'Please enter a valid number',
                },
              }}
              render={(props) => (
                <FormControl className={classes.formControl}>
                  <InputNumeric
                    id="numberOfPowerUnits-input"
                    placeholder="0"
                    decimalScale={0}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    error={!!errors.numberOfPowerUnits}
                  />
                  {!!errors.numberOfPowerUnits && (
                    <FormHelperText error>
                      {getHelperText('numberOfPowerUnits', errors)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={4}>
          <InputLabel htmlFor="projectedMileage-input">
            Projected mileage
          </InputLabel>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            <Controller
              name="projectedMileage"
              defaultValue={
                application?.indicationForm?.operationsForm?.projectedMileage
              }
              control={control}
              rules={{
                required: 'Please enter a value',
                pattern: {
                  value: /^[1-9]\d*$/,
                  message: 'Please enter a valid number',
                },
              }}
              render={(props) => (
                <FormControl className={classes.formControl}>
                  <InputNumeric
                    id="projectedMileage-input"
                    placeholder="0"
                    decimalScale={0}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    error={!!errors.projectedMileage}
                  />
                  {!!errors.projectedMileage && (
                    <FormHelperText error>
                      {getHelperText('projectedMileage', errors)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <InputLabel htmlFor="projectedMileage-input">
            Updated equipment list
          </InputLabel>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            <EquipmentUploadWithSummaryContainer
              isUploading={isUploadingEquipmentList}
              setIsUploading={setIsUploadingEquipmentList}
              openUploadDialog={setOpenEquipmentListDialog}
              isDialogOpen={openEquipmentListDialog}
              fileDefaultValue={
                application?.indicationForm?.operationsForm?.equipmentList
              }
            />
          </Box>
        </Grid>
      </Grid>

      {isGlCoverage && (
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={4}>
            <Box paddingTop="10px">
              <InputLabel htmlFor="vehicleList-input">
                Physical business addresses
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="column">
              <Grid item>
                <Controller
                  control={control}
                  name="terminalLocations"
                  defaultValue={
                    application?.indicationForm?.operationsForm
                      ?.terminalLocations
                  }
                  rules={{
                    required: 'Please add a business address',
                    validate: {
                      minOne: (value) => {
                        if (value.length) return true;

                        return 'Please add at least 1 business address';
                      },
                    },
                  }}
                  render={(props) => {
                    return (
                      <>
                        <Box mb={2}>
                          <LocationList
                            list={props.value}
                            onChange={props.onChange}
                          />
                        </Box>

                        {isAddTerminalLocationFormVisible && (
                          <Box mb={2}>
                            <LocationForm
                              index={props.value?.length || 0}
                              ref={childFormRef}
                              onConfirm={(index: number, data) => {
                                const newLocations = !props.value
                                  ? []
                                  : [...props.value];
                                newLocations.splice(index, 0, data);

                                props.onChange(newLocations);
                                setAddTerminalLocationVisibility(false);
                              }}
                              onRemove={(index: number) => {
                                const newLocations = !props.value
                                  ? []
                                  : [...props.value];
                                newLocations.splice(index, 1);

                                props.onChange(newLocations);
                                setAddTerminalLocationVisibility(false);
                              }}
                            />
                          </Box>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (childFormRef.current) {
                      childFormRef.current.submitForm(
                        () => {
                          setAddTerminalLocationVisibility(true);
                        },
                        () => {},
                      );
                    } else {
                      setAddTerminalLocationVisibility(true);
                    }
                  }}
                  startIcon={
                    <img
                      src={IconPlus}
                      alt="Add terminal location"
                      width={11}
                    />
                  }
                >
                  Add New Location
                </Button>
              </Grid>
            </Grid>

            {!!errors.terminalLocations && (
              <FormHelperText error>
                {errors.terminalLocations.message}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OperationsFormView;
