import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
} from '@material-ui/core';
import {
  ArrowBackIosRounded as ArrowBackIosRoundedIcon,
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon,
} from '@material-ui/icons';
import { AdmittedApp, CoverageType, VehicleType } from '@nirvana/api/non-fleet';
import { ApplicationHeader } from '@nirvana/ui-kit';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import {
  useBeforeUnload,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Button from 'src/components/button';
import { ClearanceConflictDialog } from 'src/components/clearanceConflict';
import ServerError from 'src/components/serverError';

import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import { useApplicationDetailsContext } from '../../hooks/useApplicationDetails';
import {
  submitApplication,
  submitQuote,
  updateApplicationDetails,
} from '../../queries/application';
import { Step, getSteps } from './constants/steps';
import PageHeader from './pageHeader';
import { useStyles } from './styles';

const ApplicationCreate = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { applicationId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const getFeatureValue = useFeatureFlag();
  const isApplicationPersistenceEnabled = getFeatureValue(
    Feature.APPLICATION_PERSISTANCE,
    false,
  );
  const clearanceConflict = searchParams.get('clearanceConflict') || '0';
  const {
    applitionHeaderInfo,
    applicationDetails,
    isLoading: isLoadingApplicationDetails,
    setApplicationPanic,
    isApplicationPanic,
  } = useApplicationDetailsContext();

  const [isIndicationLoading, setIndicationLoading] = useState(false);
  const onIndicationLoadingChange = useCallback(setIndicationLoading, [
    setIndicationLoading,
  ]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const { state } = location;
  const activeStepStateKey = state?.activeStep;

  const handleStepEdit = useCallback((stepKey: string) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    gotoStep(stepKey);
  }, []);
  const Steps: Step[] = useMemo(
    () =>
      getSteps(applicationId, {
        onIndicationLoadingChange,

        onEdit: handleStepEdit,
      }),
    [applicationId, onIndicationLoadingChange, handleStepEdit],
  );
  const gotoStep = (stepKey: string) => {
    const stepIndex = Steps.findIndex((record) => stepKey === record.key);
    setActiveStepIndex(stepIndex);
  };

  const activeStep = Steps[activeStepIndex];
  const nextStep = Steps[Math.min(activeStepIndex + 1, Steps.length - 1)];
  const methods = useForm<AdmittedApp>();
  const { handleSubmit, reset, setError, getValues } = methods;

  const handlePrevious = () => {
    setActiveStepIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const queryClient = useQueryClient();

  const { mutate: submitIndicationMutation } = useMutation(submitQuote, {
    onSuccess: () => {
      setActiveStepIndex((prev) => prev + 1);

      queryClient.invalidateQueries(['application', applicationId]);
    },
    onError: (ex: AxiosError) => {
      if (ex.response?.status === 500) {
        setApplicationPanic(true);
      } else {
        navigate(`/non-fleet/application/${applicationId}/decline`);
      }
    },
  });

  const {
    mutate: submitApplicationMutation,
    isLoading: isSubmittingApplication,
  } = useMutation(submitApplication, {
    onSuccess: () => {
      navigate(`/non-fleet/application/${applicationId}/success`);
    },
    onError: (ex: AxiosError) => {
      if (ex.response?.status === 500) {
        setApplicationPanic(true);
      } else {
        navigate(`/non-fleet/application/${applicationId}/decline`);
      }
    },
  });

  const { mutate, isLoading: isUpdatingApplication } = useMutation(
    updateApplicationDetails,
    {
      onSuccess: (data) => {
        localStorage.removeItem(`unsavedApplication|${applicationId}`);

        if (data.appStatus === 'AppStateDeclined') {
          navigate(`/non-fleet/application/${applicationId}/decline`);
        } else if (activeStep.key === 'drivers') {
          // Submit for indication
          submitIndicationMutation(applicationId);
        } else if (activeStepIndex <= 3) {
          setActiveStepIndex((prev) => prev + 1);

          queryClient.invalidateQueries(['application', applicationId]);
        }
      },
      onError: (ex: AxiosError) => {
        if (ex.response?.status === 500) {
          setApplicationPanic(true);
        } else {
          navigate(`/non-fleet/application/${applicationId}/decline`);
        }
      },
    },
  );

  const isLoadingNextStep =
    isUpdatingApplication || isSubmittingApplication || isIndicationLoading;

  const onSubmit = (data: AdmittedApp) => {
    // Commodities distribution validation
    if (
      activeStepIndex === 0 &&
      data.operationsForm?.commodityDistribution &&
      data.operationsForm?.commodityDistribution?.length
    ) {
      // Calculate total and set error
      const total = data.operationsForm?.commodityDistribution?.reduce(
        (acc, { category, percentage }) => {
          if (category && percentage) {
            return acc + percentage;
          }

          return acc;
        },
        0,
      );

      if (total !== 100) {
        setError('operationsForm.commodityDistribution', {
          type: 'manual',
          message: 'Total percentage must be 100%',
        });

        return;
      }
    } else if (
      activeStep.key === 'equipment' &&
      data.equipmentsForm?.vehicles?.length
    ) {
      if (
        !data.equipmentsForm?.vehicles?.some(({ vehicleType }) =>
          [
            VehicleType.VehicleTypeTruck,
            VehicleType.VehicleTypeTractor,
          ].includes(vehicleType),
        )
      ) {
        return setError('equipmentsForm.vehicles', {
          [data.equipmentsForm?.vehicles?.length - 1]: {
            common: {
              type: 'manual',
              message: 'Please enter at least one power unit',
            },
          },
        });
      }

      // If APD coverage is required, check if stated value is entered for at least one vehicle
      if (
        data.operationsForm?.coverages?.find(
          (coverage) =>
            coverage.coverageType === CoverageType.CoverageAutoPhysicalDamage,
        )?.isRequired
      ) {
        const totalStatedValue = data.equipmentsForm?.vehicles?.reduce(
          (acc, { statedValue }) => {
            if (statedValue) {
              return acc + (statedValue ?? 0);
            }

            return acc;
          },
          0,
        );

        if (!totalStatedValue) {
          return setError('equipmentsForm.vehicles', {
            [data.equipmentsForm?.vehicles?.length - 1]: {
              common: {
                type: 'manual',
                message:
                  'Please enter stated value for at least one vehicle to cover in Auto Physical Damage',
              },
            },
          });
        }
      }

      const vins: string[] = data.equipmentsForm?.vehicles?.map((record) => {
        return record.vin;
      });

      const errors = {};
      const counts = {};
      const lastIndex: { [key: string]: number } = {};
      vins.forEach(function (vin, index) {
        counts[vin] = (counts[vin] || 0) + 1;
        if (counts[vin] > 1) {
          lastIndex[vin] = index;
        }
      });

      Object.values(lastIndex).forEach((index: number) => {
        errors[index] = {
          vin: {
            type: 'manual',
            message:
              'Please enter a valid VIN, duplicates are not allowed. For unidentified trailers, use "Trailer 1", "Trailer 2", etc.',
          },
        };
      });

      if (Object.keys(errors).length) {
        return setError('equipmentsForm.vehicles', errors);
      }
    } else if (activeStep.key === 'drivers') {
      const cdlNumbers: string[] =
        data.driversForm?.drivers?.map((record) => {
          return record.licenseNumber;
        }) ?? [];

      const errors = {};
      const counts = {};
      const lastIndex: { [key: string]: number } = {};
      cdlNumbers.forEach(function (cdl, index) {
        counts[cdl] = (counts[cdl] || 0) + 1;
        if (counts[cdl] > 1) {
          lastIndex[cdl] = index;
        }
      });

      Object.values(lastIndex).forEach((index: number) => {
        errors[index] = {
          licenseNumber: {
            type: 'manual',
            message:
              'Please enter a valid CDL number, duplicates are not allowed.',
          },
        };
      });

      if (Object.keys(errors).length) {
        return setError('driversForm.drivers', errors);
      }
    }

    if (activeStep.key === 'review') {
      // Submit application
      submitApplicationMutation(applicationId);
    } else {
      const updatedDriversForm = {
        ...data.driversForm,
        drivers: data?.driversForm?.drivers?.map((driver) => ({
          ...driver,
          isIncluded: true,
        })),
      };

      mutate({
        applicationId,
        payload: {
          pageState: nextStep.pageState,
          admitted: {
            operationsForm: {
              ...data.operationsForm,
              commodityDistribution:
                data.operationsForm?.commodityDistribution?.filter(
                  ({ category }) => category,
                ),
            },
            equipmentsForm: data.equipmentsForm,
            driversForm: updatedDriversForm,
            indicationForm: data.indicationForm,
          },
        },
      });
    }
  };

  useEffect(() => {
    // Find step with matching pageState
    const stepIndex = Steps.findIndex(
      (step) => step.pageState === applicationDetails?.pageState,
    );

    // Set active step index based on pageState
    if (stepIndex >= 0) {
      setActiveStepIndex(stepIndex);
    }
  }, [Steps, applicationDetails?.pageState]);

  useEffect(() => {
    let formData = applicationDetails?.admitted ?? {};
    let savedApplication;
    try {
      if (isApplicationPersistenceEnabled) {
        savedApplication = JSON.parse(
          localStorage.getItem(`unsavedApplication|${applicationId}`) || '{}',
        );
      }
    } catch (e) {
      savedApplication = { data: {} };
    }

    if (savedApplication?.currentStep === activeStepStateKey) {
      formData = {
        ...formData,
        ...savedApplication?.data,
      };
    }

    reset(formData);
  }, [
    applicationDetails,
    reset,
    activeStepStateKey,
    isApplicationPersistenceEnabled,
    applicationId,
  ]);

  useEffect(() => {
    if (activeStepStateKey) {
      handleStepEdit(activeStepStateKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepStateKey]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStepIndex]);

  const beforeUnloadEventHandler = useCallback(() => {
    if (isApplicationPersistenceEnabled && !_.isEmpty(getValues())) {
      localStorage.setItem(
        `unsavedApplication|${applicationId}`,
        JSON.stringify({
          currentStep: activeStepStateKey,
          timeStamp: Date.now(),
          data: getValues(),
        }),
      );
    }
  }, [applicationId, activeStepStateKey, isApplicationPersistenceEnabled]);

  useBeforeUnload(() => {
    beforeUnloadEventHandler();
  });

  useEffect(() => {
    return () => {
      beforeUnloadEventHandler();
    };
  }, []);

  if (isLoadingApplicationDetails) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (isApplicationPanic) {
    return (
      <Grid item container direction="column" flexGrow={1}>
        <Grid item>
          <ApplicationHeader
            details={applitionHeaderInfo}
            onBack={() => {
              navigate('/applications/list', { replace: true });
            }}
          />
        </Grid>
        <ServerError />
      </Grid>
    );
  }

  return (
    <>
      <ApplicationHeader
        details={applitionHeaderInfo}
        onBack={() => {
          navigate('/applications/list', { replace: true });
        }}
      />

      <Paper
        elevation={0}
        square
        sx={{ position: 'relative', flex: 1, display: 'flex' }}
      >
        <Container
          sx={{
            display: 'flex',
            maxWidth:
              activeStep.key === 'indication' ? '1056px !important' : undefined,
          }}
        >
          <Box py={5} px={3} display="flex" flexDirection="column" flex="1">
            <PageHeader
              title={activeStep.title}
              subtitle={activeStep.subtitle}
              activeStepIndex={activeStepIndex}
            />

            <FormProvider {...methods}>{activeStep.render()}</FormProvider>
          </Box>
        </Container>
      </Paper>

      {/* Spacer to compensate for height of fixed bottom bar */}
      <Box py="32px" />

      {/* Sticky bottom bar  */}
      <AppBar
        classes={{ root: classes.footerToolbar }}
        color="inherit"
        elevation={0}
        position="fixed"
        sx={{ top: 'auto', bottom: 0, py: 1 }}
      >
        <Container
          sx={{
            display: 'flex',
            maxWidth:
              activeStep.key === 'indication' ? '1056px !important' : undefined,
            justifyContent: 'flex-end',
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            {activeStepIndex >= 1 ? (
              <Box mx={1}>
                <Button
                  startIcon={<ArrowBackIosRoundedIcon />}
                  variant="outlined"
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
              </Box>
            ) : null}

            <Button
              endIcon={<ArrowForwardIosRoundedIcon />}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoadingNextStep}
            >
              {activeStepIndex === 4 ? 'Submit' : 'Proceed'}
            </Button>
          </Box>
        </Container>
      </AppBar>

      <ClearanceConflictDialog
        open={Boolean(+clearanceConflict)}
        onClose={() => {
          searchParams.delete('clearanceConflict');
          setSearchParams(searchParams);
        }}
      />
    </>
  );
};

export default ApplicationCreate;
