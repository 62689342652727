import { Box, Grid, Typography } from '@material-ui/core';
import {
  ApplicationDetail,
  CoverageRecord,
  CoverageType,
  IndicationOptionTag,
  Producer,
} from '@nirvana/api/quoting';
import { useMutation } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TableView } from 'src/components/table';
import { fetchApplicationById } from 'src/features/application/actions';
import { fetchIndicationOptions } from 'src/features/application/actions/indicationOptions';
import { application as ApplicationHelper } from 'src/helpers';
import Section, { SectionDataRenderParams } from '../../create/review/section';
import { useStyles } from '../../create/review/styles';
import { SECTION_NAMES } from '../create';
import { ViewProps } from '../create/renewStep2';
import { updateCoverages } from '../queries';
import EditModeFooter from './footer/editModeFooter';
import MarkAsCompletedFooter from './footer/markAsCompletedFooter';
import GetCoveragesFormView from './forms/coveragesFormView';

interface CoveragesViewProps extends ViewProps {
  availableProducers?: Producer[];
  resetMarkAsCompleted?: () => void;
}

const CoveragesView = ({
  availableProducers,
  activeApplication,
  isEditable = false,
  onMarkAsCompleted,
  isMarkAsCompleted,
  isSectionLoading,
  title = 'Coverages',
  hideFooter = false,
}: CoveragesViewProps) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      coveragesRequired: [{ coverageType: CoverageType.CoverageAutoLiability }],
    },
  });
  const params = useParams();
  const { applicationId = '' } = params;
  const { mutate: update } = useMutation(updateCoverages, {
    onSuccess: () => {
      dispatch(fetchApplicationById(applicationId));
      dispatch(fetchIndicationOptions(applicationId));
      setEditMode(false);
    },
  });

  const getProducerById = (producerId: string | undefined) => {
    if (!producerId) return { name: '' };

    return availableProducers?.find((record) => record.id === producerId);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleSave = () => {
    methods.handleSubmit((data: any) => {
      if (
        data.coveragesWithCombinedDeductibles?.length > 0 &&
        data.deductibles
      ) {
        data.deductibles[CoverageType.CoverageMotorTruckCargo] =
          data?.deductibles?.[CoverageType.CoverageAutoPhysicalDamage];
      }

      if (data.deductibles) {
        data.coveragesRequired.forEach((coverage: any) => {
          const deductible = data.deductibles[coverage.coverageType];

          if (deductible) {
            coverage.deductible = deductible;
          }
        });
      }

      if (data.limits) {
        data.coveragesRequired.forEach((coverage: any) => {
          const limit = data.limits[coverage.coverageType];
          if (limit) {
            coverage.limit = limit;
          }
        });
      }

      const coveragesData = {
        coveragesRequired:
          activeApplication.indicationForm?.operationsForm?.coveragesRequired ||
          [],
        coveragesWithCombinedDeductibles:
          activeApplication.indicationForm?.operationsForm
            ?.coveragesWithCombinedDeductibles,
        producerId:
          activeApplication.indicationForm?.operationsForm?.producerId || '',
        packageType:
          activeApplication.summary.packageType ||
          IndicationOptionTag.IndicationOptionTagStandard,
        ...data,
      };

      delete coveragesData.effectiveData;

      if (activeApplication.summary.applicationID) {
        update({
          applicationID: activeApplication.summary.applicationID,
          coverages: coveragesData,
        });
      }
    })();
  };

  const hasLimits =
    activeApplication.indicationForm?.operationsForm?.coveragesRequired?.some(
      (record) =>
        !!record.limit &&
        record.coverageType === CoverageType.CoverageMotorTruckCargo,
    );

  const setEditModeVal = (value: boolean) => {
    setEditMode(value);
  };

  if (isMarkAsCompleted) {
    return (
      <Grid classes={{ root: classes.markAsCompleted }}>
        <Typography classes={{ root: classes.completedHeader }}>
          {title}
        </Typography>
        <MarkAsCompletedFooter
          checked={isMarkAsCompleted}
          isSectionLoading={isSectionLoading}
          onChange={() => onMarkAsCompleted?.(SECTION_NAMES.COVERAGES, false)}
        />
      </Grid>
    );
  }

  return (
    <Section
      applicationData={activeApplication}
      title={title}
      onEdit={() => setEditModeVal(true)}
      footer={
        hideFooter ? null : editMode ? (
          <EditModeFooter onCancel={handleCancel} onUpdate={handleSave} />
        ) : (
          <MarkAsCompletedFooter
            onChange={(checked) =>
              onMarkAsCompleted?.(SECTION_NAMES.COVERAGES, checked)
            }
            isSectionLoading={isSectionLoading}
          />
        )
      }
      isEditable={!editMode && isEditable}
      data={
        editMode
          ? [
              {
                key: 'operationsForm',
                getValue: (data: ApplicationDetail) => {
                  return data;
                },
                renderValue: () => {
                  return (
                    <FormProvider {...methods}>
                      <GetCoveragesFormView
                        applicationData={activeApplication}
                        activeProducers={availableProducers}
                      />
                    </FormProvider>
                  );
                },
              },
            ]
          : [
              {
                key: 'coveragesRequired',
                label: 'Coverage requested',
                getValue: (data: ApplicationDetail) => {
                  return data?.indicationForm?.operationsForm
                    ?.coveragesRequired;
                },
                renderValue: ({
                  value,
                  hasChanged,
                }: SectionDataRenderParams) => {
                  return hasChanged ? (
                    <mark>
                      {value
                        .map((record: CoverageRecord) =>
                          ApplicationHelper.getIndicationOptionsFeatureLabel(
                            record.coverageType,
                          ),
                        )
                        .join(', ')}
                    </mark>
                  ) : (
                    value
                      ?.map((record: CoverageRecord) =>
                        ApplicationHelper.getIndicationOptionsFeatureLabel(
                          record.coverageType,
                        ),
                      )
                      .join(', ')
                  );
                },
              },
              {
                key: 'deductibles',
                label: 'Deductibles',
                getValue: (data: ApplicationDetail) => {
                  return data.indicationForm?.operationsForm;
                },
                renderValue: ({ value }: SectionDataRenderParams) => {
                  const {
                    coveragesRequired,
                    coveragesWithCombinedDeductibles,
                  } = value;

                  const isAPDMTCCombined = ApplicationHelper.isCombinedCoverage(
                    CoverageType.CoverageAutoPhysicalDamage,
                    coveragesWithCombinedDeductibles,
                  );
                  const deductiblesMap = {};
                  const possibleCoverages = [];
                  for (
                    let i = 0, l = coveragesRequired?.length;
                    i < l;
                    i += 1
                  ) {
                    deductiblesMap[coveragesRequired[i].coverageType] =
                      coveragesRequired[i].deductible;

                    if (
                      !(
                        isAPDMTCCombined &&
                        coveragesRequired[i].coverageType ===
                          CoverageType.CoverageMotorTruckCargo
                      ) &&
                      !(
                        coveragesRequired[i].coverageType ===
                        CoverageType.CoverageGeneralLiability
                      )
                    ) {
                      possibleCoverages.push(coveragesRequired[i].coverageType);
                    }
                  }

                  const orderedCoverages =
                    ApplicationHelper.getOrderedCoverages(possibleCoverages);

                  return (
                    <TableView
                      headers={orderedCoverages.map(
                        (coverage: CoverageType) => ({
                          key: coverage,
                          content: (
                            <>
                              {ApplicationHelper.getLabelByCoverage(
                                coverage,
                                coveragesWithCombinedDeductibles,
                              )}
                            </>
                          ),
                          width:
                            isAPDMTCCombined &&
                            coverage === CoverageType.CoverageAutoPhysicalDamage
                              ? '50%'
                              : '25%',
                        }),
                      )}
                      rows={[
                        {
                          columns: orderedCoverages?.map(
                            (coverage: CoverageType) => ({
                              content: (
                                <NumberFormat
                                  value={deductiblesMap[coverage] || 0}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={'$'}
                                />
                              ),
                              width:
                                isAPDMTCCombined &&
                                coverage ===
                                  CoverageType.CoverageAutoPhysicalDamage
                                  ? '50%'
                                  : '25%',
                            }),
                          ),
                        },
                      ]}
                    />
                  );
                },
              },
              ...(hasLimits
                ? [
                    {
                      key: 'limits',
                      label: 'Limits',
                      getValue: (data: ApplicationDetail) => {
                        return data.indicationForm?.operationsForm;
                      },
                      renderValue: ({ value }: SectionDataRenderParams) => {
                        const { coveragesRequired } = value;
                        const limitRecords =
                          coveragesRequired?.filter(
                            (record: CoverageRecord) =>
                              !!record.limit &&
                              // Show limits for MTC only
                              record.coverageType ===
                                CoverageType.CoverageMotorTruckCargo,
                          ) || [];

                        return (
                          <TableView
                            headers={limitRecords?.map(
                              (record: CoverageRecord) => ({
                                key: record.coverageType,
                                content: (
                                  <>
                                    {ApplicationHelper.getIndicationOptionsFeatureLabel(
                                      record.coverageType,
                                    )}
                                  </>
                                ),
                                width: '25%',
                              }),
                            )}
                            rows={[
                              {
                                columns: limitRecords?.map(
                                  (record: CoverageRecord) => ({
                                    content: (
                                      <NumberFormat
                                        value={record.limit || 0}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                      />
                                    ),
                                    width: '25%',
                                  }),
                                ),
                              },
                            ]}
                          />
                        );
                      },
                    },
                  ]
                : []),
              {
                key: 'packageSelected',
                label: 'Plan selected',

                getValue: (data: ApplicationDetail) => {
                  return data.summary.packageType;
                },
                renderValue: ({ value }: SectionDataRenderParams) => {
                  return (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">
                        {ApplicationHelper.getIndicationOptionTagLabel(value)}{' '}
                        plan
                      </Typography>
                    </Box>
                  );
                },
              },
              {
                key: 'effectiveDate',
                label: 'Effective date',
                getValue: (data: ApplicationDetail) => {
                  return data.summary?.effectiveDate;
                },
                renderValue: ({
                  value,
                  hasChanged,
                }: SectionDataRenderParams) => {
                  return hasChanged ? (
                    <mark>{format(parseISO(value), 'MM/dd/yyyy')}</mark>
                  ) : (
                    format(parseISO(value), 'MM/dd/yyyy')
                  );
                },
              },
              {
                key: 'producerId',
                label: 'Producer',
                getValue: (data: ApplicationDetail) => {
                  return data.indicationForm?.operationsForm?.producerId;
                },
                renderValue: ({
                  value,
                  hasChanged,
                }: SectionDataRenderParams) => {
                  return hasChanged ? (
                    <mark>{getProducerById(value)?.name}</mark>
                  ) : (
                    getProducerById(value)?.name
                  );
                },
              },
            ]
      }
    />
  );
};

export default CoveragesView;
