import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ProgramType, TSP } from '@nirvana/api/quoting';
import { Show, Switch, storage } from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import ServerError from 'src/assets/icons/server-error.svg';
import SuccessStatus from 'src/assets/icons/telematicsStatus.svg';
import { ITheme } from 'src/assets/themes';
import {
  analytics as Analytics,
  hooks as Hooks,
  telematics as Telematics,
} from 'src/helpers';

import { TELEMATICS_RESPONSES_STORAGE_KEY } from 'src/constants';
import { RequestStatus } from 'src/features/application/slices';
import { useDispatch } from 'src/redux';
import { completeTSPProviderConnection, fetchTelematicsInfo } from '../actions';
import { fetchSafetyLink } from '../queries';
import {
  setError,
  setStatus,
  setTelematicsResponse,
  telematicsSelector,
} from '../slices';
import { SafetyAccountCreateRedirect } from './SafetyAccountCreateRedirect';

const useStyles = makeStyles((theme: ITheme) => ({
  contentWrapper: {
    padding: theme.spacing(9, 0),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(17, 0),
    },
  },
}));

const ConnectionCompleted = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = Hooks.useQuery();
  const applicationIdParam = query.get('token') || '';
  const provider = query.get('provider');
  const queryError = query.get('error');
  const state = query.get('state');
  const stateJSON = atob(state || '');
  let programType: ProgramType | undefined;
  try {
    programType = JSON.parse(stateJSON)?.programType;
  } catch (e) {
    // Ignore
  }
  const { show } = useIntercom();
  const isOAuthSupported = provider
    ? Telematics.isOAuthSupported(provider as TSP)
    : false;

  const telematics = useSelector(telematicsSelector);
  const { telematicsResponse, status, error } = telematics;
  const [connectionStatus, setConnectionStatus] =
    React.useState<RequestStatus>('loading');
  const [applicationId, setApplicationId] = React.useState<
    string | undefined
  >();

  const { data: safetyLinkData } = useQuery({
    queryKey: ['safetyLink', applicationIdParam],
    queryFn: () => fetchSafetyLink(applicationIdParam, programType),
    enabled: !!applicationIdParam && provider === TSP.TspGeotab,
  });

  // Note: Enable this when intercom is disabled at app level
  // React.useEffect(() => {
  //   boot({});
  // }, [boot]);

  const savedTelematicsResponses = storage.get(
    TELEMATICS_RESPONSES_STORAGE_KEY,
  );
  React.useEffect(() => {
    if (queryError != null) {
      dispatch(setStatus('failed'));
      // Override the query parameter 'error' with our generic canceled message.
      dispatch(
        setError({ message: 'Installation of the Nirvana App was canceled.' }),
      );
    } else if (!provider) {
      dispatch(setStatus('failed'));
      dispatch(
        setError({
          message: "Request is missing the telematics 'provider' parameter",
        }),
      );
    } else if (!telematicsResponse) {
      // If no telematics response is in redux, then we need to either
      // fetch the saved response from local storage, or perform a fresh
      // OAuth2 token exchange.
      if (state) {
        const responsesByOAuth2State = JSON.parse(
          savedTelematicsResponses ?? '{}',
        );
        const existingResponse = responsesByOAuth2State[state];
        if (existingResponse) {
          // Success, we found a telematics response for this state in local storage!
          // This can happen, for example, if the user reloads the page. We need to
          // dispatch this cached response to make it available for our render function,
          // and avoid making another request to exchange our OAuth2 code, which would fail.
          dispatch(setStatus('succeeded'));
          dispatch(setTelematicsResponse(existingResponse));
          return;
        }

        // If we have no telematics response in either redux or local storage, then
        // we need to perform a fresh exchange of the OAuth2 code for an access token.
        dispatch(
          completeTSPProviderConnection({
            [provider]: {
              authCode: query.get('code'),
              error: query.get('error'),
              scope: query.get('scope'),
              state,
            },
            programType,
          }),
        ).then((response: any) => {
          if (completeTSPProviderConnection.fulfilled.match(response)) {
            // Trigger API to fetch fleet info
            if (response.payload) {
              setApplicationId(response.payload.applicationID);

              dispatch(
                fetchTelematicsInfo({
                  handleId: response.payload.handleID || '',
                  programType,
                }),
              ).then((infoResponse: any) => {
                if (fetchTelematicsInfo.fulfilled.match(infoResponse)) {
                  if (infoResponse.payload?.validationErrors?.length) {
                    // Show the validation screen
                    setConnectionStatus('failed');
                  } else {
                    // Show success screen
                    setConnectionStatus('succeeded');
                  }
                } else {
                  // Show success screen
                  setConnectionStatus('succeeded');
                }
              });
            }
          }
        });
      } else {
        // This is a non-OAuth2 telematics provider, so we just assume that
        // everything worked correctly.
        //
        // Note: We have to do a lowercase string comparison since the backend
        // hard codes 'tspSamsara' instead of 'TSPSamsara'.
        if (
          provider.toLowerCase() === TSP.TspSamsara.toLowerCase() ||
          provider.toLowerCase() === TSP.TspKeepTruckin.toLowerCase()
        ) {
          dispatch(setStatus('failed'));
          dispatch(
            setError({
              message:
                "Telematics connection requires an oauth 'state' query parameter",
            }),
          );
          return;
        }
        dispatch(setStatus('succeeded'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    telematicsResponse,
    savedTelematicsResponses,
    provider,
    dispatch,
    query.toString(),
  ]);

  const handleContactClick = () => {
    show();
  };

  const handleSafetyLinkClick = (uri?: string) => {
    Analytics.trackEvent({
      event: Analytics.AnalyticsEvent.TelematicsConsentSafetyClick,
      properties: {},
    });

    if (uri) {
      window.open(uri, '_blank');
    }
  };

  return (
    <Switch>
      <Switch.Match
        when={(state && connectionStatus === 'loading') || status === 'loading'}
      >
        <Box className={classes.contentWrapper} flexGrow={1}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Box sx={{ position: 'relative' }}>
                <CircularProgress />
              </Box>
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                fontWeight="fontWeightBold"
                color="textPrimary"
                textAlign="center"
                mb={4}
              >
                We are working on connecting to your ELD/telematics provider.
              </Typography>
            </Grid>
            <Grid item textAlign="center">
              <Typography
                variant="h6"
                fontWeight="fontWeightRegular"
                color="textSecondary"
                component="p"
                mb={3}
              >
                If you have any questions or would like to learn more, please
                contact us below.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleContactClick}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Switch.Match>
      <Switch.Match when={status === 'failed' || !!error || !!queryError}>
        <Box className={classes.contentWrapper} flexGrow={1}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <img src={ServerError} alt="Telematics failed status" />
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                fontWeight="fontWeightBold"
                color="textPrimary"
                textAlign="center"
                mb={4}
              >
                Uh oh, something went wrong! Please try again later.
              </Typography>
              <Show when={error?.message}>
                <Typography
                  variant="h6"
                  fontWeight="fontWeightBold"
                  color="error"
                  textAlign="center"
                  mb={4}
                >
                  {error?.message}
                </Typography>
              </Show>
            </Grid>
            <Grid item textAlign="center">
              <Typography
                variant="h6"
                fontWeight="fontWeightRegular"
                color="textSecondary"
                component="p"
                mb={3}
              >
                If you have any questions or would like to learn more, please
                contact us below.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleContactClick}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Switch.Match>
      <Switch.Match when={connectionStatus === 'failed'}>
        <Navigate to={`/telematics/validate?token=${applicationId}`} />
      </Switch.Match>
      <Switch.Match
        when={connectionStatus === 'succeeded' || status === 'succeeded'}
      >
        <Box className={classes.contentWrapper} flexGrow={1}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <img src={SuccessStatus} alt="Telematics success status" />
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                fontWeight="fontWeightBold"
                color="textPrimary"
                textAlign="center"
                mb={4}
              >
                Congratulations! Your quote will be ready soon!
              </Typography>
              {!isOAuthSupported && (
                <Typography
                  variant="h6"
                  fontWeight="fontWeightMedium"
                  color="textPrimary"
                  textAlign="center"
                  mb={4}
                >
                  We&apos;ll be in touch with you shortly if we need additional
                  information to complete the connection.
                </Typography>
              )}
              <Show when={telematicsResponse?.signUpURI}>
                <SafetyAccountCreateRedirect
                  url={telematicsResponse?.signUpURI || ''}
                  provider={provider as string}
                />
              </Show>
              <Show
                when={
                  !telematicsResponse?.signUpURI &&
                  (!!telematicsResponse?.safetyReportURI || safetyLinkData?.url)
                }
              >
                <Grid item textAlign="center">
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightMedium"
                    color="textPrimary"
                    textAlign="center"
                    mb={4}
                  >
                    In the meantime, view your tailored Safety recommendations
                    and insights.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleSafetyLinkClick(
                        telematicsResponse?.safetyReportURI ||
                          safetyLinkData?.url,
                      )
                    }
                    className="mb-4"
                  >
                    View Safety Recommendations
                  </Button>
                </Grid>
              </Show>
            </Grid>
            <Grid item textAlign="center">
              <Typography
                variant="h6"
                fontWeight="fontWeightRegular"
                color="textSecondary"
                component="p"
                mb={3}
              >
                If you have any questions or would like to learn more, please
                contact us below.
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleContactClick}
              >
                Contact Us
              </Button>
            </Grid>
            {/* TODO: Uncomment the following code block when FAQs screen is ready */}
            {/* <Grid item>
            <Typography
              component="p"
              variant="caption"
              textAlign="center"
              color="textPrimary"
              mb={2}
            >
              Have more questions? Please visit our <Link>FAQs</Link>
            </Typography>
          </Grid> */}
          </Grid>
        </Box>
      </Switch.Match>
    </Switch>
  );
};

export default ConnectionCompleted;
