import { Dialog, Show } from '@nirvana/ui-kit';

import Button from 'src/components/button';

import { DialogActions } from '@material-ui/core';
import ClearanceConflictIcon from 'src/assets/icons/clearance-conflict-declined.svg';

interface ClearanceConflictDialogDeclinedProps {
  open: boolean;
  onClose: () => void;
  handleCreateApplication?: () => void;
  dotNumber: number | string;
}

const ClearanceConflictDialogDeclined = ({
  onClose,
  open,
  handleCreateApplication,
  dotNumber,
}: ClearanceConflictDialogDeclinedProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className="flex flex-col justify-around items-center space-y-6 p-6 w-[758px] h-[350px]">
        <img src={ClearanceConflictIcon} />

        <div className="text-xl font-semibold text-center">
          Application for {dotNumber} has been previously declined
        </div>

        <div className="text-based">
          If you have new information that significantly changes the risk
          outlook for this fleet, you may complete the application and secure a{' '}
          <a
            className="text-primary-main"
            href="https://nirvana-public-assets.s3.us-east-2.amazonaws.com/assets/agent-resources/BOR+Rules.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            Broker of Record (BOR)
          </a>{' '}
          from the insured to proceed.
        </div>

        <div className="w-full">
          <div>
            Learn more in our{' '}
            <a
              className="text-primary-main"
              target="_blank"
              rel="noreferrer noopener"
              href="https://intercom.help/nirvana-insurance/en/articles/9435293-guide-to-clearance"
            >
              Guide to Clearance
            </a>
          </div>

          <div>
            Questions? Contact{' '}
            <a
              className="underline text-primary-main"
              href="mailto:support@nirvanatech.com"
            >
              support@nirvanatech.com
            </a>
          </div>
        </div>
      </div>

      <DialogActions className="flex flex-row justify-end mt-4 space-x-5 py-9 items-right">
        <Show when={!!handleCreateApplication}>
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              color="primary"
              className="h-10"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateApplication}
              className="h-10"
            >
              Continue
            </Button>
          </>
        </Show>
      </DialogActions>
    </Dialog>
  );
};

export default ClearanceConflictDialogDeclined;
