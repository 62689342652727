import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  Typography,
} from '@material-ui/core';
import {
  ApplicationDetail,
  CoverageRecord,
  CoverageType,
  Producer,
  IndicationOptionTag,
} from '@nirvana/api/quoting';
import { Checkbox, DatePicker, ITheme, Show } from '@nirvana/ui-kit';
import { MenuItem } from '@nirvana/ui-kit/src/components/menu/menu';

import clsx from 'clsx';
import {
  formatISO,
  isBefore as isDateBefore,
  isPast,
  isToday,
  isValid as isDateValid,
  startOfToday,
} from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { fetchDOTInformation } from 'src/features/application/actions';
import { fetchIndicationOptions } from 'src/features/application/actions/indicationOptions';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import getPackageName from 'src/helpers/packageHelper';
import { useDispatch } from 'src/redux';
import { getHelperText } from '../../../create/additionalInformation';
import Deductibles from './components/deductible';
import Limits from './components/limits';

interface GetCoveragesFormViewProps {
  applicationData: ApplicationDetail;
  activeProducers?: Producer[];
}

// TODO: MOVE THIS TO A SEPARATE FILE
export const useStyles = makeStyles((theme: ITheme) => ({
  formControl: {
    width: 220,
  },
  formControlLabel: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  radioLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'normal',
  },
  formLabel: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  },
}));

const GetCoveragesFormView = ({
  applicationData,
  activeProducers: producersList,
}: GetCoveragesFormViewProps) => {
  const classes = useStyles();
  const { control, errors, watch, setValue } = useFormContext();
  const getFeatureValue = useFeatureFlag();
  const dispatch = useDispatch();
  const applicationDotNumber = applicationData?.summary?.dotNumber;

  const [applicationUSState, setApplicationUSState] = useState<
    string | undefined
  >();

  const values = watch([
    'coveragesRequired',
    'producerId',
    'effectiveDate',
    'limits',
    'deductibles',
  ]);

  const coverages =
    values.coveragesRequired?.map((record: any) => record.coverageType) || [];

  const isGlCoverage = coverages.includes(
    CoverageType.CoverageGeneralLiability,
  );
  const isMTCCoverage = coverages.includes(
    CoverageType.CoverageMotorTruckCargo,
  );

  useEffect(() => {
    const coverageRecordArr: Array<{
      coverageType: CoverageType;
    }> = [];
    applicationData.indicationForm?.operationsForm?.coveragesRequired?.forEach(
      (record: CoverageRecord) => {
        coverageRecordArr.push({
          coverageType: record.coverageType,
        });
      },
    );

    setValue('coveragesRequired', coverageRecordArr);
  }, [
    applicationData.indicationForm?.operationsForm?.coveragesRequired,
    setValue,
  ]);

  const fetchUSState = useCallback(
    (dotNumber: number) => {
      dispatch(fetchDOTInformation({ dotNumber })).then((response) => {
        if (fetchDOTInformation.fulfilled.match(response)) {
          setApplicationUSState(response.payload.usState);
        }
      });
    },
    [dispatch],
  );

  const applicationId = applicationData?.summary.applicationID;

  useEffect(() => {
    dispatch(fetchIndicationOptions(applicationId));
  }, [applicationId]);

  useEffect(() => {
    if (applicationDotNumber) {
      fetchUSState(applicationDotNumber);
    }
  }, [applicationDotNumber, fetchUSState]);

  const handleDeductiblesChange = (inputName: string, deductibles: any) => {
    setValue(inputName, deductibles);
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="coverage-input">
              Coverages requested
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="coveragesRequired"
            control={control}
            defaultValue={
              applicationData?.indicationForm?.operationsForm?.coveragesRequired?.map(
                (record) => ({
                  coverageType: record.coverageType,
                }),
              ) || []
            }
            rules={{
              required: true,
            }}
            render={(props) => {
              const coverages =
                props.value?.map(
                  (record: CoverageRecord) => record.coverageType,
                ) || [];

              return (
                <FormGroup>
                  <Grid container direction="column">
                    <Grid item mb={2}>
                      <FormControlLabel
                        control={<Checkbox disabled checked={true} />}
                        label="Auto Liability"
                        classes={{
                          root: clsx(classes.formControlLabel),
                          label: classes.radioLabel,
                        }}
                      />
                    </Grid>
                    <Grid item container direction="column">
                      <Grid item>
                        <Typography variant="caption" color="text.hint">
                          Please select additional coverages
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          onChange={(_, checked) => {
                            const newCoverages = [...props.value];
                            if (checked) {
                              newCoverages.push({
                                coverageType:
                                  CoverageType.CoverageAutoPhysicalDamage,
                              });
                            } else {
                              newCoverages.splice(
                                coverages.indexOf(
                                  CoverageType.CoverageAutoPhysicalDamage,
                                ),
                                1,
                              );
                            }

                            props.onChange(newCoverages);
                          }}
                          control={
                            <Checkbox
                              checked={coverages.includes(
                                CoverageType.CoverageAutoPhysicalDamage,
                              )}
                            />
                          }
                          label="Physical Damage"
                          classes={{
                            root: clsx(classes.formControlLabel),
                            label: classes.radioLabel,
                          }}
                        />

                        <FormControlLabel
                          onChange={(_, checked) => {
                            const newCoverages = [...props.value];
                            if (checked) {
                              newCoverages.push({
                                coverageType:
                                  CoverageType.CoverageMotorTruckCargo,
                              });
                            } else {
                              newCoverages.splice(
                                coverages.indexOf(
                                  CoverageType.CoverageMotorTruckCargo,
                                ),
                                1,
                              );
                            }

                            props.onChange(newCoverages);
                          }}
                          control={<Checkbox checked={isMTCCoverage} />}
                          label="Motor Truck Cargo"
                          classes={{
                            root: clsx(classes.formControlLabel),
                            label: classes.radioLabel,
                          }}
                        />

                        {/* Show GL coverage option only if feature flag is enabled and state criteria is met */}
                        <Show
                          when={
                            isGlCoverage ||
                            getFeatureValue(
                              Feature.GENERAL_LIABILITY_RELEASED_STATES,
                              '',
                            ).includes(applicationUSState) ||
                            getFeatureValue(
                              Feature.GENERAL_LIABILITY_INTERNAL_STATES,
                              '',
                            ).includes(applicationUSState)
                          }
                        >
                          <FormControlLabel
                            onChange={(_, checked) => {
                              const newCoverages = [...props.value];
                              if (checked) {
                                newCoverages.push({
                                  coverageType:
                                    CoverageType.CoverageGeneralLiability,
                                });
                              } else {
                                newCoverages.splice(
                                  coverages.indexOf(
                                    CoverageType.CoverageGeneralLiability,
                                  ),
                                  1,
                                );
                              }

                              props.onChange(newCoverages);
                            }}
                            control={<Checkbox checked={isGlCoverage} />}
                            label="General Liability"
                            classes={{
                              root: clsx(classes.formControlLabel),
                              label: classes.radioLabel,
                            }}
                          />
                        </Show>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormGroup>
              );
            }}
          />
        </Grid>
      </Grid>

      <Deductibles
        applicationData={applicationData}
        onChange={handleDeductiblesChange}
      />
      <Limits applicationData={applicationData} />

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={4}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="plan-selected-select">
              Plan Selected
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="packageType"
            defaultValue={applicationData.summary.packageType}
            rules={{
              required: 'Please select a plan',
            }}
            control={control}
            render={(props) => (
              <FormControl className={classes.formControl}>
                <Select
                  id="plan-select"
                  displayEmpty
                  variant="outlined"
                  value={props.value}
                  onChange={props.onChange}
                  error={!!errors.packageType}
                >
                  {Object.keys(IndicationOptionTag)?.map((option) => {
                    return (
                      <MenuItem value={option} key={option}>
                        {getPackageName(IndicationOptionTag[option])}
                      </MenuItem>
                    );
                  })}
                </Select>
                {!!errors.packageType && (
                  <FormHelperText error>
                    {getHelperText('packageType', errors)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="coverage-input">Effective date</InputLabel>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <FormControl className={classes.formControl}>
            <Controller
              name="effectiveDate"
              control={control}
              defaultValue={new Date(applicationData?.summary?.effectiveDate)}
              rules={{
                required: 'Please select effective date',
                validate: {
                  validateDate: (v) => {
                    if (!isDateValid(v)) {
                      return 'Please enter a valid date (mm/dd/yyyy)';
                    }

                    // if (isDateAfter(v, addMonths(startOfToday(), 4))) {
                    //   return 'Effective date cannot be more than four months into the future';
                    // }

                    if (isDateBefore(v, startOfToday())) {
                      return 'Effective date cannot be in the past';
                    }

                    return (
                      /^(2\d{3})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/.test(
                        formatISO(v, {
                          representation: 'date',
                        }),
                      ) || 'Please enter a valid date (mm/dd/yyyy)'
                    );
                  },
                },
              }}
              render={(props) => {
                return (
                  <DatePicker
                    disabled
                    inputFormat="MM/dd/yyyy"
                    shouldDisableDate={(date: unknown) =>
                      date instanceof Date
                        ? isPast(date) && !isToday(date)
                        : false
                    }
                    value={props.value}
                    onChange={(value) => props.onChange(value)}
                    PopperProps={{
                      placement: 'bottom',
                      modifiers: [
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom', 'right', 'top'],
                          },
                        },
                      ],
                    }}
                    InputProps={{
                      placeholder: 'eg. 12/31/2021',
                      fullWidth: true,
                      disabled: true,
                      error: !!errors.effectiveDate,
                    }}
                  />
                );
              }}
            />

            {!!errors.effectiveDate && (
              <FormHelperText error>
                {errors.effectiveDate?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={4}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="producer-select">Producer</InputLabel>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="producerId"
            defaultValue={
              applicationData.indicationForm?.operationsForm?.producerId || ''
            }
            rules={{
              required: 'Please select a producer',
            }}
            control={control}
            render={(props) => (
              <FormControl className={classes.formControl}>
                <Select
                  id="producer-select"
                  displayEmpty
                  variant="outlined"
                  value={props.value}
                  onChange={props.onChange}
                  error={!!errors.producerId}
                >
                  {producersList?.map((producer: Producer) => {
                    return (
                      <MenuItem value={producer.id} key={producer.id}>
                        {producer.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {!!errors.producerId && (
                  <FormHelperText error>
                    {getHelperText('producerId', errors)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GetCoveragesFormView;
