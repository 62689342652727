import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CommodityCategory,
  OperatingClass,
  PrimaryCommodityHauled,
  SupportedOperationsRecordV2,
} from '@nirvana/api/quoting';

import { RootState } from 'src/redux/reducers';
import { application as ApplicationHelper } from 'src/helpers';

import FlatbedInactive from 'src/assets/icons/flatbed-greyscale.png';
import DumpInactive from 'src/assets/icons/dump-greyscale.png';
import HazmatInactive from 'src/assets/icons/hazmat-greyscale.png';
import IntermodalInactive from 'src/assets/icons/intermodal-greyscale.png';
import TankerInactive from 'src/assets/icons/tanker-greyscale.png';
import VanInactive from 'src/assets/icons/van-greyscale.png';
import RefrigeratedInactive from 'src/assets/icons/refrigerated-greyscale.png';
import HeavyHaulInactive from 'src/assets/icons/heavy-haul-greyscale.png';
import FlatbedActive from 'src/assets/icons/flatbed-color.png';
import DumpActive from 'src/assets/icons/dump-color.png';
import HazmatActive from 'src/assets/icons/hazmat-color.png';
import IntermodalActive from 'src/assets/icons/intermodal-color.png';
import TankerActive from 'src/assets/icons/tanker-color.png';
import VanActive from 'src/assets/icons/van-color.png';
import RefrigeratedActive from 'src/assets/icons/refrigerated-color.png';
import HeavyHaulActive from 'src/assets/icons/heavy-haul-color.png';
import { fetchOperations } from '../actions/classesAndCommodities';

const OperationIconInactive = {
  [OperatingClass.OperatingClassDryVan]: VanInactive,
  [OperatingClass.OperatingClassDump]: DumpInactive,
  [OperatingClass.OperatingClassFlatbed]: FlatbedInactive,
  [OperatingClass.OperatingClassHazmat]: HazmatInactive,
  [OperatingClass.OperatingClassHeavyHaul]: HeavyHaulInactive,
  [OperatingClass.OperatingClassIntermodal]: IntermodalInactive,
  [OperatingClass.OperatingClassRefrigerated]: RefrigeratedInactive,
  [OperatingClass.OperatingClassTanker]: TankerInactive,
};

const OperationIconActive = {
  [OperatingClass.OperatingClassDryVan]: VanActive,
  [OperatingClass.OperatingClassDump]: DumpActive,
  [OperatingClass.OperatingClassFlatbed]: FlatbedActive,
  [OperatingClass.OperatingClassHazmat]: HazmatActive,
  [OperatingClass.OperatingClassHeavyHaul]: HeavyHaulActive,
  [OperatingClass.OperatingClassIntermodal]: IntermodalActive,
  [OperatingClass.OperatingClassRefrigerated]: RefrigeratedActive,
  [OperatingClass.OperatingClassTanker]: TankerActive,
};

export interface OperationError {
  message: string;
}

export declare type RequestStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface ICommodity {
  label: string;
  value: string;
}

export interface IOperationClass {
  label: string;
  value: string;
  iconInactive: any;
  iconActive: any;
  commodities: Array<ICommodity>;
  mtcCommodities?: Array<ICommodity>;
}

export interface OperationState {
  list: Array<IOperationClass>;
  commoditiesByClass: { [key: string]: Array<PrimaryCommodityHauled> };
  categories: CommodityCategory[];
  isFetchingCategories: boolean;
  error?: OperationError;
}

export const initialState: OperationState = {
  list: [],
  commoditiesByClass: {},
  categories: [],
  isFetchingCategories: false,
};

const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {
    setListSuccess: (
      state,
      { payload }: PayloadAction<SupportedOperationsRecordV2>,
    ) => {
      const { categories, data } = payload;

      state.categories = categories;

      data.forEach((record) => {
        state.commoditiesByClass[record.operatingClass] =
          record.primaryOperatingCommodities;
      });

      state.list = data.map((record) => ({
        // TODO: Use classes instead of operatingClass
        label: ApplicationHelper.getOperatingClassLabel(record?.operatingClass),
        value: record?.operatingClass,
        commodities: record?.primaryOperatingCommodities.map(
          (commodity: PrimaryCommodityHauled) => ({
            label:
              commodity.label !== undefined ? commodity.label : commodity.type,
            value: commodity.type,
          }),
        ),
        iconInactive: OperationIconInactive[record?.operatingClass],
        iconActive: OperationIconActive[record?.operatingClass],
      }));
    },
    setListFail: (state, { payload }: PayloadAction<OperationError>) => {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOperations.pending, (state) => {
      state.isFetchingCategories = true;
    });
    builder.addCase(fetchOperations.fulfilled, (state) => {
      state.isFetchingCategories = false;
    });
    builder.addCase(fetchOperations.rejected, (state) => {
      state.isFetchingCategories = false;
    });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = operationSlice;

// Extract and export each action creator by name
export const { setListSuccess, setListFail } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const operationSelector = (state: RootState) => state.operation;
