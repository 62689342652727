import { Dialog } from '@nirvana/ui-kit';

import PossibleRenewalConflictIcon from 'src/assets/icons/clearance-conflict-declined.svg';

interface PossibleRenewalProps {
  open: boolean;
  onClose: () => void;
}

const PossibleRenewalDialog = ({ onClose, open }: PossibleRenewalProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className="flex flex-col items-center space-y-8 p-6 w-[758px] h-[390px]">
        <img src={PossibleRenewalConflictIcon} />
        <div className="space-y-12">
          <div className="text-xl font-semibold text-center">
            Renewal in Review
          </div>

          <div className="space-y-6 text-base">
            <div>
              The assigned underwriter is reviewing your renewal and will get
              back to you with the renewal application shortly
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PossibleRenewalDialog;
