import { Box, CircularProgress, Grid } from '@material-ui/core';
import { ApplicationDetail } from '@nirvana/api/quoting';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAvailableProducers } from 'src/features/application/actions';
import { applicationSelector } from 'src/features/application/slices';
import { SECTION_NAMES } from '.';
import AdditionInformartionView from '../views/additionalInformationView';
import ClassesAndCommoditiesView from '../views/classesAndCommoditiesView';
import CoveragesView from '../views/coveragesView';
import LossHistoryView from '../views/lossHistoryView';
import OperationsView from '../views/operationsView';

interface ApplicationRenewStep2Props {
  markAsCompleted: { [key: string]: boolean };
  handleMarkAsCompleted: (section: string, isCompleted: boolean) => void;
  state: any;
}

export interface ViewProps {
  activeApplication: ApplicationDetail;
  isEditable?: boolean;
  isMarkAsCompleted?: boolean;
  onMarkAsCompleted?: (section: string, value: boolean) => void;
  title?: string;
  markAsCompletedMap?: { [key: string]: boolean };
  isSectionLoading?: boolean;
  hideFooter?: boolean;
}

const ApplicationRenewStep2 = ({
  markAsCompleted,
  handleMarkAsCompleted,
  state: sectionLoadingState,
}: ApplicationRenewStep2Props) => {
  const dispatch = useDispatch();

  const application = useSelector(applicationSelector);

  const { activeApplication, availableProducers } = application;

  React.useEffect(() => {
    dispatch(fetchAvailableProducers());
  }, [dispatch]);

  if (!activeApplication) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const commonProps = {
    activeApplication: activeApplication,
    isEditable: true,
    onMarkAsCompleted: handleMarkAsCompleted,
  };

  return (
    <Grid container py={5} spacing={4}>
      <Grid item xs={12}>
        <CoveragesView
          {...commonProps}
          availableProducers={availableProducers}
          isMarkAsCompleted={markAsCompleted[SECTION_NAMES.COVERAGES]}
          isSectionLoading={sectionLoadingState[SECTION_NAMES.COVERAGES]}
          title="Coverages"
        />
      </Grid>
      <Grid item xs={12}>
        <OperationsView
          {...commonProps}
          isMarkAsCompleted={markAsCompleted[SECTION_NAMES.OPERATIONS]}
          title="Operations"
          markAsCompletedMap={markAsCompleted}
          isSectionLoading={sectionLoadingState[SECTION_NAMES.OPERATIONS]}
        />
      </Grid>
      <Grid item xs={12}>
        <ClassesAndCommoditiesView
          {...commonProps}
          isMarkAsCompleted={
            markAsCompleted[SECTION_NAMES.CLASSES_AND_COMMODITIES]
          }
          title="Classes and Commodities"
          isSectionLoading={
            sectionLoadingState[SECTION_NAMES.CLASSES_AND_COMMODITIES]
          }
        />
      </Grid>
      <Grid item xs={12}>
        <LossHistoryView
          {...commonProps}
          isMarkAsCompleted={markAsCompleted[SECTION_NAMES.LOSS_HISTORY]}
          isSectionLoading={sectionLoadingState[SECTION_NAMES.LOSS_HISTORY]}
          title="Loss History"
        />
      </Grid>
      <Grid item xs={12}>
        <AdditionInformartionView
          {...commonProps}
          isMarkAsCompleted={markAsCompleted[SECTION_NAMES.ADDITIONAL_INFO]}
          isSectionLoading={sectionLoadingState[SECTION_NAMES.ADDITIONAL_INFO]}
          title="Additional Information"
        />
      </Grid>
    </Grid>
  );
};

export default ApplicationRenewStep2;
