import { Dialog, Show } from '@nirvana/ui-kit';
import { useEffect } from 'react';
import { getProviderInfo, isLogoAvailable } from 'src/helpers/telematics';
import { TSP } from '@nirvana/api/quoting';
import { Typography } from '@material-ui/core';
import IconSuccess from 'src/assets/icons/success-3d.svg';

const getProviderTyped = (provider: string) => {
  switch (provider) {
    case 'tspSamsara':
      return TSP.TspSamsara;
    case 'tspKeepTruckin':
      return TSP.TspKeepTruckin;
    // We only support the first two TSPs for now
    default:
      return provider;
  }
};

export const SafetyAccountCreateRedirect = ({
  url,
  provider,
}: {
  url: string;
  provider: string;
}) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = url;
    }, 3000);
  }, [url]);
  const providerTyped = getProviderTyped(provider);

  return (
    <Dialog onClose={() => {}} open maxWidth="xs">
      <div className="flex flex-col items-center p-4">
        <Show when={providerTyped && isLogoAvailable(providerTyped as TSP)}>
          <img
            src={getProviderInfo(providerTyped as TSP).logo}
            alt={provider}
            className="mb-4"
          />
        </Show>
        <Typography
          textAlign="center"
          lineHeight={1.25}
          component="p"
          maxWidth={320}
        >
          Your connection with{' '}
          <strong className="capitalize">
            {getProviderInfo(providerTyped as TSP).name}
          </strong>{' '}
          established successfully
        </Typography>
        <img src={IconSuccess} alt="Success" className="my-8" width={115} />

        <Typography textAlign="center" fontWeight="bold" mb={2}>
          Success!
        </Typography>
        <Typography textAlign="center">
          You will be re-directed automatically in 3 seconds to to finish the
          last step
        </Typography>
      </div>
    </Dialog>
  );
};
