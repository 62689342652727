import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import {
  TelematicsConsentInfo,
  ProgramType,
  TSP,
  TSPConnection,
  TSPRecord,
  TelematicsConsentKind,
} from '@nirvana/api/quoting';
import { EMAIL_REGEX } from '@nirvana/ui-kit';
import clsx from 'clsx';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HorizontalStepper } from 'src/components/stepper';
import { hooks as Hooks, telematics as Telematics } from 'src/helpers';

import sortBy from 'lodash/sortBy';
import IconApps from 'src/assets/icons/apps.svg';
import IconBack from 'src/assets/icons/arrow-left.svg';
import { useAnalytics } from 'src/helpers/analytics';
import { customTelematicsSorter } from 'src/helpers/telematics';
import useNavigateParams from 'src/hooks/useNavigateParams';
import { fetchTSPProviderConnection, fetchTSPProviders } from '../../actions';
import { setStatus, telematicsSelector } from '../../slices';
import OtherVendors from './otherVendors';
import { BackgroundGradient, useStyles } from './styles';
import PremierConnectionDialog from './premier-connection-dialog';

type TelematicsProvidersSelectProps = {
  consentInfo?: TelematicsConsentInfo;
};

const TelematicsProvidersSelect = ({
  consentInfo,
}: TelematicsProvidersSelectProps) => {
  const classes = useStyles();
  const navigateWithParams = useNavigateParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = Hooks.useQuery();
  const { capture } = useAnalytics();
  const applicationId = query.get('token') || '';
  const programType =
    ((query.get('programType') || '') as ProgramType) || undefined;
  const [dialogVisibility, setDialogVisibility] =
    React.useState<boolean>(false);
  const [premierProviderDialogVisibility, setPremierProviderDialogVisibility] =
    React.useState<boolean>(false);
  const [isFetchingConnection, setFetchingConnection] =
    React.useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = React.useState<TSPRecord>();
  const [selectedOtherProvider, setSelectedOtherProvider] = React.useState<
    string | undefined
  >();
  const [serverError, setServerError] = React.useState<string>('');
  const { control, handleSubmit, errors } = useForm();
  const telematics = useSelector(telematicsSelector);
  const { applicationInfoStatus, connections, providers, status } = telematics;
  const recommendedProviders = React.useMemo(() => {
    const telematics = providers.filter((record) =>
      Telematics.isLogoAvailable(record.tsp),
    );
    return sortBy(telematics, customTelematicsSorter);
  }, [providers]);

  const extraProviders = React.useMemo(() => {
    return providers.filter(
      (record) => !Telematics.isLogoAvailable(record.tsp),
    );
  }, [providers]);

  React.useEffect(() => {
    if (consentInfo?.creatorId) {
      dispatch(fetchTSPProviders(consentInfo?.creatorId, programType));
    }
  }, [consentInfo, programType, dispatch]);

  React.useEffect(() => {
    if (applicationInfoStatus === 'failed') {
      navigate('/not-found');
    }
  }, [applicationInfoStatus, navigate]);

  React.useEffect(() => {
    if (!isFetchingConnection) return;

    if (
      status === 'succeeded' &&
      selectedProvider &&
      connections[selectedProvider.tsp]
    ) {
      if (
        connections[selectedProvider.tsp].url &&
        Telematics.isOAuthSupported(selectedProvider.tsp)
      ) {
        window.location.href = `${connections[selectedProvider.tsp].url}`;
      } else {
        navigate(
          `/telematics/complete?token=${applicationId}&provider=${
            selectedProvider.tsp
          }${programType ? `&programType=${programType}` : ''}`,
        );
        setFetchingConnection(false);
      }
      dispatch(setStatus('idle'));
    } else if (status === 'failed') {
      setFetchingConnection(false);
      setServerError('Failed to connect to the selected provider');
      dispatch(setStatus('idle'));
    }
  }, [
    dispatch,
    status,
    isFetchingConnection,
    selectedProvider,
    connections,
    applicationId,
    programType,
    navigate,
  ]);

  React.useEffect(() => {
    capture('telematics_provider_select_page_viewed', {
      applicationId,
    });
  }, []);

  const handleProviderSelect = (provider: TSPRecord) => {
    capture('telematics_provider_selected', {
      provider: provider.tsp,
      applicationId,
    });

    setSelectedProvider(provider);
    setSelectedOtherProvider(undefined);
  };

  const onSubmit = (data: any) => {
    capture('telematics_provider_submit', {
      provider: selectedProvider?.tsp,
      applicationId,
    });

    if (
      selectedProvider?.consentKind ===
        TelematicsConsentKind.TelematicsConsentKindBasicAuth ||
      selectedProvider?.consentKind ===
        TelematicsConsentKind.TelematicsConsentKindBasicAuthWithLoginId
    ) {
      setPremierProviderDialogVisibility(true);
      return;
    }

    // Handle telematics connection
    if (applicationId && selectedProvider) {
      setServerError('');
      setFetchingConnection(true);

      const payload: TSPConnection = {
        tsp: selectedProvider.tsp,
        consentKind: selectedProvider.consentKind,
        programType,
      };

      if (selectedOtherProvider) {
        payload.additionalInfo = {
          freeTextTSPName: selectedOtherProvider,
        };
      }

      if (
        selectedProvider.consentKind ===
        TelematicsConsentKind.TelematicsConsentKindSpeedgauge
      ) {
        payload.speedgauge = data;
      }
      dispatch(fetchTSPProviderConnection(applicationId, payload));
    }
  };

  const renderForm = () => {
    if (
      !selectedProvider ||
      selectedProvider.consentKind ===
        TelematicsConsentKind.TelematicsConsentKindBasicAuth ||
      selectedProvider.consentKind ===
        TelematicsConsentKind.TelematicsConsentKindBasicAuthWithLoginId ||
      selectedProvider.consentKind ===
        TelematicsConsentKind.TelematicsConsentKindOAuth
    ) {
      return null;
    }

    return (
      <Grid container direction="column" item spacing={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            textAlign="center"
            marginBottom={2}
          >
            Please provide your contact information
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          spacing={3}
          mb={1}
        >
          <Grid item xs={4}>
            <InputLabel htmlFor="coverage-input" style={{ textAlign: 'right' }}>
              Full name
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={8}
            container
            direction="row"
            flexWrap="nowrap"
            spacing={2}
          >
            <Grid item>
              <Controller
                name="name"
                defaultValue=""
                control={control}
                rules={{
                  required: 'Please enter a name',
                }}
                render={(props) => (
                  <FormControl className={classes.formControl}>
                    <OutlinedInput
                      id="name-input"
                      placeholder="eg. John doe"
                      value={props.value}
                      onChange={props.onChange}
                      error={!!errors.name}
                    />
                    {!!errors.name && (
                      <FormHelperText error>
                        {errors?.name?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item />
          </Grid>
        </Grid>

        {selectedProvider.tsp === TSP.TspGeotab &&
          selectedProvider.consentKind ===
            TelematicsConsentKind.TelematicsConsentKindSpeedgaugeGeotab && (
            <>
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                spacing={3}
                mb={1}
              >
                <Grid item xs={4}>
                  <InputLabel
                    htmlFor="coverage-input"
                    style={{ textAlign: 'right', marginTop: 10 }}
                  >
                    Account Database Name
                  </InputLabel>
                </Grid>
                <Grid
                  item
                  xs={8}
                  container
                  direction="row"
                  flexWrap="nowrap"
                  spacing={2}
                >
                  <Grid item>
                    <Controller
                      name="dbName"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: 'Please enter account database name',
                      }}
                      render={(props) => (
                        <FormControl className={classes.formControl}>
                          <OutlinedInput
                            id="account-database-input"
                            placeholder="eg. fd_power"
                            value={props.value}
                            onChange={props.onChange}
                            error={!!errors.dbName}
                          />

                          <FormHelperText error={errors?.dbName}>
                            {errors?.dbName ? (
                              errors?.dbName?.message
                            ) : (
                              <>
                                <Link
                                  href="https://intercom.help/nirvana-insurance/en/articles/6112333-geotab-account-activation?utm_source=product&utm_medium=organic&utm_campaign=telematics_connection_flow"
                                  target="_blank"
                                  underline="always"
                                >
                                  Click here
                                </Link>{' '}
                                for instructions to know your account database
                                name
                              </>
                            )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item />
                </Grid>
              </Grid>
            </>
          )}

        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          spacing={3}
          mb={1}
        >
          <Grid item xs={4}>
            <InputLabel htmlFor="coverage-input" style={{ textAlign: 'right' }}>
              Email
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={8}
            container
            direction="row"
            flexWrap="nowrap"
            spacing={2}
          >
            <Grid item>
              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{
                  required: 'Please enter email',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Please enter a valid email',
                  },
                }}
                render={(props) => (
                  <FormControl className={classes.formControl}>
                    <OutlinedInput
                      id="email-input"
                      placeholder="eg. john@trucksrus.com"
                      value={props.value}
                      onChange={props.onChange}
                      error={!!errors.email}
                      type="email"
                    />
                    {!!errors.email && (
                      <FormHelperText error>
                        {errors?.email?.message}
                      </FormHelperText>
                    )}
                    {!!serverError && (
                      <FormHelperText error>{serverError}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (applicationInfoStatus === 'loading') {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (['expired', 'revoked'].includes(status)) {
    navigateWithParams('/telematics/previously-connected');
  }

  return (
    <>
      <BackgroundGradient />
      <Box pt={9} px={3} position="relative" zIndex={10}>
        <Button
          sx={{ color: 'text.secondary' }}
          startIcon={<img src={IconBack} alt="Back" />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      </Box>
      <Container
        maxWidth={'sm'}
        component={Box}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          zIndex: 10,
          maxWidth: {
            sm: 850,
            xxl: 960,
          },
        }}
      >
        <Grid
          flexGrow={1}
          p={4}
          pb={2}
          pt={1}
          component="form"
          container
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container direction="column" flexGrow={1} spacing={3}>
            <Grid item>
              <Box display="flex" justifyContent="center">
                <Box width={362}>
                  <HorizontalStepper
                    steps={[
                      {
                        key: 1,
                        label: '',
                      },
                      {
                        key: 2,
                        label: '',
                      },
                      { key: 3, label: '' },
                      { key: 4, label: '' },
                    ]}
                    activeStep={2}
                    onChange={() => {}}
                  />
                </Box>
              </Box>
            </Grid>
            <>
              <Grid item>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  fontWeight="fontWeightBold"
                  textAlign="center"
                  mt={2}
                  mb={2}
                >
                  Please select your telematics/ELD vendor
                </Typography>
              </Grid>
              <Grid item mb={2}>
                <Grid container spacing={1} justifyContent="center">
                  {recommendedProviders.map((record: TSPRecord) => {
                    const providerInfo = Telematics.getProviderInfo(record.tsp);

                    return (
                      <Grid key={record.tsp} item>
                        <Box
                          className={clsx(classes.buttonContainer, {
                            [classes.selected]:
                              selectedProvider?.tsp === record.tsp,
                          })}
                          onClick={() => handleProviderSelect(record)}
                        >
                          <img
                            src={providerInfo.logo}
                            className={classes.vendorIcon}
                            alt={record.name}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                  {!!extraProviders.length && (
                    <Grid item>
                      <Box
                        className={clsx(classes.buttonContainer, {
                          [classes.selected]:
                            !!selectedProvider &&
                            !Telematics.isLogoAvailable(selectedProvider.tsp),
                        })}
                        onClick={() => {
                          setDialogVisibility(true);
                        }}
                      >
                        <img src={IconApps} alt="Select other provider" />
                        <Typography
                          variant="caption"
                          color="textPrimary"
                          className={classes.otherText}
                        >
                          See All
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {renderForm()}
              <Grid
                item
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="large"
                    className={classes.btnSubmit}
                    disabled={!selectedProvider?.tsp || isFetchingConnection}
                    pendingIndicator={
                      <CircularProgress color="inherit" size={22} />
                    }
                    pending={isFetchingConnection}
                    pendingPosition="end"
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems="flex-end"
                justifyContent="center"
              >
                <Box>
                  <Typography
                    variant="caption"
                    component="p"
                    textAlign="center"
                    color="textSecondary"
                    mb={4}
                  >
                    To offer you an accurate quote, we will collect information
                    from you, consumer reporting agencies, ELD/telematics
                    vendors and other sources, including information relating to
                    your driving, insurance, claims and credit history, to the
                    extent permitted by law. By selecting an ELD/Telematics
                    vendor and clicking &ldquo;Submit&rdquo;, you agree to our{' '}
                    <Link
                      href="https://www.nirvanatech.com/privacy"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link
                      href="https://www.nirvanatech.com/terms"
                      target="_blank"
                    >
                      Terms of Use
                    </Link>
                    , authorize us to collect and use the specified information,
                    and authorize the selected vendor to provide Nirvana
                    necessary access to retrieve and use your information. This
                    authorization on behalf of all drivers insured under your
                    policy will remain in effect for as long as you are
                    continuously insured with Nirvana.
                  </Typography>
                </Box>
              </Grid>
            </>
          </Grid>
        </Grid>

        {dialogVisibility ? (
          <OtherVendors
            open={dialogVisibility}
            onClose={() => setDialogVisibility(false)}
            list={providers}
            value={selectedProvider?.tsp}
            onChange={handleProviderSelect}
            otherValue={selectedOtherProvider}
            onOtherChange={setSelectedOtherProvider}
          />
        ) : null}

        {!!selectedProvider && (
          <PremierConnectionDialog
            open={premierProviderDialogVisibility}
            applicationId={applicationId}
            selectedProvider={selectedProvider}
            onClose={() => {
              setPremierProviderDialogVisibility(false);
              setSelectedProvider(undefined);
            }}
          />
        )}
      </Container>
    </>
  );
};

export default TelematicsProvidersSelect;
