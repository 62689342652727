import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useStyles } from '@nirvana/ui-kit/src/components/applicationHeader/styles';
import React from 'react';
import ClearanceConflictDialogDeclined from 'src/features/application/components/create/ClearanceConflictDialogDeclined';

export default function ApplicationHeaderClearedApplicationExistsDeclined({
  dotNumber = '',
}: {
  dotNumber?: number | string;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="flex items-center justify-around h-12 bg-warning-light">
        <div className="flex items-center">
          <span className="mr-2">
            <InfoOutlinedIcon
              className={classes.declinedIcon}
              fontSize="small"
            />
          </span>
          <span className="mr-2 font-semibold">
            Application previously declined
          </span>{' '}
          Proceed only if you have new information that significantly changes
          the risk outlook.
        </div>
        <div className="cursor-pointer" onClick={handleOpenModal}>
          <a className="underline text-primary text-primary-main">Learn More</a>
        </div>
      </div>

      <ClearanceConflictDialogDeclined
        open={open}
        onClose={handleModalClose}
        dotNumber={dotNumber}
      />
    </>
  );
}
