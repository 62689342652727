import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { ApplicationDetail } from '@nirvana/api/quoting';
import { InputNumeric } from '@nirvana/ui-kit';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DriverUploadWithSummaryContainer from 'src/components/fileUpload/driverUploadWithSummary';
import IftaUploadWithSummaryContainer from 'src/components/fileUpload/iftaUploadWithSummaryContainer';
import LossRunUploadWithSummaryContainer from 'src/components/fileUpload/lossRunUploadWithSummary';
import {
  getHelperText,
  useStyles,
} from '../../../create/additionalInformation';

interface AdditionalFormViewProps {
  application: ApplicationDetail;
}

const AdditionalFormView = ({ application }: AdditionalFormViewProps) => {
  const classes = useStyles();

  const { control, errors } = useFormContext();
  const [isDialogOpen, openUploadDialog] = React.useState(false);

  const totalVehicles =
    application?.indicationForm?.operationsForm?.equipmentList.info?.length ||
    0;

  return (
    <Grid container direction="column" spacing={4}>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <InputLabel htmlFor="projectedMileage-input">
            Updated drivers list
          </InputLabel>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" alignItems="center">
            <DriverUploadWithSummaryContainer
              openUploadDialog={openUploadDialog}
              isDialogOpen={isDialogOpen}
              fileDefaultValue={application?.additionalInfoForm?.driverList}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={4}>
            <InputLabel
              htmlFor="numOwnerOperatorUnits-input"
              className={classes.inputLabel}
            >
              How many units in the fleet are owner-operators?
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name="numOwnerOperatorUnits"
              control={control}
              defaultValue={
                application?.additionalInfoForm?.numOwnerOperatorUnits ||
                application?.additionalInfoForm?.numOwnerOperatorUnits === 0
                  ? application?.additionalInfoForm?.numOwnerOperatorUnits
                  : undefined
              }
              rules={{
                required: 'Please enter the number of units',
                max: {
                  value: totalVehicles,
                  message:
                    "Owner-operator units shouldn't exceed uploaded equipment count",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter a valid number',
                },
              }}
              render={(props) => (
                <FormControl className={classes.formControlInput}>
                  <InputNumeric
                    id="numOwnerOperatorUnits-input"
                    placeholder="0"
                    decimalScale={0}
                    value={props.value}
                    onChange={(event) => {
                      if (event.target.value && isNaN(+event.target.value)) {
                        return;
                      }

                      props.onChange(+event.target.value);
                    }}
                    error={!!errors.numOwnerOperatorUnits}
                  />
                  {!!errors.numOwnerOperatorUnits && (
                    <FormHelperText error>
                      {getHelperText('numOwnerOperatorUnits', errors)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row" wrap="nowrap" spacing={3}>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="numOwnerOperatorUnits-input"
              className={classes.inputLabel}
            >
              Updated loss runs (4 years prior to Nirvana)
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <LossRunUploadWithSummaryContainer
              fileDefaultValue={application?.additionalInfoForm?.lossRunFiles}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row" wrap="nowrap" spacing={3}>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="numOwnerOperatorUnits-input"
              className={classes.inputLabel}
            >
              Available IFTAs for the policy term
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <IftaUploadWithSummaryContainer
              fileDefaultValue={application?.summary.renewalMetadata?.iftaFiles}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <Box paddingTop="10px">
            <InputLabel
              htmlFor="largeLossComment-input"
              className={classes.inputLabel}
            >
              Large losses
            </InputLabel>
            <FormHelperText>
              Please provide additional information for any single loss
              occurrences greater than{' '}
              {totalVehicles > 20 ? '$50,000' : '$25,000'}
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="largeLossComment"
            defaultValue={
              application?.additionalInfoForm?.largeLossComment || ''
            }
            control={control}
            rules={{
              required: false,
            }}
            render={(props) => (
              <FormControl className={classes.formControlTextarea}>
                <OutlinedInput
                  id="largeLossComment-input"
                  multiline
                  rows={3}
                  placeholder="Write your comments here..."
                  value={props.value}
                  onChange={props.onChange}
                  error={!!errors.largeLossComment}
                />
                {!!errors.largeLossComment && (
                  <FormHelperText error>
                    {getHelperText('largeLossComment', errors)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <Box paddingTop="10px">
            <InputLabel
              htmlFor="overallComment-input"
              className={classes.inputLabel}
            >
              Additional information
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="overallComment"
            defaultValue={application?.additionalInfoForm?.overallComment || ''}
            control={control}
            rules={{
              required: false,
            }}
            render={(props) => (
              <FormControl className={classes.formControlTextarea}>
                <OutlinedInput
                  id="overallComment-input"
                  multiline
                  rows={3}
                  placeholder="There is a substantial decrease in losses in every first quarter"
                  value={props.value}
                  onChange={props.onChange}
                  error={!!errors.overallComment}
                />
                {!!errors.overallComment && (
                  <FormHelperText error>
                    {getHelperText('overallComment', errors)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalFormView;
