import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { CoverageRecord, CoverageType, Producer } from '@nirvana/api/quoting';
import { AttachmentView, Checkbox, InputNumeric, Show } from '@nirvana/ui-kit';
import clsx from 'clsx';
import * as React from 'react';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import IconPlus from 'src/assets/icons/plus-primary.svg';
import IconFileUpload from 'src/assets/icons/upload-primary.svg';
import { ITheme } from 'src/assets/themes';
import { SummaryView } from 'src/components/table';
import { application as ApplicationHelper } from 'src/helpers';
import { useDispatch } from 'src/redux';

import { LocationForm, LocationList } from 'src/components/terminalLocation';
import { OPERATIONS_PAGE_VIEW } from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import {
  downloadApplicationFile,
  fetchAvailableProducers,
  fetchDOTInformation,
  resetAvailableProducers,
  resetStatus,
} from '../../actions';
import { applicationSelector } from '../../slices';
import EquipmentListDialog from './equipmentListUpload';

export const useStyles = makeStyles((theme: ITheme) => ({
  radiusRange: {
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.extraLight,
    color: theme.palette.text.secondary,
    display: 'flex',
    fontWeight: theme.typography.fontWeightRegular,
    height: '100%',
    padding: theme.spacing(1, 2),
    width: theme.typography.pxToRem(140),
  },
  radiusFormControl: {
    width: theme.typography.pxToRem(160),
  },
  formControl: {
    width: 220,
  },
  formControlLabel: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  radioLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  summaryContainer: {
    width: 380,
  },
  dialogPaper: {
    width: theme.typography.pxToRem(600),
  },
  dialogContent: {
    paddingBottom: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    borderBottom: '1px solid #E6E7EF',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

/**
 * Helper method to get readable helper text/error messages required during form validation.
 *
 * @param {string} fieldName - Name of form field.
 * @param {Object} errors - Errors provided by react-hook-form.
 */
const getHelperText = (fieldName: string, errors: FieldErrors): string => {
  const error = errors[fieldName] || {};

  switch (fieldName) {
    case 'numberOfPowerUnits':
      return error.message;

    case 'projectedMileage':
      return error.message;

    case 'radiusOfOperation':
      if (error.length) {
        // Check if a number error is available
        const numberError = error.find(
          (record: any) => record && record.type === 'number',
        );
        if (numberError) return numberError.message;

        return error.find((e: any) => !!e?.message).message;
      }
      return '';

    case 'equipmentList':
      return error.message;

    case 'producerId':
      return error.message;

    default:
      return '';
  }
};

/**
 * Form to capture operations related application information.
 * Corresponds to Operations Landing (https://www.figma.com/proto/OwouvIq33I1CCIjUXIlrcn/NIrvana_Dev-Handoff?node-id=98%3A11195&scaling=min-zoom&page-id=98%3A10867)
 * @component
 */
const Operations = ({
  childFormRef,
  clearErrors,
  errors,
  control,
  watch,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { capture } = useAnalytics();
  const getFeatureValue = useFeatureFlag();
  const [isFetchingAvailableProducers, setFetchingAvailableProducers] =
    React.useState<boolean>(false);
  const [openEquipmentListDialog, setOpenEquipmentListDialog] =
    React.useState<boolean>(false);
  const [isUploadingEquipmentList, setIsUploadingEquipmentList] =
    React.useState<boolean>(false);
  const [isAddTerminalLocationFormVisible, setAddTerminalLocationVisibility] =
    React.useState<boolean>(false);
  const [applicationUSState, setApplicationUSState] = React.useState<
    string | undefined
  >();
  const application = useSelector(applicationSelector);
  const { activeApplication, availableProducers = [] } = application;
  const applicationDotNumber = activeApplication?.summary?.dotNumber;
  const applicationId = activeApplication?.summary?.applicationID;
  const values = watch([
    'coveragesRequired',
    'numberOfPowerUnits',
    'projectedMileage',
    'radiusOfOperation',
    'terminalLocations',
    'equipmentList',
  ]);

  React.useEffect(() => {
    capture(OPERATIONS_PAGE_VIEW, {
      applicationId,
    });
  }, []);

  const producersList = React.useMemo(() => {
    const list = [...availableProducers];

    if (activeApplication?.summary.renewalMetadata) {
      list.push({
        id: activeApplication.summary.producerName,
        name: activeApplication.summary.producerID || '',
      });
    }

    return list;
  }, [availableProducers, activeApplication]);

  const coverages =
    values.coveragesRequired?.map((record: any) => record.coverageType) || [];
  const isApdCoverage = coverages.includes(
    CoverageType.CoverageAutoPhysicalDamage,
  );
  const isGlCoverage = coverages.includes(
    CoverageType.CoverageGeneralLiability,
  );

  const totalFleetPercentage: number =
    values.radiusOfOperation &&
    values.radiusOfOperation.reduce(
      (acc: number, record: { percentageOfFleet: number }) =>
        acc + (+record?.percentageOfFleet || 0),
      0,
    );

  const totalStatedValue: number =
    values.equipmentList &&
    values.equipmentList.info &&
    values.equipmentList.info.reduce(
      (acc: number, record: { statedValue: number }) =>
        acc + (+record?.statedValue || 0),
      0,
    );

  const mileageRadiusList = React.useMemo(() => {
    return ApplicationHelper.getMileageRadiusList();
  }, []);

  const fetchUSState = React.useCallback(
    (dotNumber: number) => {
      dispatch(fetchDOTInformation({ dotNumber })).then((response) => {
        if (fetchDOTInformation.fulfilled.match(response)) {
          setApplicationUSState(response.payload.usState);
        }
      });
    },
    [dispatch],
  );

  const handleFileDownload = (handleId?: string) => {
    if (!handleId) return;

    dispatch(downloadApplicationFile(handleId));
  };

  React.useEffect(() => {
    if (applicationDotNumber) {
      fetchUSState(applicationDotNumber);
    }
  }, [applicationDotNumber, fetchUSState]);

  React.useEffect(() => {
    // Fetch available producers
    setFetchingAvailableProducers(true);
    dispatch(fetchAvailableProducers());

    return () => {
      dispatch(resetAvailableProducers());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (isFetchingAvailableProducers && availableProducers) {
      setFetchingAvailableProducers(false);
      dispatch(resetStatus());
    }
  }, [dispatch, availableProducers, isFetchingAvailableProducers]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="h4" fontWeight="fontWeightBold">
          Operations
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="coverage-input">
              Coverages requested
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Controller
            name="coveragesRequired"
            control={control}
            defaultValue={[
              {
                coverageType: CoverageType.CoverageAutoLiability,
              },
            ]}
            rules={{
              required: true,
            }}
            render={(props) => {
              const coverages =
                props.value?.map(
                  (record: CoverageRecord) => record.coverageType,
                ) || [];

              return (
                <FormGroup>
                  <Grid container direction="column">
                    <Grid item mb={2}>
                      <FormControlLabel
                        onChange={() => {
                          props.onChange([
                            {
                              coverageType: CoverageType.CoverageAutoLiability,
                            },
                          ]);
                        }}
                        control={<Checkbox checked={true} />}
                        label="Auto Liability"
                        classes={{
                          root: clsx(classes.formControlLabel),
                          label: classes.radioLabel,
                        }}
                      />
                    </Grid>
                    <Grid item container direction="column">
                      <Grid item>
                        <Typography variant="caption" color="text.hint">
                          Please select additional coverages
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          onChange={(_, checked) => {
                            const newCoverages = [...props.value];
                            if (checked) {
                              capture(
                                'operations_form_coverages_added (Physical Damage)',
                                {
                                  applicationId,
                                },
                              );
                              newCoverages.push({
                                coverageType:
                                  CoverageType.CoverageAutoPhysicalDamage,
                              });
                            } else {
                              capture(
                                'operations_form_coverages_removed (Physical Damage)',
                                {
                                  applicationId,
                                },
                              );
                              newCoverages.splice(
                                coverages.indexOf(
                                  CoverageType.CoverageAutoPhysicalDamage,
                                ),
                                1,
                              );
                            }

                            props.onChange(newCoverages);
                          }}
                          control={
                            <Checkbox
                              checked={coverages.includes(
                                CoverageType.CoverageAutoPhysicalDamage,
                              )}
                            />
                          }
                          label="Physical Damage"
                          classes={{
                            root: clsx(classes.formControlLabel),
                            label: classes.radioLabel,
                          }}
                        />

                        {/* Show MTC coverage option only if feature flag is enabled */}

                        <FormControlLabel
                          sx={{ position: 'relative', zIndex: '100' }}
                          onChange={(_, checked) => {
                            const newCoverages = [...props.value];
                            if (checked) {
                              capture(
                                'operations_form_coverages_added (Motor Truck Cargo)',
                                {
                                  applicationId,
                                },
                              );
                              newCoverages.push({
                                coverageType:
                                  CoverageType.CoverageMotorTruckCargo,
                              });
                            } else {
                              capture(
                                'operations_form_coverages_removed (Motor Truck Cargo)',
                                {
                                  applicationId,
                                },
                              );
                              newCoverages.splice(
                                coverages.indexOf(
                                  CoverageType.CoverageMotorTruckCargo,
                                ),
                                1,
                              );
                            }

                            props.onChange(newCoverages);
                          }}
                          control={
                            <Checkbox
                              checked={coverages.includes(
                                CoverageType.CoverageMotorTruckCargo,
                              )}
                            />
                          }
                          label="Motor Truck Cargo"
                          classes={{
                            root: clsx(classes.formControlLabel),
                            label: classes.radioLabel,
                          }}
                        />

                        {/* Show GL coverage option only if feature flag is enabled and state criteria is met */}
                        <Show
                          when={
                            isGlCoverage ||
                            getFeatureValue(
                              Feature.GENERAL_LIABILITY_RELEASED_STATES,
                              '',
                            ).includes(applicationUSState) ||
                            getFeatureValue(
                              Feature.GENERAL_LIABILITY_INTERNAL_STATES,
                              '',
                            ).includes(applicationUSState)
                          }
                        >
                          <FormControlLabel
                            onChange={(_, checked) => {
                              const newCoverages = [...props.value];
                              if (checked) {
                                capture(
                                  'operations_form_coverages_added (General Liability)',
                                  {
                                    applicationId,
                                  },
                                );
                                newCoverages.push({
                                  coverageType:
                                    CoverageType.CoverageGeneralLiability,
                                });
                              } else {
                                capture(
                                  'operations_form_coverages_removed (General Liability)',
                                  {
                                    applicationId,
                                  },
                                );
                                newCoverages.splice(
                                  coverages.indexOf(
                                    CoverageType.CoverageGeneralLiability,
                                  ),
                                  1,
                                );
                              }

                              props.onChange(newCoverages);
                            }}
                            control={
                              <Checkbox
                                checked={coverages.includes(
                                  CoverageType.CoverageGeneralLiability,
                                )}
                              />
                            }
                            label="General Liability"
                            classes={{
                              root: clsx(classes.formControlLabel),
                              label: classes.radioLabel,
                            }}
                          />
                        </Show>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormGroup>
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={5}>
          <InputLabel htmlFor="producer-select">Producer</InputLabel>
        </Grid>
        <Grid item xs={7}>
          <Controller
            name="producerId"
            defaultValue=""
            rules={{
              required: 'Please select a producer',
            }}
            control={control}
            render={(props) => (
              <FormControl className={classes.formControl}>
                <Select
                  id="producer-select"
                  displayEmpty
                  variant="outlined"
                  value={props.value}
                  onChange={props.onChange}
                  error={!!errors.producerId}
                  disabled={!!activeApplication?.summary?.renewalMetadata}
                >
                  <MenuItem data-attr="operations-form-producer" value="">
                    Select
                  </MenuItem>
                  {producersList?.map((producer: Producer) => {
                    return (
                      <MenuItem
                        data-attr="operations-form-producer"
                        value={producer.id}
                        key={producer.id}
                      >
                        {producer.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {!!errors.producerId && (
                  <FormHelperText error>
                    {getHelperText('producerId', errors)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={5}>
          <InputLabel htmlFor="numberOfPowerUnits-input">
            Projected number of power units
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            <Controller
              name="numberOfPowerUnits"
              defaultValue=""
              control={control}
              rules={{
                required: 'Please enter a value',
                pattern: {
                  value: /^[1-9]\d*$/,
                  message: 'Please enter a valid number',
                },
              }}
              render={(props) => (
                <FormControl className={classes.formControl}>
                  <InputNumeric
                    id="numberOfPowerUnits-input"
                    placeholder="0"
                    decimalScale={0}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    error={!!errors.numberOfPowerUnits}
                  />
                  {!!errors.numberOfPowerUnits && (
                    <FormHelperText error>
                      {getHelperText('numberOfPowerUnits', errors)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={5}>
          <InputLabel htmlFor="projectedMileage-input">
            Projected mileage
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            <Controller
              name="projectedMileage"
              defaultValue=""
              control={control}
              rules={{
                required: 'Please enter a value',
                pattern: {
                  value: /^[1-9]\d*$/,
                  message: 'Please enter a valid number',
                },
                validate: (value) =>
                  value > 0 || 'A non-zero value must be provided',
              }}
              render={(props) => (
                <FormControl className={classes.formControl}>
                  <InputNumeric
                    id="projectedMileage-input"
                    placeholder="0"
                    decimalScale={0}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    error={!!errors.projectedMileage}
                  />
                  {!!errors.projectedMileage && (
                    <FormHelperText error>
                      {getHelperText('projectedMileage', errors)}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="radiusOfOperation-input">
              Radius of operation
            </InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Please specify percentage of operations in each radius bucket
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={7} container direction="column" spacing={1}>
          {mileageRadiusList.map((record, index) => {
            return (
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                spacing={2}
                key={record.bucket}
              >
                <Grid item>
                  <Box className={classes.radiusRange}>{record.label}</Box>
                </Grid>
                <Grid item flexGrow={1}>
                  <Controller
                    name={`radiusOfOperation[${index}]`}
                    defaultValue={{
                      mileageRadiusBucket: record.bucket,
                      percentageOfFleet: undefined,
                    }}
                    control={control}
                    rules={{
                      validate: {
                        number: (record) =>
                          !record ||
                          !record.percentageOfFleet ||
                          (record &&
                            /^[0-9]+$/.test(record.percentageOfFleet)) ||
                          'Please enter a valid number',
                        total: () =>
                          totalFleetPercentage === 100 ||
                          'Total must equal 100%',
                      },
                    }}
                    render={(props) => (
                      <FormControl className={classes.radiusFormControl}>
                        <InputNumeric
                          id="radiusOfOperation-input"
                          placeholder="0%"
                          decimalScale={0}
                          value={props.value.percentageOfFleet}
                          onChange={(event) => {
                            if (event.target.value) {
                              props.onChange({
                                ...props.value,
                                percentageOfFleet: +event.target.value,
                              });
                            } else {
                              props.onChange({
                                ...props.value,
                                percentageOfFleet: undefined,
                              });
                            }
                          }}
                          error={!!errors.radiusOfOperation}
                          suffix="%"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            );
          })}
          <Grid item container direction="row" wrap="nowrap" spacing={2}>
            <Grid item>
              <Box className={classes.radiusRange}>Total</Box>
            </Grid>
            <Grid item flexGrow={1}>
              <Box
                className={clsx(classes.radiusRange, classes.radiusFormControl)}
              >
                {totalFleetPercentage}%
              </Box>
            </Grid>
          </Grid>
          <Grid item container direction="row" wrap="nowrap" spacing={0}>
            <Grid item>
              {!!errors.radiusOfOperation && (
                <FormHelperText error>
                  {getHelperText('radiusOfOperation', errors)}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={5}>
          <Box paddingTop="10px">
            <InputLabel htmlFor="vehicleList-input">Equipment list</InputLabel>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Controller
            control={control}
            name="equipmentList"
            // Default value is set to undefined so that the required validation works
            defaultValue={undefined}
            rules={{
              required: 'Please upload a list of vehicles',
              validate: {
                total: (value) => {
                  if (value?.info?.length === 0) {
                    return 'Please upload a list of vehicles';
                  }

                  if (!isApdCoverage) {
                    return true;
                  } else {
                    return (
                      totalStatedValue > 0 ||
                      'Total Stated Value must be greater than 0'
                    );
                  }
                },
              },
            }}
            render={(props) => {
              const summaryValues = ApplicationHelper.getEquipmentSummary(
                props.value?.info,
              );
              const summaryRows = [
                {
                  label: 'Total # of trucks and trailers',
                  value: summaryValues.totalUnits,
                },
                {
                  label: 'Total value',
                  value: (
                    <NumberFormat
                      value={summaryValues.totalValue}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  ),
                },
              ];

              return (
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    {props.value?.flatfileMetadata?.fileMetadata?.name ? (
                      <AttachmentView
                        files={[
                          {
                            filename:
                              props.value?.flatfileMetadata?.fileMetadata
                                ?.name || 'Equipment List.xlsx',
                          },
                        ]}
                        onRemove={() => {
                          capture('operations_form_equipment_list_removed', {
                            applicationId,
                          });
                          props.onChange(null);
                        }}
                        onDownload={() => {
                          handleFileDownload(
                            props.value.flatfileMetadata.fileMetadata.handle,
                          );
                        }}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => setOpenEquipmentListDialog(true)}
                        startIcon={
                          isUploadingEquipmentList ? (
                            <CircularProgress size="16px" />
                          ) : (
                            <img src={IconFileUpload} alt="File Upload" />
                          )
                        }
                        disabled={isUploadingEquipmentList}
                      >
                        Upload
                      </Button>
                    )}
                    <EquipmentListDialog
                      open={openEquipmentListDialog}
                      onClose={() => setOpenEquipmentListDialog(false)}
                      isApdCoverage={isApdCoverage}
                      value={props.value}
                      onChange={props.onChange}
                      onUploadStatusChange={(status) => {
                        if (status === 'start') {
                          setOpenEquipmentListDialog(true);
                        } else if (status === 'progress') {
                          setIsUploadingEquipmentList(true);
                          capture(
                            'operations_form_equipment_list_upload_completed',
                            {
                              applicationId,
                            },
                          );
                        } else if (status === 'complete') {
                          setIsUploadingEquipmentList(false);
                        }
                      }}
                    />
                  </Grid>
                  {props.value?.info && props.value?.info?.length ? (
                    <Grid item>
                      <div className={classes.summaryContainer}>
                        <SummaryView
                          title="Document Summary"
                          rows={summaryRows}
                        />
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              );
            }}
          />

          {!!errors.equipmentList && (
            <FormHelperText error>
              {getHelperText('equipmentList', errors)}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
      {isGlCoverage && (
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={5}>
            <Box paddingTop="10px">
              <InputLabel htmlFor="vehicleList-input">
                Physical business addresses
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Grid container direction="column">
              <Grid item>
                <Controller
                  control={control}
                  name="terminalLocations"
                  defaultValue={[]}
                  rules={{
                    required: 'Please add a business address',
                    validate: {
                      minOne: (value) => {
                        if (value.length) return true;

                        return 'Please add at least 1 business address';
                      },
                    },
                  }}
                  render={(props) => {
                    return (
                      <>
                        <Box mb={2}>
                          <LocationList
                            list={props.value}
                            onChange={props.onChange}
                          />
                        </Box>

                        {isAddTerminalLocationFormVisible && (
                          <Box mb={2}>
                            <LocationForm
                              index={props.value.length}
                              ref={childFormRef}
                              onConfirm={(index: number, data) => {
                                const newLocations = [...props.value];
                                newLocations.splice(index, 0, data);

                                capture(
                                  'operations_form_terminal_location_added',
                                  {
                                    applicationId,
                                  },
                                );
                                props.onChange(newLocations);
                                setAddTerminalLocationVisibility(false);
                              }}
                              onRemove={(index: number) => {
                                const newLocations = [...props.value];
                                newLocations.splice(index, 1);

                                capture(
                                  'operations_form_terminal_location_removed',
                                  {
                                    applicationId,
                                  },
                                );

                                props.onChange(newLocations);
                                setAddTerminalLocationVisibility(false);
                              }}
                            />
                          </Box>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (childFormRef.current) {
                      childFormRef.current.submitForm(
                        () => {
                          setAddTerminalLocationVisibility(true);
                        },
                        () => {},
                      );
                    } else {
                      setAddTerminalLocationVisibility(true);
                      clearErrors('terminalLocations');
                    }
                  }}
                  startIcon={
                    <img
                      src={IconPlus}
                      alt="Add terminal location"
                      width={11}
                    />
                  }
                >
                  Add New Location
                </Button>
              </Grid>
            </Grid>

            {!!errors.terminalLocations && (
              <FormHelperText error>
                {errors.terminalLocations.message}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const OperationsContainer = (props: any) => {
  const methods = useFormContext();

  return <Operations {...methods} {...props} />;
};

export default OperationsContainer;
