import * as React from 'react';
import {
  ForgotPassword,
  Login,
  ResetPassword,
  VerificationEmail,
} from 'src/features/auth/components';
import {
  List as ApplicationsList,
  Create as ApplicationCreate,
  Submitted as ApplicationSubmitted,
} from 'src/features/application/components';
import {
  Create as NFApplicationCreate,
  Submitted as NFApplicationSubmitted,
  Indication as NFIndication,
  Quote as NFQuote,
  Bound as NFBound,
} from 'src/features/nonFleet/components';
import {
  Create as FPApplicationCreate,
  Submitted as FPApplicationSubmitted,
  Success as FPSuccess,
  Panic as FPPanic,
  Quote as FPApplicationQuote,
} from 'src/features/admitted/components';
import { Policies } from 'src/features/policies';
import { Claims } from 'src/features/claims/Claims';
import AppetiteLite from 'src/features/appetite-lite/';
import Resources from 'src/features/resources/components';
import Workramp from 'src/features/workramp/components';
import Hubspot from 'src/features/hubspot/components';
import Safety from 'src/features/safety/components';

import {
  TelematicsWelcome,
  TelematicsProviderSelect,
  TelematicsConnected,
  TelematicsValidation,
  TelematicsPreviouslyConnected,
  TelematicsReviewConnection,
} from 'src/features/telematics/components';
import { AuthLayout, PrivateLayout, PublicLayout } from 'src/components/layout';
import NotFound from 'src/features/404/components';
import QuoteBinding from 'src/features/application/components/binding';
// import Faq from 'src/features/faq/components';

import Profile from 'src/features/profile/components';
import { NavigateWithQuery } from 'src/components/navigate';
import NFApplicationDecline from 'src/features/nonFleet/components/declined';

import Policy from 'src/features/policy';
import Success from 'src/features/nonFleet/components/success';
import ApplicationRenewCreate from 'src/features/application/components/renew/create';
import RenewalSubmittedView from 'src/features/application/components/renew/submitted/renewalSubmittedView';
import SignUp from 'src/features/auth/components/signUp/signUp';
import AgencyInfo from 'src/features/agency-info/AgencyInfo';
import AuthGuard from './authGuard';

export const mainRoutesConfig = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'verification-email', element: <VerificationEmail /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'sign-up', element: <SignUp /> },
      { path: '/', element: <NavigateWithQuery to="/login" /> },
    ],
  },
  {
    path: '/not-found',
    element: (
      <PublicLayout>
        <NotFound />
      </PublicLayout>
    ),
  },
  // {
  //   path: '/faq',
  //   element: (
  //     <PublicLayout>
  //       <Faq />
  //     </PublicLayout>
  //   ),
  // },
  {
    path: '/applications',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      { path: 'list', element: <ApplicationsList /> },
      {
        path: ':applicationId/create',
        element: <ApplicationCreate />,
      },
      {
        path: ':applicationId/submitted',
        element: <ApplicationSubmitted />,
      },
      {
        path: ':applicationId/quote',
        element: <QuoteBinding />,
      },
      {
        path: ':applicationId/renew',
        element: <ApplicationRenewCreate />,
      },
      {
        path: '/applications',
        element: <NavigateWithQuery to="/applications/list" />,
      },
      {
        path: ':applicationId/renew/submitted',
        element: <RenewalSubmittedView />,
      },
    ],
  },
  {
    path: '/nrb',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ':applicationId/create',
        element: <NFApplicationCreate />,
      },
      {
        path: ':applicationId/decline',
        element: <NFApplicationDecline />,
      },
      {
        path: ':applicationId/submitted',
        element: <NFApplicationSubmitted />,
      },
      {
        path: ':applicationId/indication',
        element: <NFIndication />,
      },
      {
        path: ':applicationId/success',
        element: <Success />,
      },
      {
        path: ':applicationId/quote',
        element: <NFQuote />,
      },
      {
        path: ':applicationId/bound',
        element: <NFBound />,
      },
      {
        path: '/nrb',
        element: <NavigateWithQuery to="/applications/list" />,
      },
    ],
  },
  {
    path: '/non-fleet/application',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ':applicationId/create',
        element: <FPApplicationCreate />,
      },
      {
        path: ':applicationId/decline',
        element: <NFApplicationDecline />,
      },
      {
        path: ':applicationId/success',
        element: <FPSuccess />,
      },
      {
        path: ':applicationId/panic',
        element: <FPPanic />,
      },
      {
        path: ':applicationId/submitted',
        element: <FPApplicationSubmitted />,
      },
      {
        path: ':applicationId/quote',
        element: <FPApplicationQuote />,
      },
      {
        path: '/non-fleet/application',
        element: <NavigateWithQuery to="/applications/list" />,
      },
    ],
  },
  {
    path: '/policies',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      { path: ':policyId', element: <Policy /> },
      { path: 'list', element: <Policies /> },
      {
        path: '/policies',
        element: <NavigateWithQuery to="/policies/list" />,
      },
    ],
  },
  {
    path: '/claims',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Claims />,
      },
    ],
  },
  {
    path: '/appetite-lite',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ':dotNumber',
        element: <AppetiteLite />,
      },
    ],
  },
  {
    path: '/resources',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Resources />,
      },
    ],
  },
  {
    // Third party JWT-based SSO logins.
    path: '/login',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'academy',
        element: <Workramp />,
      },
      {
        path: 'hubspot',
        element: <Hubspot />,
      },
    ],
  },
  {
    path: '/safety',
    element: (
      <AuthGuard>
        <PrivateLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Safety />,
      },
    ],
  },
  {
    path: '/telematics',
    element: <PublicLayout />,
    children: [
      {
        path: 'connect',
        element: <TelematicsWelcome />,
      },
      {
        path: 'providers',
        element: <TelematicsProviderSelect />,
      },
      {
        path: 'complete',
        element: <TelematicsConnected />,
      },
      {
        path: 'validate',
        element: <TelematicsValidation />,
      },
    ],
  },
  {
    path: '/telematics/previously-connected',
    element: <TelematicsPreviouslyConnected />,
  },
  {
    path: '/telematics/review-connection',
    element: <TelematicsReviewConnection />,
  },
  {
    // Backward compatibility with old links
    path: '/telematics-connection',
    element: <NavigateWithQuery to="/telematics/connect" />,
  },
  {
    // Backward compatibility with old links
    path: '/telematics-connection-complete',
    element: <NavigateWithQuery to="/telematics/complete" />,
  },
];

export const modalRoutesConfig = [
  {
    path: '/profile',
    element: (
      <AuthGuard>
        <Profile />
      </AuthGuard>
    ),
  },
  {
    path: '/onboarding/agency/info',
    element: (
      <AuthGuard>
        <AgencyInfo />
      </AuthGuard>
    ),
  },
];
