import { Chip, formatNumber, getFormattedDate } from '@nirvana/ui-kit';

import PolicyCube from 'src/assets/icons/policy-cube.svg?react';
import SuccessIcon from 'src/assets/icons/check-circle-sm.svg?react';

import { mockPolicyData } from '../constants/policy';

export default function SummaryCard() {
  return (
    <section className="relative max-w-xl p-6 mb-10 bg-white rounded-md shadow-md">
      <p className="absolute font-medium underline top-6 right-6 text-primary-main">
        {mockPolicyData.indicationPlanType} Plan
      </p>

      <div className="flex mb-5">
        <div className="flex items-center justify-center mr-3 bg-[#ECF7FF] px-3 py-2 rounded-md">
          <PolicyCube />
        </div>

        <div>
          <p className="text-text-hint">Policy Number</p>
          <div className="flex items-center space-x-3">
            <p className="text-xl font-bold">{mockPolicyData.policyNumber}</p>
            <Chip
              color="success"
              icon={<SuccessIcon />}
              label={mockPolicyData.status}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between mb-5">
        <p className="text-xs text-text-hint">
          Annual Gross <br /> Written Premium
        </p>

        <p className="font-bold text-[28px]">
          <span className="text-text-hint">$</span>
          {formatNumber(mockPolicyData.totalPremium)}
        </p>
      </div>

      <div className="flex justify-between">
        <p className="text-xs text-text-hint">Effective Date</p>
        <p className="font-medium">
          {getFormattedDate(mockPolicyData.effectiveDate)}
        </p>
      </div>

      <div className="flex justify-between ">
        <p className="text-xs text-text-hint">Expiration Date</p>
        <p className="font-medium">
          {getFormattedDate(mockPolicyData.expirationDate)}
        </p>
      </div>
    </section>
  );
}
