import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';

import { useStyles } from '@nirvana/ui-kit/src/components/applicationHeader/styles';
import ClearanceConflictDialog from 'src/features/application/components/create/ClearanceConflictDialog';

export default function ApplicationHeaderClearedApplicationExists() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="h-12 bg-[#F3F4FA] flex items-center justify-around">
        <div className="flex items-center">
          <span className="mr-2">
            <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
          </span>
          <span className="mr-2 font-semibold">Potential Clearance</span> An
          application for this DOT has been cleared by another agent
        </div>
        <div className="cursor-pointer" onClick={handleOpenModal}>
          <a className="underline text-primary text-primary-main">Learn More</a>
        </div>
      </div>

      <ClearanceConflictDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
}
