// import DashboardIcon from 'src/assets/icons/sidebar/dashboard.svg';
// import DashboardActiveIcon from 'src/assets/icons/sidebar/dashboard-active.svg';
import AcademyIcon from 'src/assets/icons/sidebar/academy.svg';
import AcademyActiveIcon from 'src/assets/icons/sidebar/academy-active.svg';
import ApplicationsIcon from 'src/assets/icons/sidebar/applications.svg';
import ApplicationsActiveIcon from 'src/assets/icons/sidebar/applications-active.svg';
import PoliciesIcon from 'src/assets/icons/sidebar/policies.svg';
import PoliciesActiveIcon from 'src/assets/icons/sidebar/policies-active.svg';
import ClaimsIcon from 'src/assets/icons/sidebar/claims.svg';
import ClaimsActiveIcon from 'src/assets/icons/sidebar/claims-active.svg';
import SafetyIcon from 'src/assets/icons/sidebar/safety.svg';
import SafetyActiveIcon from 'src/assets/icons/sidebar/safety-active.svg';
import ResourcesIcon from 'src/assets/icons/sidebar/resources.svg';
import ResourcesActiveIcon from 'src/assets/icons/sidebar/resources-active.svg';
import HelpIcon from 'src/assets/icons/sidebar/help.svg';
import HelpActiveIcon from 'src/assets/icons/sidebar/help-active.svg';

export type ListItemProps = {
  key: string;
  label: string;
  icon: string;
  path: string;
  activeIcon: string;
  open?: boolean;
  external?: boolean;
};

type GetLinksProps = {
  hasHelp: boolean;
  hasPolicies: boolean;
  hasSafety: boolean;
  hasWorkramp: boolean;
  trainingProgress?: number;
};

export const getLinks = ({
  hasHelp,
  hasPolicies,
  hasSafety,
  hasWorkramp,
}: GetLinksProps): ListItemProps[] => [
  // TODO: Enable later
  // {
  //   key: 'dashboard',
  //   label: 'Dashboard',
  //   path: '/dashboard',
  //   icon: DashboardIcon,
  //   activeIcon: DashboardActiveIcon,
  // },
  {
    key: 'applications',
    label: 'Applications',
    path: '/applications',
    icon: ApplicationsIcon,
    activeIcon: ApplicationsActiveIcon,
  },
  ...(hasPolicies
    ? [
        {
          key: 'policies',
          label: 'Policies',
          path: '/policies',
          icon: PoliciesIcon,
          activeIcon: PoliciesActiveIcon,
        },
      ]
    : []),
  {
    key: 'claims',
    label: 'Claims',
    path: '/claims',
    icon: ClaimsIcon,
    activeIcon: ClaimsActiveIcon,
  },
  {
    key: 'resources',
    label: 'Resources',
    path: '/resources',
    icon: ResourcesIcon,
    activeIcon: ResourcesActiveIcon,
  },
  ...(hasSafety
    ? [
        {
          key: 'safety',
          label: 'Safety',
          path: '/safety',
          icon: SafetyIcon,
          activeIcon: SafetyActiveIcon,
        },
      ]
    : []),
  ...(hasWorkramp
    ? [
        {
          key: 'academy',
          label: 'Academy',
          path: '/login/academy',
          external: true,
          icon: AcademyIcon,
          activeIcon: AcademyActiveIcon,
        },
      ]
    : []),
  ...(hasHelp
    ? [
        {
          key: 'faq',
          label: 'Help Center',
          path: 'https://intercom.help/nirvana-insurance/en/?utm_source=product&utm_medium=organic&utm_campaign=agent_portal_help_tab',
          external: true,
          icon: HelpIcon,
          activeIcon: HelpActiveIcon,
        },
      ]
    : []),
];
