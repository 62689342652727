import { Show, TableV8 } from '@nirvana/ui-kit';
import { useSearchParams } from 'react-router-dom';
import { changesData, endorsementColumns } from '../constants/endorsement';

export default function EndorsementTab() {
  const [searchParams] = useSearchParams();

  return (
    <Show when={searchParams.get('tab') === 'endorsements'}>
      <h2 className="mb-5 text-xl font-medium">Endorsements</h2>

      <div className="overflow-hidden bg-white border rounded-lg border-text-disabled">
        <TableV8 columns={endorsementColumns} data={changesData} />
      </div>
    </Show>
  );
}
