import { IconButton } from '@material-ui/core';
import { HiArrowDownTray } from 'react-icons/hi2';
import { createColumnHelper } from '@tanstack/react-table';
import { Chip, IChipProps } from '@nirvana/ui-kit';

enum EndorsementStatus {
  Submitted = 'Submitted',
  Processed = 'Processed',
  QuoteRevised = 'Quote Revised',
}

type EndorsementData = {
  id: string;
  description: string;
  premium: string;
  effectiveDate: string;
  status: EndorsementStatus;
};

export const changesData: EndorsementData[] = [
  {
    id: '#003',
    description: 'Add 1 Vehicle + Update Stated Value',
    premium: '$14,300',
    effectiveDate: 'Aug 23, 2024',
    status: EndorsementStatus.QuoteRevised,
  },
  {
    id: '#002',
    description: 'Remove 2 Drivers',
    premium: '$10,540',
    effectiveDate: 'Aug 23, 2024',
    status: EndorsementStatus.Processed,
  },
  {
    id: '#001',
    description: 'Add 1 Vehicle + 1 Driver',
    premium: '$10,540',
    effectiveDate: 'Aug 23, 2024',
    status: EndorsementStatus.Submitted,
  },
];

function getEndorsementStatusChipProps(status: EndorsementStatus): IChipProps {
  switch (status) {
    case EndorsementStatus.Submitted:
      return { label: status, color: 'default' };
    case EndorsementStatus.Processed:
      return { label: status, color: 'success' };
    case EndorsementStatus.QuoteRevised:
      return { label: status, color: 'warning' };
  }
}

const columnHelper = createColumnHelper<EndorsementData>();

export const endorsementColumns = [
  columnHelper.accessor('id', {
    size: 500,
    header: 'ID & Changes',
    cell: ({ getValue, row }) => (
      <div className="text-text-primary">
        <p className="mb-0.5">{getValue()}</p>
        <p className="text-xs">{row.original.description}</p>
      </div>
    ),
  }),
  columnHelper.accessor('premium', { header: 'Premium' }),
  columnHelper.accessor('effectiveDate', { header: 'Effective Date' }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: ({ getValue }) => (
      <Chip {...getEndorsementStatusChipProps(getValue())} />
    ),
  }),
  columnHelper.display({
    id: 'download',
    size: 20,
    cell: () => (
      <IconButton size="small">
        <HiArrowDownTray className="text-primary-main" />
      </IconButton>
    ),
  }),
];
