import { Avatar, Box, Divider, Grid, Typography } from '@material-ui/core';
import { TelematicsConsentInfo } from '@nirvana/api/quoting';

import BagIcon from '@nirvana/ui-kit/src/assets/icons/bag.svg';
import Samsara from 'src/assets/icons/vendors/samsara.png';
import Background from 'src/assets/images/connected-background.jpg';

type ReviewConnectionProps = {
  consentInfo?: TelematicsConsentInfo;
};

export default function ReviewConnection({
  consentInfo,
}: ReviewConnectionProps) {
  return (
    <>
      <div className="absolute right-5 top-4">
        {!!consentInfo && (
          <Box>
            <Grid container flexWrap="nowrap" spacing={2} alignItems="center">
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ width: 20, height: 20, bgcolor: '#E6E7EF' }}>
                    <img src={BagIcon} alt="company" />
                  </Avatar>
                  <Typography variant="caption" color="secondary" ml={1}>
                    {consentInfo?.companyName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Divider flexItem sx={{ height: 16 }} orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="caption" color="secondary">
                  DOT: {consentInfo?.dotNumber}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>

      <div className="flex w-screen h-screen bg-primary-extraLight">
        <div className="relative flex-1 bg-none">
          <img
            className="absolute object-cover object-left w-full h-full"
            src={Background}
          />
          <div className="absolute w-full max-w-md text-3xl font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <p className="mb-8 text-primary-extraLight">
              You’re all set for now
            </p>
            <p className="text-primary-extraLight/70">
              Our team will follow up with new updates
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center flex-1">
          <div className="w-full max-w-md mx-auto text-text-primary">
            <p className="mb-4 text-xl font-bold">Your current connection</p>
            <p className="mb-10">
              The vehicles and drivers in your connection match your equipment
              list.
            </p>

            <div className="flex items-center mb-6 space-x-10">
              <div>
                <p className="text-xs">DOT</p>
                <p className="text-xl">{consentInfo?.dotNumber}</p>
              </div>

              <div>
                <p className="text-xs"># of VINs</p>
                <p className="text-xl">10</p>
              </div>
            </div>

            <div className="p-6 mb-8 bg-white border rounded-md shadow">
              <div className="flex items-center justify-between mb-8">
                <p className="text-base font-bold">Samsara</p>
                <img
                  src={Samsara}
                  className="object-cover object-left w-8 h-12"
                  alt="TSP vendor logo"
                />
              </div>

              <div className="grid grid-cols-3 space-x-10">
                <div>
                  <p className="text-xs">Account</p>
                  <p>{consentInfo?.insuredEmail}</p>
                </div>

                <div className="text-right">
                  <p className="text-xs">DOT</p>
                  <p>{consentInfo?.dotNumber}</p>
                </div>

                <div className="text-right">
                  <p className="text-xs"># of VINs</p>
                  <p>10/10</p>
                </div>
              </div>
            </div>

            <a className="font-bold text-primary-main">Need more help?</a>
          </div>
        </div>
      </div>
    </>
  );
}
