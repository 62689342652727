import { Box, styled, Typography } from '@material-ui/core';
import { ITheme } from '@nirvana/ui-kit';
import { TableView } from 'src/components/table';

export const TableBordered = styled(Box)(({ theme }: { theme: ITheme }) => ({
  border: '1px solid',
  borderColor: theme.palette.error.main,
  borderRadius: '5px',
  padding: theme.spacing(0.75, 3),
  paddingRight: theme.spacing(2),
}));

const CommoditiesUnfilledSkeleton = () => {
  return (
    <>
      <TableBordered>
        <TableView
          headers={[
            {
              key: 'commodityName',
              content: 'Primary Commodities',
            },
            {
              key: 'commodityCategory',
              content: 'Avg Value',
            },
            {
              key: 'avgDollarValueHauled',
              content: 'Max Value',
            },
            {
              key: 'maxDollarValueHauled',
              content: '% of Hauls',
            },
          ]}
          rows={[1, 2, 3, 4]?.map(() => ({
            columns: [
              {
                content: (
                  <div className="w-32">
                    <div className="h-4 bg-gray-100 rounded" />
                  </div>
                ),
              },
              {
                content: (
                  <div>
                    <div className="w-20 h-4 bg-gray-100 rounded" />
                  </div>
                ),
              },
              {
                content: (
                  <div>
                    <div className="w-20 h-4 bg-gray-100 rounded" />
                  </div>
                ),
              },
              {
                content: (
                  <div>
                    <div className="w-10 h-4 bg-gray-100 rounded" />
                  </div>
                ),
              },
            ],
          }))}
        />
        <div className="mt-5" />
        <TableView
          headers={[
            {
              key: 'otherCommodities',
              content: 'Other Commodities',
            },
            {
              key: 'empty',
              content: '',
            },
          ]}
          rows={[1, 2]?.map(() => ({
            columns: [
              {
                content: (
                  <div className="w-64">
                    <div className="h-4 bg-gray-100 rounded" />
                  </div>
                ),
              },
              {
                content: (
                  <div>
                    <div className="w-10 h-4 ml-2 bg-gray-100 rounded" />
                  </div>
                ),
              },
            ],
          }))}
        />
      </TableBordered>
      <Typography
        variant="caption"
        color="error"
        sx={{
          wordBreak: 'break-word',
        }}
      >
        {'Please enter commodity breakdown required for MTC'}
      </Typography>
    </>
  );
};

export default CommoditiesUnfilledSkeleton;
