import * as React from 'react';
import { Collapse, Pagination } from '@material-ui/core';
import {
  Column,
  Row,
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
  useFilters,
} from 'react-table';
import Show from '../show';
import Switch from '../switch-match';
import { cn } from '../../utils/tw-merge';
import TableSkeletonLoader from '../table-skeleton-loader';
import SortableHeader from './sortable-header';

type RowOptions = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
};

type CellOptions = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLTableCellElement>) => void;
};

type TableProps<T extends object> = {
  /** Columns prop allows you to define column header and cell styles for that column
   * For more details, refer: https://react-table-v7.tanstack.com/docs/api/useTable#column-options
   */
  columns: Array<Column<T>>;
  data: Array<T>;
  isLoading?: boolean;
  isPaginated?: boolean;
  expandedRowRender?: (row: T) => React.ReactNode;
  onRow?: (original: T, index: number, row: Row<T>) => RowOptions;
  onCell?: (cell: any, row: T) => CellOptions;
};

export default function Table<T extends object>({
  columns,
  data,
  isLoading,
  isPaginated = false,
  expandedRowRender,
  onCell,
  onRow,
}: TableProps<T>) {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    rows,
    gotoPage,
    pageCount,
    state: { pageIndex },
    prepareRow,
    footerGroups,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Header: SortableHeader },
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <div className="flex-1 mb-4 overflow-y-auto">
        <table {...getTableProps} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, idx) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                {headerGroup.headers.map((column, idx) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps)}
                    key={idx}
                    className="sticky top-0 z-10 px-4 py-3 text-xs font-normal text-left bg-primary-extraLight text-secondary-dark"
                  >
                    {column.render('Header')}
                    {/* Add filter UI */}
                    <div>
                      {column.canFilter && column.Filter
                        ? column.render('Filter')
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <Switch>
              <Switch.Match when={isLoading}>
                <TableSkeletonLoader columns={columns.length} />
              </Switch.Match>

              <Switch.Match when={data}>
                {(isPaginated ? page : rows).map((row, idx) => {
                  prepareRow(row);
                  let className, onClick;
                  if (onRow) {
                    className = onRow(row.original, idx, row).className;
                    onClick = onRow(row.original, idx, row).onClick;
                  }

                  return (
                    <React.Fragment key={idx}>
                      <tr
                        {...row.getRowProps()}
                        key={idx}
                        className={cn('text-sm text-text-primary', className)}
                        onClick={onClick}
                      >
                        {row.cells.map((cell, idx) => {
                          let className, onClick;
                          if (onCell) {
                            className = onCell(
                              cell.value,
                              row.original,
                            ).className;
                            onClick = onCell(cell.value, row.original).onClick;
                          }
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={idx}
                              className={cn('px-4 py-2 font-normal', className)}
                              onClick={onClick}
                              style={{
                                width: cell.column.width,
                                minWidth: cell.column.minWidth,
                                maxWidth: cell.column.maxWidth,
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>

                      <Show when={row.isExpanded}>
                        <tr>
                          <td colSpan={columns.length}>
                            <Collapse
                              in={row.isExpanded}
                              unmountOnExit
                              className="bg-gray-100 border-y"
                            >
                              {expandedRowRender?.(row.original) ?? null}
                            </Collapse>
                          </td>
                        </tr>
                      </Show>
                    </React.Fragment>
                  );
                })}
              </Switch.Match>
            </Switch>
          </tbody>

          <Show when={columns.some((column) => !!column.Footer)}>
            <tfoot>
              {footerGroups.map((group, idx) => (
                <tr {...group.getFooterGroupProps()} key={idx}>
                  {group.headers.map((column, idx) => (
                    <td
                      {...column.getFooterProps()}
                      key={idx}
                      className="px-4 py-2 font-medium border-primary-extraLight border-y text-text-primary"
                    >
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          </Show>
        </table>
      </div>

      <Show when={isPaginated}>
        <Pagination
          count={pageCount}
          variant="outlined"
          shape="rounded"
          page={pageIndex + 1}
          onChange={(_, page) => gotoPage(page - 1)}
        />
      </Show>
    </div>
  );
}
