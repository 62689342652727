import { Grid, Typography } from '@material-ui/core';
import {
  ApplicationDetail,
  CoverageRecord,
  CoverageType,
  LossRunSummaryRecord,
} from '@nirvana/api/quoting';
import { Tooltip } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { TableView } from 'src/components/table';
import { fetchApplicationById } from 'src/features/application/actions';
import { application as ApplicationHelper } from 'src/helpers';
import { useDispatch } from 'src/redux';
import Section, { SectionDataRenderParams } from '../../create/review/section';
import { useStyles } from '../../create/review/styles';
import { SECTION_NAMES } from '../create';
import { ViewProps } from '../create/renewStep2';

import { updateLossHistory } from '../queries';
import EditModeFooter from './footer/editModeFooter';
import MarkAsCompletedFooter from './footer/markAsCompletedFooter';
import LossRunFormMTC from './forms/lossRunFormMTC';
import LossRunFormView from './forms/lossRunFormView';
import { APDUnfilledSkeleton } from './skeleton/APDUnfilledSkeleton';
import { MTCUnfilledSkeleton } from './skeleton/MTCUnfilledSkeleton';

const LossHistoryView = ({
  activeApplication,
  isEditable = false,
  isMarkAsCompleted,
  onMarkAsCompleted,
  title = 'Loss History',
  hideFooter,
  isSectionLoading,
}: ViewProps) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const methods = useForm();
  const dispatch = useDispatch();

  const setEditModeVal = (value: boolean) => {
    setEditMode(value);
  };

  const applicationID = activeApplication?.summary.applicationID;

  const { mutate: update } = useMutation(updateLossHistory, {
    onSuccess: () => {
      dispatch(fetchApplicationById(applicationID));
      setEditMode(false);
    },
  });

  const coverages = React.useMemo(() => {
    return (
      activeApplication.indicationForm?.operationsForm?.coveragesRequired?.map(
        (record) => record.coverageType,
      ) || []
    );
  }, [activeApplication]);

  const hasAPD = React.useMemo(() => {
    return coverages.includes(CoverageType.CoverageAutoPhysicalDamage);
  }, [coverages]);

  const hasMTC = React.useMemo(() => {
    return coverages.includes(CoverageType.CoverageMotorTruckCargo);
  }, [coverages]);

  const handleMarkAsCompleted = (checked: boolean) => {
    // check if hasAPD is true then the values are present in application
    let isFieldsFilled = true;
    if (hasAPD) {
      const apdCoverage =
        activeApplication?.indicationForm?.lossRunSummaryForm?.find(
          (record) =>
            record.coverageType === CoverageType.CoverageAutoPhysicalDamage,
        );
      isFieldsFilled = !!apdCoverage?.lossRunSummary;
    }

    if (hasMTC) {
      const mtcCoverage =
        activeApplication?.indicationForm?.lossRunSummaryForm?.find(
          (record) =>
            record.coverageType === CoverageType.CoverageMotorTruckCargo,
        );
      isFieldsFilled = !!mtcCoverage?.lossRunSummary;
    }

    if (!isFieldsFilled) {
      return;
    }

    onMarkAsCompleted?.(SECTION_NAMES.LOSS_HISTORY, checked);
  };

  if (isMarkAsCompleted) {
    return (
      <Grid classes={{ root: classes.markAsCompleted }}>
        <Typography classes={{ root: classes.completedHeader }}>
          {title}
        </Typography>
        <MarkAsCompletedFooter
          checked={isMarkAsCompleted}
          onChange={() => handleMarkAsCompleted?.(false)}
          isSectionLoading={isSectionLoading}
        />
      </Grid>
    );
  }

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleUpdate = () => {
    methods.handleSubmit((data) => {
      const lossHistory = {
        lossRunSummaryForm:
          activeApplication.indicationForm?.lossRunSummaryForm || [],
        ...data,
      };

      lossHistory.lossRunSummaryForm.forEach((lossHistory, i) => {
        lossHistory.lossRunSummary.forEach((record, j) => {
          record.isNirvanaPeriod =
            activeApplication?.indicationForm?.lossRunSummaryForm?.[i]
              ?.lossRunSummary?.[j].isNirvanaPeriod || false;
        });
      });

      update({
        applicationID,
        lossHistory,
      });
      setEditMode(false);
    })();
  };

  if (hasMTC) {
    return (
      <Section
        applicationData={activeApplication}
        title={title}
        onEdit={() => setEditModeVal(true)}
        isEditable={!editMode && isEditable}
        footer={
          hideFooter ? null : editMode ? (
            <EditModeFooter onCancel={handleCancel} onUpdate={handleUpdate} />
          ) : (
            <MarkAsCompletedFooter
              onChange={(checked) => handleMarkAsCompleted(checked)}
              isSectionLoading={isSectionLoading}
            />
          )
        }
        data={
          editMode
            ? [
                {
                  key: 'lossRunForm',
                  getValue: (data: ApplicationDetail) => {
                    return data;
                  },
                  renderValue: () => {
                    return (
                      <FormProvider {...methods}>
                        <LossRunFormMTC applicationData={activeApplication} />
                      </FormProvider>
                    );
                  },
                },
              ]
            : [
                {
                  key: 'lossRunSummaryFormAuto&MotorTruck',
                  label: 'Auto Liability & Motor Truck Cargo',
                  getValue: (data: ApplicationDetail) => {
                    return data.indicationForm?.lossRunSummaryForm;
                  },
                  renderValue: ({
                    value = [],
                    previousValue = { lossRunSummary: [] },
                    hasChanged,
                  }: SectionDataRenderParams) => {
                    const previousLossRunSummaryByPeriod = {};
                    const getKey = (record: LossRunSummaryRecord) =>
                      `${record.policyPeriodStartDate}-${record.policyPeriodEndDate}`;
                    for (
                      let i = 0, l = previousValue.lossRunSummary.length;
                      i < l;
                      i += 1
                    ) {
                      previousLossRunSummaryByPeriod[
                        getKey(previousValue.lossRunSummary[i])
                      ] = previousValue.lossRunSummary[i];
                    }

                    const AutoLiabilityCoverage = value.find(
                      (item: CoverageRecord) =>
                        item.coverageType === 'CoverageAutoLiability',
                    ) || { lossRunSummary: [] };

                    const MotorTruckCargoCoverage = value.find(
                      (item: CoverageRecord) =>
                        item.coverageType === 'CoverageMotorTruckCargo',
                    ) || { lossRunSummary: [] };

                    return (
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography
                            variant="caption"
                            fontWeight={'fontWeightBold'}
                            mb={1}
                            component={'p'}
                          >
                            &nbsp;
                          </Typography>
                          <TableView
                            headers={[
                              {
                                key: 'columnPolicyPeriod',
                                content: 'Policy period',
                              },
                              {
                                key: 'columnPowerUnits',
                                content: 'Units',
                              },
                            ]}
                            rows={AutoLiabilityCoverage.lossRunSummary
                              .filter((record: LossRunSummaryRecord) => {
                                if (record.isNirvanaPeriod) {
                                  return true;
                                }
                                return (
                                  !!record.numberOfPowerUnits ||
                                  !!record.lossIncurred ||
                                  !!record.numberOfClaims
                                );
                              })
                              .map((record: LossRunSummaryRecord) => ({
                                columns: [
                                  {
                                    content: (
                                      <>
                                        {format(
                                          parseISO(
                                            record.policyPeriodStartDate,
                                          ),
                                          'M/d/yy',
                                        )}
                                        &nbsp;-&nbsp;
                                        {format(
                                          parseISO(record.policyPeriodEndDate),
                                          'M/d/yy',
                                        )}
                                      </>
                                    ),
                                  },
                                  {
                                    content:
                                      hasChanged &&
                                      previousLossRunSummaryByPeriod[
                                        getKey(record)
                                      ].numberOfPowerUnits !==
                                        record.numberOfPowerUnits ? (
                                        <mark>{record.numberOfPowerUnits}</mark>
                                      ) : (
                                        `${record.numberOfPowerUnits}`
                                      ),
                                  },
                                ],
                              }))}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="caption"
                            fontWeight={'fontWeightBold'}
                            mb={1}
                            component={'p'}
                          >
                            Auto Liability
                          </Typography>
                          <TableView
                            headers={[
                              {
                                key: 'columnLossIncured',
                                content: 'Loss incurred',
                              },
                              {
                                key: 'columnClaims',
                                content: 'Claims',
                              },
                            ]}
                            rows={AutoLiabilityCoverage.lossRunSummary
                              .filter((record: LossRunSummaryRecord) => {
                                if (record.isNirvanaPeriod) {
                                  return true;
                                }
                                return (
                                  !!record.numberOfPowerUnits ||
                                  !!record.lossIncurred ||
                                  !!record.numberOfClaims
                                );
                              })
                              .map((record: LossRunSummaryRecord) => {
                                if (record.isNirvanaPeriod) {
                                  return {
                                    columns: [
                                      {
                                        content: (
                                          <Tooltip
                                            title={
                                              <p>
                                                This Nirvana Loss Run is non
                                                editable
                                              </p>
                                            }
                                          >
                                            <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                                              -
                                            </div>
                                          </Tooltip>
                                        ),
                                      },
                                      {
                                        content: (
                                          <Tooltip
                                            title={
                                              <p>
                                                This Nirvana Loss Run is non
                                                editable
                                              </p>
                                            }
                                          >
                                            <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                                              -
                                            </div>
                                          </Tooltip>
                                        ),
                                      },
                                    ],
                                  };
                                }
                                return {
                                  columns: [
                                    {
                                      content:
                                        hasChanged &&
                                        previousLossRunSummaryByPeriod[
                                          getKey(record)
                                        ].lossIncurred !==
                                          record.lossIncurred ? (
                                          <mark>
                                            {
                                              <NumberFormat
                                                value={record.lossIncurred}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                              />
                                            }
                                          </mark>
                                        ) : (
                                          <NumberFormat
                                            value={record.lossIncurred}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                          />
                                        ),
                                    },
                                    {
                                      content:
                                        hasChanged &&
                                        previousLossRunSummaryByPeriod[
                                          getKey(record)
                                        ].numberOfClaims !==
                                          record.numberOfClaims ? (
                                          <mark>{record.numberOfClaims}</mark>
                                        ) : (
                                          `${record.numberOfClaims}`
                                        ),
                                    },
                                  ],
                                };
                              })}
                          />
                        </Grid>

                        {hasMTC && !MotorTruckCargoCoverage && (
                          <MTCUnfilledSkeleton
                            AutoLiabilityCoverage={AutoLiabilityCoverage}
                          />
                        )}

                        {!!MotorTruckCargoCoverage && (
                          <Grid item xs={4}>
                            <Typography
                              variant="caption"
                              fontWeight={'fontWeightBold'}
                              mb={1}
                              component={'p'}
                            >
                              Motor Truck Cargo
                            </Typography>
                            <TableView
                              headers={[
                                {
                                  key: 'columnLossIncured',
                                  content: 'Loss incurred',
                                },
                                {
                                  key: 'columnClaims',
                                  content: 'Claims',
                                },
                              ]}
                              rows={MotorTruckCargoCoverage?.lossRunSummary
                                ?.filter((record: LossRunSummaryRecord) => {
                                  if (record.isNirvanaPeriod) {
                                    return true;
                                  }
                                  return (
                                    !!record.numberOfPowerUnits ||
                                    !!record.lossIncurred ||
                                    !!record.numberOfClaims
                                  );
                                })
                                ?.map((record: LossRunSummaryRecord) => {
                                  if (record.isNirvanaPeriod) {
                                    return {
                                      columns: [
                                        {
                                          content: (
                                            <Tooltip
                                              title={
                                                <p>
                                                  This Nirvana Loss Run is non
                                                  editable
                                                </p>
                                              }
                                            >
                                              <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                                                -
                                              </div>
                                            </Tooltip>
                                          ),
                                        },
                                        {
                                          content: (
                                            <Tooltip
                                              title={
                                                <p>
                                                  This Nirvana Loss Run is non
                                                  editable
                                                </p>
                                              }
                                            >
                                              <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                                                -
                                              </div>
                                            </Tooltip>
                                          ),
                                        },
                                      ],
                                    };
                                  }
                                  return {
                                    columns: [
                                      {
                                        content:
                                          hasChanged &&
                                          previousLossRunSummaryByPeriod[
                                            getKey(record)
                                          ].lossIncurred !==
                                            record.lossIncurred ? (
                                            <mark>
                                              <NumberFormat
                                                value={record.lossIncurred}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                              />
                                            </mark>
                                          ) : (
                                            <NumberFormat
                                              value={record.lossIncurred}
                                              displayType={'text'}
                                              thousandSeparator={true}
                                              prefix={'$'}
                                            />
                                          ),
                                      },
                                      {
                                        content:
                                          hasChanged &&
                                          previousLossRunSummaryByPeriod[
                                            getKey(record)
                                          ].numberOfClaims !==
                                            record.numberOfClaims ? (
                                            <mark>{record.numberOfClaims}</mark>
                                          ) : (
                                            `${record.numberOfClaims}`
                                          ),
                                      },
                                    ],
                                  };
                                })}
                            />
                          </Grid>
                        )}
                      </Grid>
                    );
                  },
                },
                ...(hasAPD
                  ? [
                      {
                        key: 'lossRunSummaryFormPhysicalDamage',
                        label: 'Physical Damage',
                        getValue: (data: ApplicationDetail) => {
                          return data.indicationForm?.lossRunSummaryForm;
                        },
                        renderValue: ({
                          value = [],
                          previousValue = { lossRunSummary: [] },
                          hasChanged,
                        }: SectionDataRenderParams) => {
                          const previousLossRunSummaryByPeriod = {};
                          const getKey = (record: LossRunSummaryRecord) =>
                            `${record.policyPeriodStartDate}-${record.policyPeriodEndDate}`;
                          for (
                            let i = 0, l = previousValue.lossRunSummary.length;
                            i < l;
                            i += 1
                          ) {
                            previousLossRunSummaryByPeriod[
                              getKey(previousValue.lossRunSummary[i])
                            ] = previousValue.lossRunSummary[i];
                          }

                          const PhysicalDamageCoverage = value.find(
                            (item: CoverageRecord) =>
                              item.coverageType ===
                              'CoverageAutoPhysicalDamage',
                          );

                          if (
                            hasAPD &&
                            (!PhysicalDamageCoverage ||
                              PhysicalDamageCoverage.lossRunSummary.length ===
                                0)
                          ) {
                            return <APDUnfilledSkeleton />;
                          }

                          return (
                            <Grid container spacing={1}>
                              <Grid item xs={4}>
                                <Typography
                                  variant="caption"
                                  fontWeight={'fontWeightBold'}
                                  mb={1}
                                  component={'p'}
                                >
                                  &nbsp;
                                </Typography>
                                <TableView
                                  headers={[
                                    {
                                      key: 'columnPolicyPeriod',
                                      content: 'Policy period',
                                    },
                                    {
                                      key: 'columnPowerUnits',
                                      content: 'Units',
                                    },
                                  ]}
                                  rows={PhysicalDamageCoverage?.lossRunSummary
                                    ?.filter((record: LossRunSummaryRecord) => {
                                      if (record.isNirvanaPeriod) {
                                        return true;
                                      }
                                      return (
                                        !!record.numberOfPowerUnits ||
                                        !!record.lossIncurred ||
                                        !!record.numberOfClaims
                                      );
                                    })
                                    ?.map((record: LossRunSummaryRecord) => ({
                                      columns: [
                                        {
                                          content: (
                                            <>
                                              {format(
                                                parseISO(
                                                  record.policyPeriodStartDate,
                                                ),
                                                'M/d/yy',
                                              )}
                                              &nbsp;-&nbsp;
                                              {format(
                                                parseISO(
                                                  record.policyPeriodEndDate,
                                                ),
                                                'M/d/yy',
                                              )}
                                            </>
                                          ),
                                        },
                                        {
                                          content:
                                            hasChanged &&
                                            previousLossRunSummaryByPeriod[
                                              getKey(record)
                                            ].numberOfPowerUnits !==
                                              record.numberOfPowerUnits ? (
                                              <mark>
                                                {record.numberOfPowerUnits}
                                              </mark>
                                            ) : (
                                              `${record.numberOfPowerUnits}`
                                            ),
                                        },
                                      ],
                                    }))}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  variant="caption"
                                  fontWeight={'fontWeightBold'}
                                  mb={1}
                                  component={'p'}
                                >
                                  Physical Damage
                                </Typography>
                                <TableView
                                  headers={[
                                    {
                                      key: 'columnLossIncured',
                                      content: 'Loss incurred',
                                    },
                                    {
                                      key: 'columnClaims',
                                      content: 'Claims',
                                    },
                                  ]}
                                  rows={PhysicalDamageCoverage?.lossRunSummary
                                    .filter((record: LossRunSummaryRecord) => {
                                      if (record.isNirvanaPeriod) {
                                        return true;
                                      }
                                      return (
                                        !!record.numberOfPowerUnits ||
                                        !!record.lossIncurred ||
                                        !!record.numberOfClaims
                                      );
                                    })
                                    .map((record: LossRunSummaryRecord) => {
                                      if (record.isNirvanaPeriod) {
                                        return {
                                          columns: [
                                            {
                                              content: (
                                                <Tooltip
                                                  title={
                                                    <p>
                                                      This Nirvana Loss Run is
                                                      non editable
                                                    </p>
                                                  }
                                                >
                                                  <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                                                    -
                                                  </div>
                                                </Tooltip>
                                              ),
                                            },
                                            {
                                              content: (
                                                <Tooltip
                                                  title={
                                                    <p>
                                                      This Nirvana Loss Run is
                                                      non editable
                                                    </p>
                                                  }
                                                >
                                                  <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                                                    -
                                                  </div>
                                                </Tooltip>
                                              ),
                                            },
                                          ],
                                        };
                                      }
                                      return {
                                        columns: [
                                          {
                                            content:
                                              hasChanged &&
                                              previousLossRunSummaryByPeriod[
                                                getKey(record)
                                              ].lossIncurred !==
                                                record.lossIncurred ? (
                                                <mark>
                                                  <NumberFormat
                                                    value={record.lossIncurred}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                  />
                                                </mark>
                                              ) : (
                                                <NumberFormat
                                                  value={record.lossIncurred}
                                                  displayType={'text'}
                                                  thousandSeparator={true}
                                                  prefix={'$'}
                                                />
                                              ),
                                          },
                                          {
                                            content:
                                              hasChanged &&
                                              previousLossRunSummaryByPeriod[
                                                getKey(record)
                                              ].numberOfClaims !==
                                                record.numberOfClaims ? (
                                                <mark>
                                                  {record.numberOfClaims}
                                                </mark>
                                              ) : (
                                                `${record.numberOfClaims}`
                                              ),
                                          },
                                        ],
                                      };
                                    })}
                                />
                              </Grid>
                            </Grid>
                          );
                        },
                      },
                    ]
                  : []),
              ]
        }
      />
    );
  }

  const getOptionsLabel = (coverage: CoverageType) => {
    if (coverage === CoverageType.CoverageAutoPhysicalDamage) {
      if (!hasAPD) {
        return '';
      }
    }

    if (coverage === CoverageType.CoverageMotorTruckCargo) {
      if (!hasMTC) {
        return '';
      }
    }

    return ApplicationHelper.getIndicationOptionsFeatureLabel(coverage);
  };
  const data = (activeApplication.indicationForm?.lossRunSummaryForm || []).map(
    (formItem, index: number) => ({
      key: `lossRunSummaryForm.${formItem.coverageType}`,
      label: getOptionsLabel(formItem.coverageType),
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.lossRunSummaryForm?.[index];
      },
      renderValue: ({
        value = { lossRunSummary: [] },
        previousValue = { lossRunSummary: [] },
        hasChanged,
      }: SectionDataRenderParams) => {
        if (
          value.coverageType === CoverageType.CoverageAutoPhysicalDamage &&
          !hasAPD
        ) {
          return null;
        }

        if (
          value.coverageType === CoverageType.CoverageMotorTruckCargo &&
          !hasMTC
        ) {
          return null;
        }

        const previousLossRunSummaryByPeriod = {};
        const getKey = (record: LossRunSummaryRecord) =>
          `${record.policyPeriodStartDate}-${record.policyPeriodEndDate}`;
        for (
          let i = 0, l = previousValue.lossRunSummary.length;
          i < l;
          i += 1
        ) {
          previousLossRunSummaryByPeriod[
            getKey(previousValue.lossRunSummary[i])
          ] = previousValue.lossRunSummary[i];
        }

        if (
          hasAPD &&
          (!activeApplication.indicationForm?.lossRunSummaryForm ||
            activeApplication.indicationForm?.lossRunSummaryForm.length === 0)
        ) {
          return <APDUnfilledSkeleton />;
        }

        return (
          <TableView
            headers={[
              {
                key: 'columnPolicyPeriod',
                content: 'Policy period',
              },
              {
                key: 'columnPowerUnits',
                content: '# of Power Units',
              },
              {
                key: 'columnLossIncured',
                content: 'Loss incurred',
              },
              {
                key: 'columnClaims',
                content: '# of claims',
              },
            ]}
            rows={value.lossRunSummary
              .filter((record: LossRunSummaryRecord) => {
                if (record.isNirvanaPeriod) {
                  return true;
                }
                return (
                  !!record.numberOfPowerUnits ||
                  !!record.lossIncurred ||
                  !!record.numberOfClaims
                );
              })
              .map((record: LossRunSummaryRecord) => {
                if (record.isNirvanaPeriod) {
                  return {
                    columns: [
                      {
                        content: (
                          <>
                            {format(
                              parseISO(record.policyPeriodStartDate),
                              'M/d/yy',
                            )}
                            &nbsp;-&nbsp;
                            {format(
                              parseISO(record.policyPeriodEndDate),
                              'M/d/yy',
                            )}
                          </>
                        ),
                      },
                      {
                        content: (
                          <Tooltip
                            title={<p>This Nirvana Loss Run is non editable</p>}
                          >
                            <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                              -
                            </div>
                          </Tooltip>
                        ),
                      },
                      {
                        content: (
                          <Tooltip
                            title={<p>This Nirvana Loss Run is non editable</p>}
                          >
                            <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                              -
                            </div>
                          </Tooltip>
                        ),
                      },
                      {
                        content: (
                          <Tooltip
                            title={<p>This Nirvana Loss Run is non editable</p>}
                          >
                            <div className="w-4 h-4 pr-0 text-center align-middle bg-gray-100 rounded">
                              -
                            </div>
                          </Tooltip>
                        ),
                      },
                    ],
                  };
                }
                return {
                  columns: [
                    {
                      content: (
                        <>
                          {format(
                            parseISO(record.policyPeriodStartDate),
                            'M/d/yy',
                          )}
                          &nbsp;-&nbsp;
                          {format(
                            parseISO(record.policyPeriodEndDate),
                            'M/d/yy',
                          )}
                        </>
                      ),
                    },
                    {
                      content:
                        hasChanged &&
                        previousLossRunSummaryByPeriod[getKey(record)]
                          .numberOfPowerUnits !== record.numberOfPowerUnits ? (
                          <mark>{record.numberOfPowerUnits}</mark>
                        ) : (
                          `${record.numberOfPowerUnits}`
                        ),
                    },
                    {
                      content:
                        hasChanged &&
                        previousLossRunSummaryByPeriod[getKey(record)]
                          .lossIncurred !== record.lossIncurred ? (
                          <mark>
                            <NumberFormat
                              value={record.lossIncurred}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          </mark>
                        ) : (
                          <NumberFormat
                            value={record.lossIncurred}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        ),
                    },
                    {
                      content:
                        hasChanged &&
                        previousLossRunSummaryByPeriod[getKey(record)]
                          .numberOfClaims !== record.numberOfClaims ? (
                          <mark>{record.numberOfClaims}</mark>
                        ) : (
                          `${record.numberOfClaims}`
                        ),
                    },
                  ],
                };
              })}
          />
        );
      },
    }),
  );

  const doesAPDExist =
    activeApplication.indicationForm?.lossRunSummaryForm?.find(
      (formItem) =>
        formItem.coverageType === CoverageType.CoverageAutoPhysicalDamage,
    );

  if (hasAPD && !doesAPDExist) {
    data.push({
      key: 'lossRunSummaryForm',
      label: 'Physical Damage',
      getValue: (data: ApplicationDetail) => {
        return data.indicationForm?.lossRunSummaryForm?.find(
          (formItem) =>
            formItem.coverageType === CoverageType.CoverageAutoPhysicalDamage,
        );
      },
      renderValue: ({ value = { lossRunSummary: null } }) => {
        if (hasAPD && !value.lossRunSummary) {
          return <APDUnfilledSkeleton />;
        }
        return <></>;
      },
    });
  }
  return (
    <Section
      applicationData={activeApplication}
      title="Loss History"
      onEdit={() => setEditModeVal(true)}
      isEditable={!editMode && isEditable}
      footer={
        hideFooter ? null : editMode ? (
          <EditModeFooter onCancel={handleCancel} onUpdate={handleUpdate} />
        ) : (
          <MarkAsCompletedFooter
            onChange={(checked) => handleMarkAsCompleted(checked)}
            isSectionLoading={isSectionLoading}
          />
        )
      }
      data={
        editMode
          ? [
              {
                key: 'lossRunForm',
                getValue: (data: ApplicationDetail) => {
                  return data;
                },
                renderValue: () => {
                  return (
                    <FormProvider {...methods}>
                      <LossRunFormView applicationData={activeApplication} />
                    </FormProvider>
                  );
                },
              },
            ]
          : [...data]
      }
    />
  );
};

export default LossHistoryView;
