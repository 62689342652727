import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchApplicationById } from 'src/features/application/actions';

import { ITheme } from '@nirvana/ui-kit';
import DriverUploadWithSummaryContainer from 'src/components/fileUpload/driverUploadWithSummary';
import EquipmentUploadWithSummaryContainer from 'src/components/fileUpload/equipmentUploadWithSummary';
import IftaUploadWithSummaryContainer from 'src/components/fileUpload/iftaUploadWithSummaryContainer';
import { LossRunUploadWithSummaryContainer } from 'src/components/fileUpload/lossRunUploadWithSummary';
import {
  applicationSelector,
  fetchApplicationStatus,
  fetchQuoteData,
} from '../../../slices';
import { getCoverageType } from '../utils';

type RouteParams = {
  applicationId?: string;
};

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
});

const useStyles = makeStyles((theme: ITheme) => ({
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  subtitle: {
    color: '#878B95',
    fontSize: theme.typography.caption.fontSize,
  },
}));

const ApplicationRenewStep1 = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const params: RouteParams = useParams();
  const [openEquipmentListDialog, setOpenEquipmentListDialog] =
    React.useState<boolean>(false);

  const [openDriverListDialog, setOpenDriverListDialog] =
    React.useState<boolean>(false);

  const [isUploadingEquipmentList, setIsUploadingEquipmentList] =
    React.useState<boolean>(false);

  const application = useSelector(applicationSelector);

  const { activeApplication } = application;

  const { applicationId = '' } = params;

  React.useEffect(() => {
    // Fetch application information
    dispatch(fetchApplicationById(applicationId));
    dispatch(fetchApplicationStatus({ applicationId }));
    dispatch(fetchQuoteData({ applicationId }));
  }, [dispatch, applicationId]);

  const fileDefaultValue =
    activeApplication?.indicationForm?.operationsForm?.equipmentList;

  if (!activeApplication) {
    return null;
  }

  return (
    <Box>
      <Grid container py={3} mt={5}>
        <Grid item xs={4} />
        <Grid item>
          <div>
            <Typography
              variant="body1"
              className={classes.heading}
              gutterBottom
            >
              Updated equipment list
            </Typography>

            <EquipmentUploadWithSummaryContainer
              isUploading={isUploadingEquipmentList}
              setIsUploading={setIsUploadingEquipmentList}
              openUploadDialog={setOpenEquipmentListDialog}
              isDialogOpen={openEquipmentListDialog}
              fileDefaultValue={
                fileDefaultValue?.flatfileMetadata?.fileMetadata.name
                  ? fileDefaultValue
                  : undefined
              }
            />
          </div>
        </Grid>
      </Grid>

      <Grid container py={3}>
        <Grid item xs={4} />
        <Grid item>
          <div>
            <Typography
              className={classes.heading}
              variant="body1"
              gutterBottom
            >
              Updated drivers list
            </Typography>

            <DriverUploadWithSummaryContainer
              openUploadDialog={setOpenDriverListDialog}
              isDialogOpen={openDriverListDialog}
              fileDefaultValue={
                activeApplication?.additionalInfoForm?.driverList
              }
            />
          </div>
        </Grid>
      </Grid>

      <Grid container py={3}>
        <Grid item xs={4} />
        <Grid item>
          <Box>
            <Typography
              className={classes.heading}
              variant="body1"
              gutterBottom
            >
              Updated loss runs (4 years prior to Nirvana)
            </Typography>

            <Typography
              className={classes.subtitle}
              variant="body1"
              gutterBottom
            >
              Please upload loss runs for{' '}
              {formatter.format(
                activeApplication.indicationForm?.operationsForm?.coveragesRequired?.map(
                  (coverage) => getCoverageType(coverage.coverageType),
                ) || [],
              )}{' '}
              for 2018-2022
            </Typography>

            <LossRunUploadWithSummaryContainer
              fileDefaultValue={
                activeApplication?.additionalInfoForm?.lossRunFiles
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container py={3}>
        <Grid item xs={4} />
        <Grid item>
          <Box>
            <Typography
              className={classes.heading}
              variant="body1"
              gutterBottom
            >
              Available IFTAs for the current policy term
            </Typography>

            <Typography
              className={classes.subtitle}
              variant="body1"
              gutterBottom
            >
              Please upload IFTAs for the past 2-3 quarters of the current
              policy period
            </Typography>

            <IftaUploadWithSummaryContainer
              fileDefaultValue={
                activeApplication?.summary?.renewalMetadata?.iftaFiles
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApplicationRenewStep1;
