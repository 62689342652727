export const coverages = [
  {
    title: 'Auto Liability',
    description: 'Covers injuries/damage caused to others during accidents',
    limit: '$1,000,000',
    deductible: 1000,
  },
  {
    title: 'Auto Physical Damage',
    description: 'Repair/replace your damaged truck',
    limit: `$1,000,000 CSL
$250K Tractor/Trailer`,
    deductible: 1000,
  },
  {
    title: 'Motor Truck Cargo',
    description: 'Protects cargo hauled damaged or lost during transit',
    limit: '$100,000 CSL',
    deductible: 1000,
  },
  {
    title: 'General Liability',
    description:
      'Covers injuries/damage caused to others unrelated to accidents',
    limit: '$1M occ / $2M agg',
    deductible: 0,
  },
];
