import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  BasicAuthConnData,
  BasicAuthWithLoginIdConnData,
  ProgramType,
  TSPConnection,
  TSPRecord,
  TelematicsConnectionInfo,
  TelematicsConnectionStatus,
  TelematicsConsentKind,
} from '@nirvana/api/quoting';
import { Switch } from '@nirvana/ui-kit';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { useNavigate } from 'react-router-dom';

import { ITheme } from 'src/assets/themes';
import {
  createTelematicsConnection,
  fetchTelematicsConnectionInfo,
} from 'src/features/telematics/queries';
import { hooks as Hooks } from 'src/helpers';

import {
  CONTINUE_CLICK_ON_TELEMATICS_LOADING_PAGE,
  CONTINUE_CLICK_ON_TELEMATICS_LOGIN_PAGE,
  CONTINUE_CLICK_ON_TELEMATICS_PERMISSION_MODAL_INFO,
} from 'src/features/telematics/events';
import { useAnalytics } from 'src/helpers/analytics';
import { getProviderInfo } from 'src/helpers/telematics';
import { getLogoDimensions } from '../../utils';
import LoginForm from './form';
import Loader from './loader';
import Permissions from './permissions';
import Success from './success';

const useStyles = makeStyles((theme: ITheme) => ({
  dialogPaper: {
    width: theme.typography.pxToRem(480),
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
  },
  logo: {
    height: theme.typography.pxToRem(60),
    maxWidth: '50%',
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: 0,
  },
  loginForm: {
    marginBottom: theme.spacing(6),
  },
}));

interface PersmissionsDialogProps {
  open: boolean;
  onClose: () => void;
  applicationId: string;
  selectedProvider: TSPRecord;
}

const PersmissionsDialog = ({
  open,
  onClose,
  applicationId,
  selectedProvider,
}: PersmissionsDialogProps) => {
  const classes = useStyles();
  const { capture } = useAnalytics();
  const navigate = useNavigate();
  const query = Hooks.useQuery();
  const programType = (query.get('programType') as ProgramType) || undefined;
  const successWaitInterval = useRef<any>(0);

  const [content, setContent] = useState(0);
  const [handleId, setHandleId] = useState('');
  const [successCounter, setSuccessCounter] = useState(3);
  const [loadingAnimationComplete, setLoadingAnimationComplete] =
    useState(false);
  const [connectionError, setConnectionError] = useState('');
  const providerInfo = getProviderInfo(selectedProvider.tsp);

  const methods = useForm<BasicAuthConnData | BasicAuthWithLoginIdConnData>();
  const { handleSubmit } = methods;

  const handleConnectionInfo = (data: TelematicsConnectionInfo) => {
    if (
      data.status ===
      TelematicsConnectionStatus.TelematicsConnectionStatusConnected
    ) {
      setHandleId('');
      setContent(3);
    } else if (
      data.status ===
      TelematicsConnectionStatus.TelematicsConnectionStatusRejected
    ) {
      setHandleId('');
      setContent(1);
      // Save error message in state and display it
      setConnectionError(
        data.error || ((data as any)?.message as string) || 'connection_error',
      );
    }
  };

  const { data: connectionInfo } = useQuery({
    queryKey: ['connectionInfo', handleId],
    queryFn: () => fetchTelematicsConnectionInfo(handleId),
    enabled: !!handleId,
    refetchInterval: 10 * 1000,
    onSuccess: (response) => {
      if (!loadingAnimationComplete) return;

      handleConnectionInfo(response);
    },
    onError: (err) => {
      handleConnectionInfo((err as any)?.response?.data);
    },
  });

  const { mutate } = useMutation(createTelematicsConnection, {
    onMutate: () => {
      setLoadingAnimationComplete(false);
      setConnectionError('');
      setContent(2);
    },
    onSuccess: (data) => {
      // Start polling for connection status
      if (data.handleID) {
        setHandleId(data.handleID);
      }
    },
    onError: () => {
      setContent(1);
      setConnectionError('internal_error');
    },
  });

  const onSubmit = (data: any) => {
    const payload: TSPConnection = {
      tsp: selectedProvider?.tsp,
      consentKind: selectedProvider?.consentKind,
      programType,
    };

    if (
      selectedProvider.consentKind ===
      TelematicsConsentKind.TelematicsConsentKindBasicAuthWithLoginId
    ) {
      payload.basicAuthWithLoginId = data;
    } else {
      payload.basicAuth = data;
    }

    mutate({
      applicationId,
      payload,
    });
  };

  const handleContinue = (value: number) => {
    if (value === 0) {
      setContent(1);
      capture(CONTINUE_CLICK_ON_TELEMATICS_PERMISSION_MODAL_INFO, {
        page: 'info_page',
        TSP: selectedProvider.tsp,
        applicationId,
      });
    } else if (value === 1) {
      capture(CONTINUE_CLICK_ON_TELEMATICS_LOGIN_PAGE, {
        page: 'login_page',
        TSP: selectedProvider.tsp,
        applicationId,
      });
      handleSubmit(onSubmit)();
    } else if (value === 2) {
      capture(CONTINUE_CLICK_ON_TELEMATICS_LOADING_PAGE, {
        page: 'loading_page',
        TSP: selectedProvider.tsp,
        applicationId,
      });
      onClose();
    }
  };

  const handleLoadingComplete = () => {
    setLoadingAnimationComplete(true);

    if (connectionInfo) {
      handleConnectionInfo(connectionInfo);
    }
  };

  useEffect(() => {
    if (content === 3) {
      // Start a timer to close the dialog
      successWaitInterval.current = setInterval(() => {
        setSuccessCounter((prev) => Math.max(prev - 1, 0));
      }, 1000);
    }
  }, [content]);

  useEffect(() => {
    if (successCounter <= 0) {
      if (successWaitInterval.current) {
        clearInterval(successWaitInterval.current);
      }

      navigate(
        `/telematics/complete?token=${applicationId}&provider=${
          selectedProvider.tsp
        }${programType ? `&programType=${programType}` : ''}`,
      );

      onClose();
    }
  }, [
    applicationId,
    programType,
    onClose,
    navigate,
    successCounter,
    selectedProvider.tsp,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        sx={{
          position: 'relative',
        }}
      >
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="small"
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers sx={{ borderTop: 0 }}>
        <Box display={'flex'} justifyContent="center" mt={2} mb={4}>
          <img
            src={providerInfo.logo}
            alt={providerInfo.name}
            style={getLogoDimensions(selectedProvider.tsp)}
          />
        </Box>

        <Switch>
          <Switch.Match when={content === 0}>
            <Permissions selectedProvider={selectedProvider} />
          </Switch.Match>

          <Switch.Match when={content === 1}>
            <FormProvider {...methods}>
              <LoginForm
                connectionError={connectionError}
                selectedProvider={selectedProvider}
              />
            </FormProvider>
          </Switch.Match>

          <Switch.Match when={content === 2}>
            <Loader
              onDone={handleLoadingComplete}
              selectedProvider={selectedProvider}
            />
          </Switch.Match>

          <Switch.Match when={content === 3}>
            <Success selectedProvider={selectedProvider} />
          </Switch.Match>
        </Switch>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {content === 3 ? (
          <Box flexGrow={1}>
            <Typography
              variant="body2"
              textAlign={'center'}
              component="p"
              color="secondary"
              lineHeight={2}
            >
              You will be re-directed automatically in{' '}
              <strong>{successCounter}</strong> seconds
            </Typography>
          </Box>
        ) : (
          <Button
            type="button"
            color="primary"
            variant="contained"
            disabled={content === 2}
            onClick={() => {
              handleContinue(content);
            }}
            sx={{
              width: 175,
            }}
          >
            Continue
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PersmissionsDialog;
