import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import { DatePicker, InputZip, Show, constants } from '@nirvana/ui-kit';
import { useMutation } from '@tanstack/react-query';
import {
  formatISO,
  isAfter as isDateAfter,
  isBefore as isDateBefore,
  isValid as isDateValid,
  parseISO,
  startOfToday,
  subYears,
} from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form-v7';

import AddressSearch from 'src/components/address-search';
import { InputWithoutLabel as OutlinedInput } from 'src/components/input';
import { useApplicationDetailsContext } from 'src/features/admitted/hooks/useApplicationDetails';
import { decodeZipInfo } from 'src/features/admitted/queries/application';

const { usStates } = constants;

const BusinessOwner = () => {
  const { applicationDetails } = useApplicationDetailsContext();
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { mutateAsync } = useMutation(decodeZipInfo);

  return (
    <>
      <Grid
        item
        container
        spacing={3}
        wrap="nowrap"
        direction="row"
        alignItems="flex-start"
      >
        <Grid item xs={5}>
          <Box>
            <InputLabel htmlFor="business-owner">Business Owner</InputLabel>
            <FormHelperText style={{ width: 288 }}>
              Please enter the business owner&apos;s personal information
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={3} container flexDirection={'column'} spacing={2}>
          <Grid item container spacing={1} wrap="nowrap">
            <Grid item>
              <FormControl>
                <FormHelperText>First Name</FormHelperText>
                <OutlinedInput
                  placeholder="e.g. John"
                  {...register('operationsForm.businessOwner.firstName', {
                    required: 'Please enter first name',
                    setValueAs: (value) => value.trim(),
                  })}
                  error={
                    !!(errors?.operationsForm as any)?.businessOwner?.firstName
                  }
                  helperText={
                    (errors?.operationsForm as any)?.businessOwner?.firstName
                      ?.message
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormHelperText>Last Name</FormHelperText>
                <OutlinedInput
                  placeholder="e.g. Doe"
                  {...register('operationsForm.businessOwner.lastName', {
                    required: 'Please enter last name',
                    setValueAs: (value) => value.trim(),
                  })}
                  error={
                    !!(errors?.operationsForm as any)?.businessOwner?.lastName
                  }
                  helperText={
                    (errors?.operationsForm as any)?.businessOwner?.lastName
                      ?.message
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <FormHelperText>Date of Birth</FormHelperText>
              <Controller
                control={control}
                name="operationsForm.businessOwner.dateOfBirth"
                render={({ field: { value, onChange }, fieldState }) => {
                  return (
                    <DatePicker
                      inputFormat="MM/dd/yyyy"
                      shouldDisableDate={(date) => {
                        return isDateAfter(date as Date, startOfToday());
                      }}
                      value={value ? parseISO(value) : null}
                      maxDate={new Date()}
                      onChange={(value) =>
                        onChange(
                          value && isDateValid(value)
                            ? formatISO(value, {
                                representation: 'date',
                              })
                            : '',
                        )
                      }
                      PopperProps={{
                        placement: 'bottom',
                        modifiers: [
                          {
                            name: 'flip',
                            options: {
                              fallbackPlacements: ['bottom', 'right', 'top'],
                            },
                          },
                        ],
                      }}
                      InputProps={{
                        placeholder: 'eg. 12/31/2021',
                        fullWidth: true,
                        error: !!fieldState.error,
                      }}
                    />
                  );
                }}
                rules={{
                  required: 'Please enter date of birth',
                  validate: {
                    validDate: (value) => {
                      return (
                        /^([1-2]{1}[0-9]{3})-([0-9]{2})-([0-9]{2})$/.test(
                          value,
                        ) || 'Please enter a valid date of birth'
                      );
                    },
                    minAge: (value) => {
                      // Min age should be 14 years
                      const date = parseISO(value);
                      const minAgeDate = subYears(new Date(), 14);

                      return (
                        isDateBefore(date, minAgeDate) ||
                        'Please enter a valid date of birth'
                      );
                    },
                    maxAge: (value) => {
                      // Max age should be 125 years
                      const date = parseISO(value);
                      const maxAgeDate = subYears(new Date(), 125);

                      return (
                        isDateAfter(date, maxAgeDate) ||
                        'Please enter a valid date of birth'
                      );
                    },
                  },
                }}
              />
              {(errors.operationsForm as any)?.businessOwner?.dateOfBirth
                ?.message && (
                <FormHelperText error>
                  {
                    (errors.operationsForm as any)?.businessOwner?.dateOfBirth
                      ?.message
                  }
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item>
            <Controller
              control={control}
              defaultValue=""
              name="operationsForm.businessOwner.address.street"
              rules={{
                required: 'Please enter street name',
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <FormHelperText>Street</FormHelperText>
                    <AddressSearch
                      value={value}
                      onPlaceSelect={(placeDetails) => {
                        onChange(placeDetails?.street);
                        setValue(
                          'operationsForm.businessOwner.address.city',
                          placeDetails?.city,
                        );
                        setValue(
                          'operationsForm.businessOwner.address.state',
                          placeDetails?.state,
                        );
                        setValue(
                          'operationsForm.businessOwner.address.zip',
                          placeDetails?.zip,
                        );
                      }}
                    />

                    {!!(errors?.operationsForm as any)?.businessOwner?.address
                      ?.street && (
                      <FormHelperText error>
                        {
                          (errors?.operationsForm as any)?.businessOwner
                            ?.address?.street?.message
                        }
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item container spacing={1} wrap="nowrap">
            <Grid item>
              <FormControl>
                <FormHelperText>City</FormHelperText>
                <OutlinedInput
                  id="city"
                  placeholder="eg. Austin"
                  {...register('operationsForm.businessOwner.address.city', {
                    required: 'Enter city',
                  })}
                  error={
                    !!(errors.operationsForm as any)?.businessOwner?.address
                      ?.city
                  }
                  helperText={
                    (errors.operationsForm as any)?.businessOwner?.address?.city
                      ?.message
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormHelperText>State</FormHelperText>
                <Controller
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Select state' }}
                  name="operationsForm.businessOwner.address.state"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      size="small"
                      displayEmpty
                      value={value}
                      variant="outlined"
                      onChange={onChange}
                      error={
                        !!(errors.operationsForm as any)?.businessOwner?.address
                          ?.state
                      }
                    >
                      <MenuItem value="">
                        <Typography color="text.hint">Select</Typography>
                      </MenuItem>
                      {usStates.map((state) => (
                        <MenuItem key={state.code} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {!!(errors.operationsForm as any)?.businessOwner?.address
                  ?.state && (
                  <FormHelperText error>
                    {
                      (errors.operationsForm as any)?.businessOwner?.address
                        ?.state?.message
                    }
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormHelperText>Zip code</FormHelperText>
                <Controller
                  control={control}
                  defaultValue=""
                  name="operationsForm.businessOwner.address.zip"
                  rules={{
                    required: 'Enter ZIP Code',
                    validate: {
                      isExtendedZip: (value) => {
                        return (
                          value.length <= 5 ||
                          'Please remove the zip code extension'
                        );
                      },
                      isValidZip: async (value) => {
                        try {
                          await mutateAsync(value);

                          return true;
                        } catch (ex) {
                          return 'Please enter a valid ZIP code';
                        }
                      },
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <InputZip
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      placeholder="eg. 12345"
                      error={
                        !!(errors.operationsForm as any)?.businessOwner?.address
                          ?.zip
                      }
                      helperText={
                        (errors.operationsForm as any)?.businessOwner?.address
                          ?.zip?.message
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Show when={applicationDetails?.isOnNewCreditScore}>
        <Grid
          item
          container
          spacing={3}
          wrap="nowrap"
          direction="row"
          alignItems="flex-start"
        >
          <Grid item xs={5}>
            &nbsp;
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondaryLight">
              <strong>Information Disclosure:</strong> By proceeding with this
              application, you authorize us, on behalf of the applicant for
              which you represent that you serve as an agent, to collect and use
              any and all information set forth in our{' '}
              <Link href="https://www.nirvanatech.com/privacy" target="_blank">
                <strong>Privacy Policy</strong>
              </Link>{' '}
              to the extent permitted by law.
            </Typography>
          </Grid>
        </Grid>
      </Show>

      <Grid
        item
        container
        spacing={3}
        wrap="nowrap"
        direction="row"
        alignItems="flex-start"
      >
        <Grid item xs={5}>
          <FormHelperText style={{ width: 288 }}>
            Is the business owner a driver on the policy?
          </FormHelperText>
        </Grid>
        <Grid item xs={7}>
          <Grid item>
            <Controller
              control={control}
              defaultValue={undefined}
              name="operationsForm.businessOwner.driverOnPolicy"
              rules={{
                validate: {
                  required: (value?: string) => {
                    if (value === undefined) {
                      return 'Please provide if the owner is a driver on the policy';
                    }

                    return true;
                  },
                },
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-label="driver-on-policy"
                      value={`${value}`}
                      onChange={(e) => {
                        onChange(e.target.value === 'true');
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                    {!!(errors.operationsForm as any)?.businessOwner
                      ?.driverOnPolicy && (
                      <FormHelperText error>
                        {
                          (errors.operationsForm as any)?.businessOwner
                            ?.driverOnPolicy?.message
                        }
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessOwner;
