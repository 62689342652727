import {
  ApplicationApi,
  ProgramType,
  TelematicsApi,
  TSPConnection,
  UTMParamMedium,
  UTMParamSource,
} from '@nirvana/api/quoting';

import apiServiceNF from 'src/helpers/apiService';

export const createTelematicsConnection = async ({
  applicationId,
  payload,
}: {
  applicationId: string;
  payload: TSPConnection;
}) => {
  const apiService = new TelematicsApi();

  const { data } =
    await apiService.applicationApplicationIDTelematicsConnectionPost(
      applicationId,
      payload,
    );

  return data;
};

export const fetchTelematicsConnectionInfo = async (handleId: string) => {
  const apiService = new TelematicsApi();

  const { data } = await apiService.getTelematicsConnectionInfo(handleId);

  return data;
};

export const fetchSafetyLink = async (
  applicationId: string,
  programType?: ProgramType,
) => {
  const apiService = new ApplicationApi();

  const { data } = await apiService.generateSafetyReportURI(
    applicationId,
    UTMParamMedium.QuotingApp,
    UTMParamSource.TelematicsConsent,
    { programType },
  );

  return data;
};

export const trackTelematicsLinkOpen = async (applicationId: string) => {
  const { data } = await apiServiceNF.postTelematicsConnectionLinkOpened(
    applicationId,
  );

  return data;
};
